// https://github.com/TehShrike/is-mergeable-object/blob/master/index.js
function isMergeableObject(value): any {
  return isNonNullObject(value) && !isSpecial(value);
}

function isNonNullObject(value): any {
  return !!value && typeof value === 'object';
}

function isSpecial(value): any {
  const stringValue = Object.prototype.toString.call(value);

  return stringValue === '[object RegExp]' || stringValue === '[object Date]' || isReactElement(value);
}

const canUseSymbol = typeof Symbol === 'function' && Symbol.for;
const REACT_ELEMENT_TYPE = canUseSymbol ? Symbol.for('react.element') : 0xeac7;

function isReactElement(value): any {
  return value.$$typeof === REACT_ELEMENT_TYPE;
}

// https://davidwalsh.name/javascript-deep-merge
// https://github.com/KyleAMathews/deepmerge

function emptyTarget(val): any {
  return Array.isArray(val) ? [] : {};
}

function cloneUnlessOtherwiseSpecified(value, options): any {
  return options.clone !== false && options.isMergeableObject(value) ? deepmerge(emptyTarget(value), value, options) : value;
}

function defaultArrayMerge(target, source, options): any {
  return target.concat(source).map(function(element): any {
    return cloneUnlessOtherwiseSpecified(element, options);
  });
}

function mergeObject(target, source, options): any {
  const destination = {};
  if (options.isMergeableObject(target)) {
    Object.keys(target).forEach(function(key): any {
      destination[key] = cloneUnlessOtherwiseSpecified(target[key], options);
    });
  }
  Object.keys(source).forEach(function(key): any {
    if (!options.isMergeableObject(source[key]) || !target[key]) {
      destination[key] = cloneUnlessOtherwiseSpecified(source[key], options);
    } else {
      destination[key] = deepmerge(target[key], source[key], options);
    }
  });
  return destination;
}

export function deepmerge(target, source, options?: any): any {
  options = options || { isMergeableObject: isMergeableObject };
  options.arrayMerge = options.arrayMerge || defaultArrayMerge;
  // options.isMergeableObject = options.isMergeableObject || defaultIsMergeableObject;

  const sourceIsArray = Array.isArray(source);
  const targetIsArray = Array.isArray(target);
  const sourceAndTargetTypesMatch = sourceIsArray === targetIsArray;

  if (!sourceAndTargetTypesMatch) {
    return cloneUnlessOtherwiseSpecified(source, options);
  } else if (sourceIsArray) {
    return options.arrayMerge(target, source, options);
  } else {
    return mergeObject(target, source, options);
  }
}

export function deepmergeAll(array, options): any {
  if (!Array.isArray(array)) {
    throw new Error('first argument should be an array');
  }

  return array.reduce(function(prev, next): any {
    return deepmerge(prev, next, options);
  }, {});
}
