import * as tslib_1 from "tslib";
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { UserState } from '../../user/state/user.state';
import { menuHandleAfterClick } from './menu-handle-after-click.function';
import { MenuService } from './menu.service';
import { ResetProfilePictureImageAction } from './state/action/reset-profile-picture-image.action';
import { ToggleMenuAction } from './state/action/toggle-menu.action';
import { MenuState } from './state/menu.state';
var MenuComponent = /** @class */ (function () {
    function MenuComponent(router, breakpointObserver, cdr, menuService, store, injector, actions$, window) {
        var _this = this;
        this.router = router;
        this.breakpointObserver = breakpointObserver;
        this.cdr = cdr;
        this.menuService = menuService;
        this.store = store;
        this.injector = injector;
        this.actions$ = actions$;
        this.window = window;
        this.userImageMonogramMode = false;
        this.userImageLoadMode = false;
        this.disableNextUrlDetect = false;
        this.version = environment.VERSION;
        // add menu filter operators
        this.menuWrapper$ = this.menuService.getMenuObservable().pipe(tap(function (menu) {
            // ha valtozik a menu valamiert valamilyen szinten akkor a jelenlegi menut megint meg kell keresni hisz uj referencia lesz ...
            _this.setChoosenMenu(_this.router.url, menu);
        }));
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), debounceTime(200), distinctUntilChanged())
            .subscribe(function (event) { return _this.setChoosenMenu(event.urlAfterRedirects, _this.menuService.getMenuCurrentValue()); });
        this.detectProfilePictureReset();
    }
    MenuComponent.prototype.setChoosenMenu = function (url, menu) {
        var _this = this;
        this.menuService
            .findSelectedMenuItemByCurrentUrl(url, menu)
            .pipe(take(1))
            .subscribe(function (chosenMenu) {
            _this.chosenMenu = chosenMenu.selectedMenus;
            _this.cdr.markForCheck();
        });
    };
    MenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.urlDetector();
        this.store
            .select(MenuState.menuOpened)
            .pipe(untilDestroyed(this))
            .subscribe(function (menuOpened) {
            _this.opened = menuOpened;
            timer(0).subscribe(function () { return _this.cdr.markForCheck(); });
        });
    };
    MenuComponent.prototype.onMenuChange = function (chosenMenu) {
        if (chosenMenu.outsideLink !== undefined) {
            this.window.open(chosenMenu.outsideLink, chosenMenu.target);
        }
        // ha a fő menüpontnak van route-ja, akkor odanavigálunk
        if (chosenMenu.route) {
            this.router.navigate([chosenMenu.route]);
        }
        // ha főmenünek nincs route-ja, de van legalább egy subtop menüje
        else if (chosenMenu.top && chosenMenu.top[0]) {
            this.router.navigate([chosenMenu.top[0].route]);
        }
        // ha főmenünek nincs route-ja, de van legalább egy subbottom menüje
        else if (chosenMenu.bottom && chosenMenu.bottom[0]) {
            this.router.navigate([chosenMenu.bottom[0].route]);
        }
    };
    MenuComponent.prototype.onMenuToggle = function () {
        this.store.dispatch(new ToggleMenuAction());
    };
    MenuComponent.prototype.onFloatMenuNavigate = function (subMenu) {
        menuHandleAfterClick(subMenu, this.injector);
        this.router.navigate([subMenu.route]);
    };
    MenuComponent.prototype.onSelectSubMenu = function ($event) {
        this.disableNextUrlDetect = true;
        // this.chosenMenu = { ...this.chosenMenu, chosenSubMenu: $event };
        this.chosenMenu.chosenSubMenu = $event;
    };
    MenuComponent.prototype.ngOnDestroy = function () {
        this.routerEventSubscription.unsubscribe();
    };
    MenuComponent.prototype.getImageRandom = function () {
        if (MenuComponent.imageRandomNumber === undefined) {
            this.generateImageRandomNumber();
        }
        return MenuComponent.imageRandomNumber;
    };
    MenuComponent.prototype.onErrorUserImage = function () {
        this.userImageMonogramMode = true;
        this.userImageLoadMode = false;
        // this.cdr.markForCheck();
    };
    MenuComponent.prototype.onLoadUserImage = function () {
        this.userImageMonogramMode = false;
        this.userImageLoadMode = false;
        // this.cdr.markForCheck();
    };
    MenuComponent.prototype.detectProfilePictureReset = function () {
        var _this = this;
        this.actions$
            .pipe(untilDestroyed(this), ofActionSuccessful(ResetProfilePictureImageAction))
            .subscribe(function () {
            _this.userImageMonogramMode = false;
            _this.userImageLoadMode = true;
            _this.generateImageRandomNumber();
            _this.cdr.markForCheck();
        });
    };
    MenuComponent.prototype.urlDetector = function () {
        var _this = this;
        this.routerEventSubscription = this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), filter(function () {
            if (_this.disableNextUrlDetect) {
                _this.disableNextUrlDetect = false;
                return false;
            }
            return true;
        }), switchMap(function (event) { return _this.menuService.findSelectedMenuItemByCurrentUrl(event.url).pipe(take(1)); }), filter(function (menu) {
            return _this.chosenMenu.chosenMenu !== menu.selectedMenus.chosenMenu &&
                _this.chosenMenu.chosenSubMenu !== menu.selectedMenus.chosenSubMenu;
        }))
            .subscribe(function (menu) {
            _this.chosenMenu = menu.selectedMenus;
            _this.cdr.markForCheck();
        });
    };
    MenuComponent.prototype.generateImageRandomNumber = function () {
        MenuComponent.imageRandomNumber = getRandomInt(0, 9999999999);
        console.warn('generateImageRandomNumber', MenuComponent.imageRandomNumber);
    };
    MenuComponent.prototype.onClickLogo = function () {
        var _this = this;
        this.menuService
            .findSelectedMenuItemByCurrentUrl('/')
            .pipe(take(1))
            .subscribe(function (menu) {
            if (menu.navigateUrl === undefined) {
                _this.router.navigate([
                    menu.selectedMenus.chosenSubMenu !== undefined ? menu.selectedMenus.chosenSubMenu.route : menu.selectedMenus.chosenMenu.route
                ]);
            }
            else {
                _this.router.navigate([menu.navigateUrl]);
            }
        });
    };
    tslib_1.__decorate([
        Select(UserState.me),
        tslib_1.__metadata("design:type", Observable)
    ], MenuComponent.prototype, "userMe$", void 0);
    return MenuComponent;
}());
export { MenuComponent };
export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
