import { enableProdMode } from '@angular/core';
import { detect } from 'detect-browser';
import 'hammerjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from './environments/environment';
import { setDeviceType } from './main-config';
import { insertOutdatedBrowserDetectScript, setGACode } from './main-functions';
import { UUID } from 'angular2-uuid';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
window.__uniqId = UUID.UUID();
setDeviceType(window);
var browserInfo = detect();
insertOutdatedBrowserDetectScript();
// Fix: recaptcha unhandled promise error
RecaptchaComponent.prototype.ngOnDestroy = function () {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
};
if (environment.production) {
    if (environment.google.analytics.enable) {
        setGACode(environment.google.analytics.code);
    }
    enableProdMode();
}
if (browserInfo.name === 'chrome') {
    var startDOMChangeDetect = function () {
        var mutationObserver = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                Array.from(mutation.addedNodes).forEach(function (node) {
                    if (node !== null && typeof node.querySelector === 'function' && node.querySelector('input') !== null) {
                        node.querySelectorAll('input:not([data-with-last-pass]):not([data-with-browser-autocomplete])').forEach(function (input) {
                            input.setAttribute('data-lpignore', true);
                            input.autocomplete = 'off';
                        });
                        node.querySelectorAll('input[data-with-last-pass]').forEach(function (input) {
                            input.setAttribute('data-lpignore', false);
                        });
                        node.querySelectorAll('input[data-with-browser-autocomplete]').forEach(function (input) {
                            input.autocomplete = 'on';
                        });
                    }
                });
            });
        });
        mutationObserver.observe(document.documentElement, {
            attributes: false,
            childList: true,
            characterData: false,
            subtree: true
        });
    };
    startDOMChangeDetect();
}
var bootstrap = function () { return __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory); };
var ɵ0 = bootstrap;
bootstrap().catch(function (err) { return console.log(err); });
export { ɵ0 };
