import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WINDOW } from '../../common/window.token';
import { ROUTE_USER_LOGIN } from '../route.paths';
import { UserState } from '../state/user.state';

export const userGetUrl = { url: '' };

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private store: Store, private router: Router, @Inject(WINDOW) private window: Window) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }

  private check(): Observable<boolean> {
    // debugger
    userGetUrl.url = this.window.location.hash.slice(1, this.window.location.hash.length);
    return this.store.selectOnce(UserState.loggedIn).pipe(
      map(isLoggedIn => {
        if (!isLoggedIn) {
          const userLoginRoute = `/${ROUTE_USER_LOGIN}`;
          if (this.router.url !== userLoginRoute) {
            this.router.navigate([userLoginRoute]);
          }
          return false;
        }
        return true;
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.check();
  }
}
