export function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}
export enum IconTypeEnum {
  MATERIAL,
  SVG
}
export interface LiveAutocompleteOptionModelIconModel {
  type: IconTypeEnum;
  icon: string;
}

export type LiveAutocompleteOptionModelIconFunction<VIEW_MODEL> = (model: VIEW_MODEL) => undefined | LiveAutocompleteOptionModelIconModel;
