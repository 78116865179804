import * as tslib_1 from "tslib";
import { Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { menuConfig } from '../../../config/menu.config';
import { UserState } from '../../user/state/user.state';
import { deepmerge } from '../../utils/common/deep-merge';
import { deepEqual } from '../../utils/function/deep-equal';
import { isFunction } from '../../utils/type-guard/is-function';
import { isPresent } from '../../utils/type-guard/is-present';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
var MenuService = /** @class */ (function () {
    function MenuService(/*private router: Router,*/ injector, store) {
        var _this = this;
        this.injector = injector;
        this.store = store;
        this._menu$ = new BehaviorSubject(deepmerge({}, menuConfig));
        this.menu$ = this._menu$.pipe(switchMap(function (menu) {
            var operator = _this.getMenuAuthorityFilterOperator(menu);
            return _this.store.select(UserState.loggedIn).pipe(filter(function (loggedIn) { return loggedIn; }), switchMap(function () {
                return _this.store.select(UserState.user).pipe(filter(function (state) { return isPresent(state); }), distinctUntilChanged(function (oldValue, newValue) { return deepEqual(oldValue, newValue); }), switchMap(function () { return _this.store.select(UserState.authorities); }), operator);
            }));
        }), this.getMenuShowContextFilterOperator(), shareReplay(1));
    }
    MenuService.prototype.getMenuObservable = function () {
        return this.menu$;
    };
    MenuService.prototype.getMenuCurrentValue = function () {
        return this._menu$.getValue();
    };
    /**
     * Authority-k alapján szűri
     * , hogy mit láthat a user a paraméterben megadott menürészlet alapján
     * @param menus
     */
    MenuService.prototype._authorizedMenuFilter = function (menus, authorities) {
        return menus.filter(function (menu) { return authorities.includes(menu.authority); });
    };
    /**
     * Authority-k alapján szűri, hogy mit láthat a user a menüben
     * végig megy a Fő menü alsó és felső részén, illetve annak minden gyermek submenüjén
     * @param menuWrapper
     */
    MenuService.prototype.filterMenuWrapperByAuthority = function (menuWrapper, authorities) {
        var _this = this;
        menuWrapper.top = this._authorizedMenuFilter(menuWrapper.top, authorities);
        menuWrapper.bottom = this._authorizedMenuFilter(menuWrapper.bottom, authorities);
        var newMenuWrapper = tslib_1.__assign({}, menuWrapper);
        tslib_1.__spread(newMenuWrapper.top, newMenuWrapper.bottom).forEach(function (menu) {
            menu.top = _this._authorizedMenuFilter(menu.top, authorities);
            menu.bottom = _this._authorizedMenuFilter(menu.bottom, authorities);
        });
        return newMenuWrapper;
    };
    MenuService.prototype.getMenuAuthorityFilterOperator = function (menuWrapper) {
        var _this = this;
        return map(function (authorities) { return _this.filterMenuWrapperByAuthority(menuWrapper, authorities); });
    };
    MenuService.prototype.showContextFilterItems = function (menuItem) {
        var _this = this;
        if (isFunction(menuItem.showContext)) {
            var cbArgs_1 = [];
            if (Array.isArray(menuItem.showContextDeps)) {
                menuItem.showContextDeps.forEach(function (dep) { return cbArgs_1.push(_this.injector.get(dep)); });
            }
            return menuItem.showContext.apply(menuItem, tslib_1.__spread(cbArgs_1));
        }
        return true;
    };
    // afterClickedFilterItems(menuItem: (SubMenuConfigModel)): boolean {
    //   if (isFunction(menuItem.afterClicked)) {
    //     const cbArgs = [];
    //     if (Array.isArray(menuItem.afterClicked$Deps)) {
    //       menuItem.afterClicked$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
    //     }
    //     return menuItem.afterClicked(...cbArgs);
    //   }
    //   return true;
    // }
    MenuService.prototype.getMenuShowContextFilterOperator = function () {
        var _this = this;
        return map(function (menuWrapper) {
            // check top level show items by context method
            menuWrapper.top = menuWrapper.top
                .filter(function (menuItem) { return _this.showContextFilterItems(menuItem); })
                .map(function (menuItem) {
                if (isFunction(menuItem.showContext$)) {
                    var cbArgs_2 = [];
                    if (Array.isArray(menuItem.showContext$Deps)) {
                        menuItem.showContext$Deps.forEach(function (dep) { return cbArgs_2.push(_this.injector.get(dep)); });
                    }
                    menuItem._showContext$ = menuItem.showContext$.apply(menuItem, tslib_1.__spread(cbArgs_2));
                }
                return menuItem;
            });
            menuWrapper.bottom = menuWrapper.bottom
                .filter(function (menuItem) { return _this.showContextFilterItems(menuItem); })
                .map(function (menuItem) {
                if (isFunction(menuItem.showContext$)) {
                    var cbArgs_3 = [];
                    if (Array.isArray(menuItem.showContext$Deps)) {
                        menuItem.showContext$Deps.forEach(function (dep) { return cbArgs_3.push(_this.injector.get(dep)); });
                    }
                    menuItem._showContext$ = menuItem.showContext$.apply(menuItem, tslib_1.__spread(cbArgs_3));
                }
                return menuItem;
            });
            // check sub level show items by context method
            menuWrapper.top = menuWrapper.top.map(function (menuItem) {
                menuItem.top = menuItem.top
                    .filter(function (subMenuItem) { return _this.showContextFilterItems(subMenuItem); })
                    .map(function (subMenuItem) {
                    if (isFunction(subMenuItem.showContext$)) {
                        var cbArgs_4 = [];
                        if (Array.isArray(subMenuItem.showContext$Deps)) {
                            subMenuItem.showContext$Deps.forEach(function (dep) { return cbArgs_4.push(_this.injector.get(dep)); });
                        }
                        subMenuItem._showContext$ = subMenuItem.showContext$.apply(subMenuItem, tslib_1.__spread(cbArgs_4));
                    }
                    return subMenuItem;
                }); // , this.afterClickedFilterItems(subMenuItem)));
                menuItem.bottom = menuItem.bottom
                    .filter(function (subMenuItem) { return _this.showContextFilterItems(subMenuItem); })
                    .map(function (subMenuItem) {
                    if (isFunction(subMenuItem.showContext$)) {
                        var cbArgs_5 = [];
                        if (Array.isArray(subMenuItem.showContext$Deps)) {
                            subMenuItem.showContext$Deps.forEach(function (dep) { return cbArgs_5.push(_this.injector.get(dep)); });
                        }
                        subMenuItem._showContext$ = subMenuItem.showContext$.apply(subMenuItem, tslib_1.__spread(cbArgs_5));
                    }
                    return subMenuItem;
                }); // , this.afterClickedFilterItems(subMenuItem)));
                return menuItem;
            });
            menuWrapper.bottom = menuWrapper.bottom.map(function (menuItem) {
                menuItem.top = menuItem.top
                    .filter(function (subMenuItem) { return _this.showContextFilterItems(subMenuItem); })
                    .map(function (subMenuItem) {
                    if (isFunction(subMenuItem.showContext$)) {
                        var cbArgs_6 = [];
                        if (Array.isArray(subMenuItem.showContext$Deps)) {
                            subMenuItem.showContext$Deps.forEach(function (dep) { return cbArgs_6.push(_this.injector.get(dep)); });
                        }
                        subMenuItem._showContext$ = subMenuItem.showContext$.apply(subMenuItem, tslib_1.__spread(cbArgs_6));
                    }
                    return subMenuItem;
                }); //, this.afterClickedFilterItems(subMenuItem)));
                menuItem.bottom = menuItem.bottom
                    .filter(function (subMenuItem) { return _this.showContextFilterItems(subMenuItem); })
                    .map(function (subMenuItem) {
                    if (isFunction(subMenuItem.showContext$)) {
                        var cbArgs_7 = [];
                        if (Array.isArray(subMenuItem.showContext$Deps)) {
                            subMenuItem.showContext$Deps.forEach(function (dep) { return cbArgs_7.push(_this.injector.get(dep)); });
                        }
                        subMenuItem._showContext$ = subMenuItem.showContext$.apply(subMenuItem, tslib_1.__spread(cbArgs_7));
                    }
                    return subMenuItem;
                }); //, this.afterClickedFilterItems(subMenuItem)));
                return menuItem;
            });
            return menuWrapper;
        });
    };
    /**
     * Megadott url alapján kikeresi a config-ból, hogy melyik menün + submenün vagyunk aktuálisan
     * @param currentUrl
     * @param menu
     */
    MenuService.prototype.findSelectedMenuItemByCurrentUrl = function (currentUrl, menu) {
        var _this = this;
        var menu$;
        if (menu !== undefined) {
            menu$ = of(menu);
        }
        else {
            menu$ = this.menu$;
        }
        return menu$.pipe(map(function (menuWrapper) {
            var selectedMenus = null;
            var allMenus = tslib_1.__spread(menuWrapper.top, menuWrapper.bottom);
            allMenus.forEach(function (_menu) {
                if (isPresent(_menu.route) && _menu.route === currentUrl) {
                    selectedMenus = { chosenMenu: _menu, chosenSubMenu: undefined };
                }
                if (_menu.hidden === undefined) {
                    _menu.hidden = [];
                }
                // ha van olyan eleme, aminek a route-ja = az url
                var chosenMenu = tslib_1.__spread(_menu.top, _menu.bottom, _menu.hidden).find(function (subMenu) {
                    return currentUrl.includes(subMenu.route);
                });
                if (chosenMenu) {
                    selectedMenus = { chosenMenu: _menu, chosenSubMenu: chosenMenu };
                }
            });
            if (!isPresent(selectedMenus)) {
                if (allMenus.length > 0) {
                    selectedMenus = {
                        chosenMenu: allMenus[0],
                        chosenSubMenu: allMenus[0].top[0] || allMenus[0].top[0]
                    };
                }
                else {
                    selectedMenus = {
                        chosenMenu: undefined,
                        chosenSubMenu: undefined
                    };
                }
            }
            return { selectedMenus: selectedMenus, navigateUrl: _this.getTopAccesMenuItemUrl(selectedMenus, menuWrapper) };
        }));
    };
    /**
     * ha nem volt ilyen url-el elem a listában, az első elemre navigáljuk
     * azért nem 404-re, mert app.routing default az invoice/dashboard-ra navigálna
     * de nem mindegyik fajta felhasználónak lesz a dashboard elérhető! pl:bookkeeper(könyvelő)
     * @param selectedMenus
     * @param menuWrapper
     */
    MenuService.prototype.getTopAccesMenuItemUrl = function (selectedMenus, menuWrapper) {
        if (!isPresent(selectedMenus) && menuWrapper.top.length > 0) {
            if (menuWrapper.top[0].top.length > 0) {
                return menuWrapper.top[0].top[0].route;
            }
            else if (menuWrapper.top[0].bottom.length > 0) {
                return menuWrapper.top[0].bottom[0].route;
            }
        }
        return undefined;
    };
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(i0.inject(i0.INJECTOR), i0.inject(i1.Store)); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };
