import { InjectionToken } from '@angular/core';
import { Store } from '@ngxs/store';
import { default as jwt_decode } from 'jwt-decode';
import { timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserState } from '../user/state/user.state';
import { isPresent } from './type-guard/is-present';
import { isString } from './type-guard/is-string';
export var TGStorageRouterExcludePathsToken = new InjectionToken('TG_STORAGE_ROUTER_EXCLUDE_PATHS_TOKEN');
var TGStorageEngine = /** @class */ (function () {
    function TGStorageEngine(injector) {
        var _this = this;
        this.injector = injector;
        this.routerExcludedPaths = [];
        timer(0).subscribe(function () {
            _this.routerExcludedPaths = _this.injector.get(TGStorageRouterExcludePathsToken);
            if (!isPresent(_this.routerExcludedPaths)) {
                _this.routerExcludedPaths = [];
            }
            _this.getUserId();
        });
    }
    Object.defineProperty(TGStorageEngine.prototype, "user", {
        set: function (user) {
            this.userId = user.user_name;
        },
        enumerable: true,
        configurable: true
    });
    TGStorageEngine.prototype.getUserId = function () {
        this.store = this.injector.get(Store);
        var user = this.store.selectSnapshot(UserState.user);
        if (isPresent(user)) {
            this.userId = user.user_name;
        }
        else {
            this.userId = undefined;
        }
    };
    TGStorageEngine.prototype.getItem = function (key) {
        if (['user', 'i18n'].indexOf(key) === -1) {
            key = this.keyPrefix(key);
        }
        if (key !== undefined) {
            var value = localStorage.getItem(environment.appName + "__" + key);
            if (key === 'user' && isString(value)) {
                try {
                    var user = jwt_decode(value);
                    if (user.user_name !== undefined) {
                        this.userId = user.user_name;
                    }
                }
                catch (e) { }
            }
            return value;
        }
        // tslint:disable-next-line
        return null;
    };
    TGStorageEngine.prototype.setItem = function (key, value) {
        var routerSave = false;
        if (['user', 'i18n'].indexOf(key) === -1) {
            if (key === 'router') {
                routerSave = true;
            }
            key = this.keyPrefix(key);
        }
        if (key !== undefined && value !== JSON.stringify({ notFoundAuthFutureValue: 1 })) {
            // TODO ATNEZNI ES KIVENNI A NOT FOUND AUTH FUTURE VALUE-t
            if (typeof value === 'object' && value.notFoundAuthFutureValue === true) {
                delete value.notFoundAuthFutureValue;
            }
            else if (isString(value)) {
                var json = JSON.parse(value);
                if (json.notFoundAuthFutureValue !== undefined && isPresent(localStorage.getItem(environment.appName + "__" + key))) {
                    return;
                }
                delete json.notFoundAuthFutureValue;
                value = JSON.stringify(json);
            }
            if (isString(value)) {
                if (routerSave && this.routerExcludedPaths.length > 0) {
                    var routerState = JSON.parse(value);
                    if (isPresent(routerState.state) &&
                        isPresent(routerState.state.url) &&
                        this.routerExcludedPaths.indexOf(routerState.state.url.split('?')[0]) > -1) {
                        return;
                    }
                }
                localStorage.setItem(environment.appName + "__" + key, value);
            }
        }
    };
    TGStorageEngine.prototype.keyPrefix = function (key, tryGetUserId) {
        if (tryGetUserId === void 0) { tryGetUserId = true; }
        if (this.userId === undefined) {
            if (tryGetUserId) {
                this.getUserId();
                return this.keyPrefix(key, false);
            }
            return undefined;
        }
        return this.userId + "_" + key;
    };
    Object.defineProperty(TGStorageEngine.prototype, "length", {
        get: function () {
            return localStorage.length;
        },
        enumerable: true,
        configurable: true
    });
    TGStorageEngine.prototype.clear = function () {
        localStorage.clear();
    };
    TGStorageEngine.prototype.key = function (index) {
        return localStorage.key(index);
    };
    TGStorageEngine.prototype.removeItem = function (key) {
        if (key !== 'user') {
            key = this.keyPrefix(key);
        }
        if (key !== undefined) {
            localStorage.removeItem(environment.appName + "__" + key);
        }
    };
    return TGStorageEngine;
}());
export { TGStorageEngine };
