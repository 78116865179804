import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';

@Injectable()
export class ErrorCenterService {
  private static _INSTANCE: ErrorCenterService;
  private _translateService: TranslateService;

  private _errorCodes$ = new ReplaySubject(1);
  private _ngZone: NgZone;
  constructor(private http: HttpClient, readonly matDialog: MatDialog, private injector: Injector) {
    ErrorCenterService._INSTANCE = this;
  }

  loadTranslate(path: string) {
    this.http.get(path).subscribe(errorCodes => this._errorCodes$.next(errorCodes));
  }

  get errorCodes$() {
    return this._errorCodes$.asObservable();
  }

  static get INSTANCE(): ErrorCenterService {
    return this._INSTANCE;
  }

  get translateService(): TranslateService {
    if (this._translateService === undefined) {
      this._translateService = this.injector.get(TranslateService);
    }
    return this._translateService;
  }

  get ngZone(): NgZone {
    if (this._ngZone === undefined) {
      this._ngZone = this.injector.get(NgZone);
    }
    return this._ngZone;
  }
}
