import { NoopFunctionType } from '../../utils/noop/noop-function.type';
import { ErrorCenterService } from '../error-center.service';
import { showErrorDialog } from './show-error-dialog.function';
import { isPresent } from '../../utils/type-guard/is-present';
import { timer } from 'rxjs';

/**
 * Error dialog
 *
 * @param clickCallback
 * @param commonErrorDialogTitleTranslateKey
 * @param commonErrorDialogMessageTranslateKey
 * @param commonErrorDialogButtonTranslateKey
 * @param backdropClass
 */
export function showTranslatedErrorDialog(
  clickCallback?: NoopFunctionType,
  commonErrorDialogTitleTranslateKey?: string,
  commonErrorDialogMessageTranslateKey?: string,
  commonErrorDialogButtonTranslateKey?: string,
  backdropClass?: string
) {
  if (!isPresent(commonErrorDialogTitleTranslateKey)) {
    commonErrorDialogTitleTranslateKey = 'COMMON_ERROR_DIALOG.TITLE';
  }
  if (!isPresent(commonErrorDialogMessageTranslateKey)) {
    commonErrorDialogMessageTranslateKey = 'COMMON_ERROR_DIALOG.TEXT';
  }
  if (!isPresent(commonErrorDialogButtonTranslateKey)) {
    commonErrorDialogButtonTranslateKey = 'COMMON.ACTION_BUTTONS.OK';
  }

  const errorCenterService = ErrorCenterService.INSTANCE;
  timer(0).subscribe(() =>
    errorCenterService.translateService
      .get([commonErrorDialogTitleTranslateKey, commonErrorDialogMessageTranslateKey, commonErrorDialogButtonTranslateKey])
      .subscribe(translate =>
        showErrorDialog(
          errorCenterService.matDialog,
          translate[commonErrorDialogTitleTranslateKey],
          translate[commonErrorDialogMessageTranslateKey],
          translate[commonErrorDialogButtonTranslateKey],
          clickCallback,
          backdropClass
        )
      )
  );
}
