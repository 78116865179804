import * as tslib_1 from "tslib";
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { default as jwt_decode } from 'jwt-decode';
import { asapScheduler, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { filter, switchMap, take } from 'rxjs/operators';
import { RenewTokenSuccessAction } from '../state/action/renew-token-success.action';
import { RenewTokenAction } from '../state/action/renew-token.action';
import { UserState } from '../state/user.state';
import * as i0 from "@angular/core";
import * as i1 from "./exlude-token-require.token";
import * as i2 from "@ngxs/store";
import * as i3 from "../state/user.state";
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(excludeTokenRequire, store, actions$, userState) {
        this.excludeTokenRequire = excludeTokenRequire;
        this.store = store;
        this.actions$ = actions$;
        this.userState = userState;
        this.renewRun$ = new BehaviorSubject(false);
        /**
         * csak a kovetkezo intercept-nel vesszuk figyelembe
         */
        this._excludeNext = [];
    }
    Object.defineProperty(TokenInterceptor.prototype, "excludeNext", {
        set: function (value) {
            this._excludeNext = value;
        },
        enumerable: true,
        configurable: true
    });
    TokenInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var reqUrl = req.url;
        var excludeTokens = tslib_1.__spread(this._excludeNext, this.excludeTokenRequire);
        var foundConf = excludeTokens.find(function (conf) {
            if (conf.regexp === true) {
                return reqUrl.match(new RegExp(conf.value.replace(new RegExp('\\?', 'g'), '\\?'), 'g')) !== null;
            }
            else {
                return conf.value === reqUrl;
            }
        });
        if (this._excludeNext.length > 0) {
            // reset exclude next
            this._excludeNext = [];
        }
        if (foundConf === undefined) {
            if (req.url.startsWith('assets/')) {
                return next.handle(req);
            }
            if (this.store.selectSnapshot(UserState.renewRun) === false) {
                // check access token expire
                var currentTime = new Date().getTime() / 1000;
                if (currentTime > this.store.selectSnapshot(UserState.user).exp) {
                    // expired token
                    var refreshToken = this.store.selectSnapshot(UserState.refreshToken);
                    if (currentTime < jwt_decode(refreshToken).exp) {
                        // renew token
                        this.renewRun$.next(true);
                        return this.store.dispatch(new RenewTokenAction()).pipe(switchMap(function () {
                            return _this.actions$.pipe(ofActionSuccessful(RenewTokenSuccessAction), take(1), tap(function () { return asapScheduler.schedule(function () { return _this.renewRun$.next(false); }); }));
                        }), switchMap(function () {
                            return next.handle(req.clone({
                                headers: req.headers.set('Authorization', "Bearer " + _this.store.selectSnapshot(UserState.accessToken))
                            }));
                        }));
                    }
                    else {
                        // expired refresh token
                        UserState.timeoutToken = true;
                        this.userState.showTokenExpireDialog(this.store.dispatch);
                    }
                }
            }
            return this.renewRun$.pipe(filter(function (run) { return run === false; }), take(1), switchMap(function () {
                return next.handle(req.clone({
                    headers: req.headers.set('Authorization', "Bearer " + _this.store.selectSnapshot(UserState.accessToken))
                }));
            }));
        }
        else {
            return next.handle(req);
        }
    };
    TokenInterceptor.ngInjectableDef = i0.defineInjectable({ factory: function TokenInterceptor_Factory() { return new TokenInterceptor(i0.inject(i1.EXCLUDE_TOKEN_REQUIRE), i0.inject(i2.Store), i0.inject(i2.Actions), i0.inject(i3.UserState)); }, token: TokenInterceptor, providedIn: "root" });
    return TokenInterceptor;
}());
export { TokenInterceptor };
