import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserInfo, detect } from 'detect-browser';
import 'hammerjs';
import { RecaptchaComponent } from 'ng-recaptcha';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { setDeviceType } from './main-config';
import { insertOutdatedBrowserDetectScript, setGACode } from './main-functions';
import { UUID } from 'angular2-uuid';

(window as any).__uniqId = UUID.UUID();

setDeviceType(window);
const browserInfo = detect() as BrowserInfo;

insertOutdatedBrowserDetectScript();

// Fix: recaptcha unhandled promise error
RecaptchaComponent.prototype.ngOnDestroy = function() {
  if (this.subscription) {
    this.subscription.unsubscribe();
  }
};

if (environment.production) {
  if (environment.google.analytics.enable) {
    setGACode(environment.google.analytics.code);
  }
  enableProdMode();
}

if (browserInfo.name === 'chrome') {
  const startDOMChangeDetect = () => {
    const mutationObserver = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach(mutation => {
        Array.from(mutation.addedNodes).forEach((node: any) => {
          if (node !== null && typeof node.querySelector === 'function' && node.querySelector('input') !== null) {
            node.querySelectorAll('input:not([data-with-last-pass]):not([data-with-browser-autocomplete])').forEach(input => {
              input.setAttribute('data-lpignore', true);
              input.autocomplete = 'off';
            });
            node.querySelectorAll('input[data-with-last-pass]').forEach(input => {
              input.setAttribute('data-lpignore', false);
            });
            node.querySelectorAll('input[data-with-browser-autocomplete]').forEach(input => {
              input.autocomplete = 'on';
            });
          }
        });
      });
    });
    mutationObserver.observe(document.documentElement, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true
    });
  };
  startDOMChangeDetect();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

bootstrap().catch(err => console.log(err));
