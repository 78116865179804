import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule, MatRippleModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MobileAppLayoutComponent } from './mobile-app-layout/mobile-app-layout.component';

@NgModule({
  declarations: [MobileAppLayoutComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    TranslateModule,
    MatIconModule,
    RouterModule,
    MatSidenavModule,
    MatRippleModule,
    MatButtonModule
  ],
  exports: [MobileAppLayoutComponent]
})
export class MobileAppLayoutModule {}
