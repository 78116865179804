import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
var ExpiredTokenError = /** @class */ (function (_super) {
    tslib_1.__extends(ExpiredTokenError, _super);
    function ExpiredTokenError(oldHttpError) {
        return _super.call(this, oldHttpError) || this;
    }
    return ExpiredTokenError;
}(HttpErrorResponse));
export { ExpiredTokenError };
