/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./menu-display.component";
import * as i9 from "@angular/common/http";
import * as i10 from "@ngxs/store";
var styles_MenuDisplayComponent = [i0.styles];
var RenderType_MenuDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuDisplayComponent, data: {} });
export { RenderType_MenuDisplayComponent as RenderType_MenuDisplayComponent };
function View_MenuDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "custom-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "mat-progress-spinner", [["class", "mat-progress-spinner"], ["color", "accent"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(4, 49152, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], mode: [1, "mode"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_7 = "accent"; var currVal_8 = "indeterminate"; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 4).diameter; var currVal_2 = i1.ɵnov(_v, 4).diameter; var currVal_3 = ((i1.ɵnov(_v, 4).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 4).mode === "determinate") ? 100 : null); var currVal_5 = i1.ɵnov(_v, 4).value; var currVal_6 = i1.ɵnov(_v, 4).mode; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_MenuDisplayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n      ", "\n    "])), i1.ɵpod(2, { backDaysCounter: 0 }), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform("SUBSCRIPTION.MENU_DISPLAY.LABEL.PROBATION", _ck(_v, 2, 0, _co.backDaysCounter))); _ck(_v, 1, 0, currVal_0); }); }
function View_MenuDisplayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i7.TranslatePipe, [i7.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n    "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("SUBSCRIPTION.MENU_DISPLAY.LABEL.OVER")); _ck(_v, 2, 0, currVal_0); }); }
function View_MenuDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "d-flex justify-content-start w-100"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuDisplayComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵand(0, [["probationOver", 2]], null, 0, null, View_MenuDisplayComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.backDaysCounter >= 0); var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_MenuDisplayComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_MenuDisplayComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["tpl", 2]], null, 0, null, View_MenuDisplayComponent_2)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.loading$)); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_MenuDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tg-menu-display", [], null, null, null, View_MenuDisplayComponent_0, RenderType_MenuDisplayComponent)), i1.ɵdid(1, 180224, null, 0, i8.MenuDisplayComponent, [i9.HttpClient, i10.Store], null, null)], null, null); }
var MenuDisplayComponentNgFactory = i1.ɵccf("tg-menu-display", i8.MenuDisplayComponent, View_MenuDisplayComponent_Host_0, {}, {}, []);
export { MenuDisplayComponentNgFactory as MenuDisplayComponentNgFactory };
