import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { menuConfig } from '../../../config/menu.config';
import { matSnackbarErrorConfig } from '../../common/mat-snackbar-error.config';
import { MenuService } from './menu.service';
import * as i0 from "@angular/core";
import * as i1 from "./menu.service";
import * as i2 from "@angular/router";
import * as i3 from "@angular/material/snack-bar";
import * as i4 from "@ngx-translate/core";
var HasAccessRouteGuard = /** @class */ (function () {
    function HasAccessRouteGuard(menuService, router, matSnackBar, translateService) {
        this.menuService = menuService;
        this.router = router;
        this.matSnackBar = matSnackBar;
        this.translateService = translateService;
    }
    HasAccessRouteGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.check(state.url);
    };
    HasAccessRouteGuard.prototype.check = function (url, autoRedirect) {
        var _this = this;
        if (autoRedirect === void 0) { autoRedirect = true; }
        return this.menuService.findSelectedMenuItemByCurrentUrl(url).pipe(take(1), switchMap(function (menu) {
            if (menu.navigateUrl !== undefined) {
                return _this.menuService.findSelectedMenuItemByCurrentUrl(url, menuConfig).pipe(map(function (_menu) {
                    var route;
                    if (_menu.selectedMenus['chosenSubMenu'] !== undefined) {
                        route = _menu.selectedMenus['chosenSubMenu'].title;
                    }
                    else {
                        route = _menu.selectedMenus['chosenMenu'].title;
                    }
                    _this.matSnackBar.open(_this.translateService.instant('COMMON_ERROR.NOT_ACCESS_ROUTE', {
                        route: route
                    }), _this.translateService.instant('COMMON.SNACKBAR.CLOSE'), matSnackbarErrorConfig);
                    if (autoRedirect) {
                        return _this.router.parseUrl(menu.navigateUrl);
                    }
                    return false;
                }));
            }
            if (menu.selectedMenus.chosenSubMenu !== undefined && url.startsWith(menu.selectedMenus.chosenSubMenu.route)) {
                return of(true);
            }
            if (menu.selectedMenus.chosenSubMenu !== undefined) {
                if (menu.selectedMenus.chosenSubMenu.route !== url) {
                    return of(_this.router.parseUrl(menu.selectedMenus.chosenSubMenu.route));
                }
            }
            else if (menu.selectedMenus.chosenMenu !== undefined && menu.selectedMenus.chosenMenu.route !== url) {
                return of(_this.router.parseUrl(menu.selectedMenus.chosenMenu.route));
            }
            return of(true);
        }));
    };
    HasAccessRouteGuard.ngInjectableDef = i0.defineInjectable({ factory: function HasAccessRouteGuard_Factory() { return new HasAccessRouteGuard(i0.inject(i1.MenuService), i0.inject(i2.Router), i0.inject(i3.MatSnackBar), i0.inject(i4.TranslateService)); }, token: HasAccessRouteGuard, providedIn: "root" });
    return HasAccessRouteGuard;
}());
export { HasAccessRouteGuard };
