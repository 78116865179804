import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { UUID } from 'angular2-uuid';
import { environment } from '../../../environments/environment';
import { ParamsEncoder } from '../utils/http/params-encoder';
import { Memoize } from '../utils/memoize/memoize.decorator';
import { isString } from '../utils/type-guard/is-string';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@ngxs/store";
export var oauthTokenUrl = environment.apiUrls.oauth + "/token";
var UserService = /** @class */ (function () {
    function UserService(http, store) {
        this.http = http;
        this.store = store;
        this.uuid = UUID.UUID();
    }
    UserService.prototype.login = function (username, password) {
        var formData = new FormData();
        formData.append('grant_type', 'password');
        formData.append('username', username);
        formData.append('password', password);
        formData.append('scope', 'all');
        return this.http.post(oauthTokenUrl, formData, {
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: "Basic " + environment.clientSecret,
                Accept: 'application/json'
            }
        });
    };
    UserService.prototype.me = function () {
        return this.http.get("" + environment.apiUrls.userMe);
    };
    UserService.prototype.refreshToken = function (refresh_token) {
        var formData = new FormData();
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', refresh_token);
        return this.http.post(oauthTokenUrl, formData, {
            headers: {
                'Cache-Control': 'no-cache',
                Authorization: "Basic " + environment.clientSecret,
                Accept: 'application/json'
            }
        });
        // .pipe(tap(v => console.log(v)));
    };
    // getBussinessKey() {
    //   return this.http.get<
    //     {
    //       links: string[];
    //       id: string;
    //       definitionId: string;
    //       businessKey: string;
    //       caseInstanceId: string;
    //       ended: boolean;
    //       suspended: boolean;
    //       tenantId: string;
    //     }[]
    //   >(`${environment.apiUrls.processUrl}/rest/process-instance?processDefinitionKey=configureProduct`);
    // }
    UserService.prototype.getCompanyData = function () {
        return this.http.get(environment.apiUrls.company + "/api/company/" + this.store.selectSnapshot(function (states) { return states['user'].me; }).tenants[0].refCompany);
    };
    UserService.prototype.getUsersProfilePictureImages = function (emails) {
        var params = new HttpParams({ encoder: new ParamsEncoder() })
            .set('reduced', 'true')
            .set('withProfilePicture', 'true')
            .set('idEqAny', Array.from(emails.values())
            .sort()
            .join(','));
        return this.http.get(environment.apiUrls.userApi + "/user", {
            params: params
        });
    };
    UserService.prototype.generateNewEmailSetParam = function (emails) {
        return new Set(emails.filter(function (v) { return isString(v) && v.length > 0; }));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient), i0.inject(i2.Store)); }, token: UserService, providedIn: "root" });
    tslib_1.__decorate([
        Memoize(30 * 60 * 1000),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Set]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserService.prototype, "getUsersProfilePictureImages", null);
    return UserService;
}());
export { UserService };
