import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { default as jwt_decode } from 'jwt-decode';
import { asapScheduler, BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { filter, switchMap, take } from 'rxjs/operators';
import { RenewTokenSuccessAction } from '../state/action/renew-token-success.action';
import { RenewTokenAction } from '../state/action/renew-token.action';
import { UserState } from '../state/user.state';
import { EXCLUDE_TOKEN_REQUIRE } from './exlude-token-require.token';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  private renewRun$ = new BehaviorSubject(false);
  constructor(
    @Inject(EXCLUDE_TOKEN_REQUIRE) private excludeTokenRequire: { value: string; regexp?: boolean }[],
    private store: Store,
    private actions$: Actions,
    private userState: UserState
  ) {}

  /**
   * csak a kovetkezo intercept-nel vesszuk figyelembe
   */
  private _excludeNext: { value: string; regexp?: boolean }[] = [];

  set excludeNext(value: { value: string; regexp?: boolean }[]) {
    this._excludeNext = value;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const excludeTokens = [...this._excludeNext, ...this.excludeTokenRequire];
    const foundConf = excludeTokens.find(conf => {
      if (conf.regexp === true) {
        return reqUrl.match(new RegExp(conf.value.replace(new RegExp('\\?', 'g'), '\\?'), 'g')) !== null;
      } else {
        return conf.value === reqUrl;
      }
    });
    if (this._excludeNext.length > 0) {
      // reset exclude next
      this._excludeNext = [];
    }
    if (foundConf === undefined) {
      if (req.url.startsWith('assets/')) {
        return next.handle(req);
      }
      if (this.store.selectSnapshot(UserState.renewRun) === false) {
        // check access token expire
        const currentTime = new Date().getTime() / 1000;
        if (currentTime > this.store.selectSnapshot(UserState.user).exp) {
          // expired token
          const refreshToken = this.store.selectSnapshot(UserState.refreshToken);
          if (currentTime < jwt_decode(refreshToken).exp) {
            // renew token
            this.renewRun$.next(true);
            return this.store.dispatch(new RenewTokenAction()).pipe(
              switchMap(() =>
                this.actions$.pipe(
                  ofActionSuccessful(RenewTokenSuccessAction),
                  take(1),
                  tap(() => asapScheduler.schedule(() => this.renewRun$.next(false)))
                )
              ),
              switchMap(() =>
                next.handle(
                  req.clone({
                    headers: req.headers.set('Authorization', `Bearer ${this.store.selectSnapshot(UserState.accessToken)}`)
                  })
                )
              )
            );
          } else {
            // expired refresh token
            UserState.timeoutToken = true;
            this.userState.showTokenExpireDialog(this.store.dispatch);
          }
        }
      }
      return this.renewRun$.pipe(
        filter(run => run === false),
        take(1),
        switchMap(() =>
          next.handle(
            req.clone({
              headers: req.headers.set('Authorization', `Bearer ${this.store.selectSnapshot(UserState.accessToken)}`)
            })
          )
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
