import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Memoize } from '../utils/memoize/memoize.decorator';
import { tap } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TaxService = /** @class */ (function () {
    function TaxService(http) {
        this.http = http;
        this.baseUrl = environment.apiUrls.invoice + "/api/vatkey";
        this._defaultTax$ = new ReplaySubject(1);
    }
    TaxService.prototype.getAll = function () {
        var _this = this;
        return this.http.get(this.baseUrl).pipe(tap(function (taxes) { return _this._defaultTax$.next(taxes.find(function (tax) { return tax.description === '27%'; })); }));
    };
    TaxService.prototype.computeTaxAmount = function (param) {
        return this.http.post(this.baseUrl, param);
    };
    Object.defineProperty(TaxService.prototype, "defaultTax$", {
        get: function () {
            return this._defaultTax$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    TaxService.ngInjectableDef = i0.defineInjectable({ factory: function TaxService_Factory() { return new TaxService(i0.inject(i1.HttpClient)); }, token: TaxService, providedIn: "root" });
    tslib_1.__decorate([
        Memoize(12 * 60 * 60 * 1000),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], TaxService.prototype, "getAll", null);
    return TaxService;
}());
export { TaxService };
