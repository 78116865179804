import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { isDesktop } from '../main-config';
import { AppLayoutComponent } from './modules/app-layout/app-layout/app-layout.component';
import { HasAccessRouteGuard } from './modules/app-layout/menu/has-access-route.guard';
import { ErrorPage404Component } from './modules/error-page404/error-page404.component';
import { AuthGuard } from './modules/user/guard/auth.guard';
import { NotAuthGuard } from './modules/user/guard/not-auth.guard';
import { RegisteredUserGuard } from './modules/user/guard/registered-user.guard';
import { ROUTE_SETTINGS, ROUTE_SUBSCRIPTION_PROCESS, ROUTE_USER_LOGIN } from './modules/user/route.paths';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/invoice/dashboard'
  },
  {
    path: '',
    component: AppLayoutComponent,
    canActivateChild: [AuthGuard, RegisteredUserGuard, HasAccessRouteGuard],
    children: [
      {
        path: 'invoice',
        loadChildren: './modules/invoice/invoice.module#InvoiceModule'
      },
      {
        path: 'my-subscription',
        loadChildren: './modules/my-subscription/my-subscription.module#MySubscriptionModule'
      },
      {
        path: ROUTE_SUBSCRIPTION_PROCESS,
        loadChildren: './modules/subscription/subscription.module#SubscriptionModule'
      },
      {
        path: ROUTE_SETTINGS,
        loadChildren: './modules/settings/settings.module#SettingsModule'
      },
      {
        path: 'user/me',
        loadChildren: 'src/app/modules/user/me/me.module#MeModule'
      },
      {
        path: 'user/nav-registration',
        loadChildren: 'src/app/modules/user/nav-registration/nav-registration.module#NavRegistrationModule'
      },
      {
        path: 'users',
        loadChildren: 'src/app/modules/users/users.module#UsersModule'
      }
    ]
  },
  // {
  //   path: 'mock-start',
  //   component: MockstartComponent
  // },
  {
    path: 'user',
    loadChildren: './modules/user/user.module#UserModule'
  },
  {
    path: ROUTE_USER_LOGIN,
    loadChildren: './modules/user/login/login.module#LoginModule',
    canActivate: [NotAuthGuard]
  },
  {
    path: '**',
    component: ErrorPage404Component
  }
];

const routerConfig: ExtraOptions = { useHash: true };
if (!isDesktop) {
  routerConfig.preloadingStrategy = PreloadAllModules;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
