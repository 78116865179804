import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, Inject, Injector, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BrowserInfo, detect } from 'detect-browser';
import * as MobileDetect from 'mobile-detect';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { map } from 'rxjs/operators';
import { isDesktop } from '../../../../main-config';
import { fadeInEnter } from '../../../animations';
import { AppTitleService } from '../../../app-title.service';
import { WINDOW } from '../../common/window.token';
import { MenuService } from '../menu/menu.service';
import { MenuConfigModel } from '../menu/model/menu-config.model';

export const slideInAnimation = trigger('routeAnimations', [
  transition('* => *', [
    query(':enter', style({ position: 'fixed', maxWidth: 'calc(100vw - 30px)', width: '100%' }), {
      optional: true
    }),
    query(':leave', style({ position: 'fixed', maxWidth: 'calc(100vw - 30px)', width: '100%' }), {
      optional: true
    }),
    group([
      query(':leave', [style({ transform: 'translateX(0%)' }), animate('0.5s', style({ transform: 'translateX(-100%)' }))], {
        optional: true
      }),
      query(':enter', [style({ transform: 'translateX(100%)' }), animate('0.5s', style({ transform: 'translateX(0%)' }))], {
        optional: true
      })
    ])
  ])
]);

const browser = detect() as BrowserInfo;
const mobileDetect = new MobileDetect(window.navigator.userAgent);
const browserName = `${browser.os.toLowerCase().replace(/ /g, '')}-${browser.name.toLowerCase()}-${
  mobileDetect.mobile() ? 'mobile' : 'other'
}`;

@Component({
  selector: 'tg-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  animations: [fadeInEnter, slideInAnimation]
})
export class AppLayoutComponent implements OnDestroy, AfterViewInit {
  isDesktop = isDesktop;
  @ViewChildren('scrollElement') private scrollElement: QueryList<ElementRef<HTMLDivElement>>;
  readonly appTitle$: Observable<string>;
  menu$: Observable<MenuConfigModel[]> = this.menuService.getMenuObservable().pipe(map(m => [...m.top, ...m.bottom]));
  animationDisableDevices: { [key: string]: string[] } = { '/invoice/dashboard': ['ios-crios-mobile', 'ios-ios-mobile'] };
  disableAnimations = false;
  constructor(
    private injector: Injector,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    appTitleService: AppTitleService,
    private menuService: MenuService
  ) {
    this.appTitle$ = appTitleService.title$;
    router.events
      .pipe(
        untilDestroyed(this),
        filter(evt => evt instanceof NavigationEnd)
      )
      .subscribe((evt: NavigationEnd) => {
        if (this.scrollElement !== undefined && this.scrollElement.length > 0) {
          this.scrollElement.first.nativeElement.scrollTo(0, 0);
        }
        if (Object.keys(this.animationDisableDevices).indexOf(evt.urlAfterRedirects) > -1) {
          this.disableAnimations = this.animationDisableDevices[evt.urlAfterRedirects].indexOf(browserName) > -1;
        }
      });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    // let viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    // let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    // let isPortrait = viewportHeight > viewportWidth;
    //
    // window.addEventListener('resize', onresize);
    //
    // function onresize() {
    //   let newViewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    //   let newViewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    //   let hasOrientationChanged = newViewportHeight > newViewportWidth !== isPortrait;
    //   let addressbarHeight = 130;
    //
    //   if (!hasOrientationChanged && newViewportHeight !== viewportHeight) {
    //     addressbarHeight = Math.abs(newViewportHeight - viewportHeight);
    //     if (newViewportHeight < viewportHeight) {
    //       // Android Chrome address bar has appeared
    //     } else {
    //       // Android Chrome address bar has disappeared
    //     }
    //   } else if (hasOrientationChanged) {
    //     // Orientation change
    //   }
    //
    //   viewportHeight = newViewportHeight;
    //   viewportWidth = newViewportWidth;
    //   isPortrait = viewportHeight > viewportWidth;
    // }
  }
}
