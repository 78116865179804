import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { isPresent } from '../../utils/type-guard/is-present';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
var RegisteredUserGuard = /** @class */ (function () {
    function RegisteredUserGuard(store, router) {
        this.store = store;
        this.router = router;
    }
    RegisteredUserGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.store.selectOnce(function (states) {
            return !isPresent(states['process']) ||
                !isPresent(states['process'].task) ||
                (states['process'].task.formKey !== 'setProcessParamDeployRecieveInvoice' && states['process'].task.formKey !== 'inputCompanyForm');
        });
    };
    RegisteredUserGuard.ngInjectableDef = i0.defineInjectable({ factory: function RegisteredUserGuard_Factory() { return new RegisteredUserGuard(i0.inject(i1.Store), i0.inject(i2.Router)); }, token: RegisteredUserGuard, providedIn: "root" });
    return RegisteredUserGuard;
}());
export { RegisteredUserGuard };
