import { Component, Injector, Input } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { browserName } from '../../../../main-config';
import { fadeInEnter } from '../../../animations';
import { slideInAnimation } from '../../app-layout/app-layout/app-layout.component';
import { menuHandleAfterClick } from '../../app-layout/menu/menu-handle-after-click.function';
import { MenuConfigModel } from '../../app-layout/menu/model/menu-config.model';
import { SubMenuConfigModel } from '../../app-layout/menu/model/sub-menu-config.model';
import { isString } from '../../utils/type-guard/is-string';

@Component({
  selector: 'tg-mobile-app-layout',
  templateUrl: './mobile-app-layout.component.html',
  styleUrls: ['./mobile-app-layout.component.scss'],
  animations: [fadeInEnter, slideInAnimation]
})
export class MobileAppLayoutComponent {
  @Input() appTitle$: Observable<string>;
  @Input() menu$: Observable<MenuConfigModel[]>;
  version = environment.VERSION;
  browserName = browserName;
  @Input() disableAnimations: boolean;

  constructor(private injector: Injector, private router: Router) {}

  onClickMenuItem(matSidenav: MatSidenav, subMenuItem: SubMenuConfigModel) {
    menuHandleAfterClick(subMenuItem, this.injector);
    if (isString(subMenuItem.route) && subMenuItem.route.length > 0) {
      this.router.navigate([subMenuItem.route]).then(() => matSidenav.close());
    } else {
      matSidenav.close();
    }
  }

  onClickRootMenuItem(matSidenav: MatSidenav, rootMenuItem: MenuConfigModel) {
    this.router.navigate([rootMenuItem.route]).then(() => matSidenav.close());
  }
}
