import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Action, State, StateContext } from '@ngxs/store';
import { timer } from 'rxjs';
import { isString } from '../../utils/type-guard/is-string';
import { AddAppOverlayAction } from './action/add-app-overlay.action';
import { RemoveAppLoadingAction } from './action/remove-app-loading.action';

@State<any>({
  name: 'appLayout',
  defaults: undefined
})
export class AppLayoutState {
  constructor(@Inject(DOCUMENT) private document: Document, private matDialog: MatDialog) {
    this.matDialog.afterOpen.subscribe(() => this.document.body.classList.add('overflow-hidden'));
    this.matDialog.afterAllClosed.subscribe(() => {
      const appOverlay: HTMLDivElement = this.getAppOverlayElement();
      if (appOverlay !== null && appOverlay.style.display === 'none') {
        this.document.body.classList.remove('overflow-hidden');
      }
    });
  }

  @Action(RemoveAppLoadingAction)
  removeAppLoading() {
    const pageLoaderElement = this.document.getElementById('page-loader');
    if (pageLoaderElement !== null) {
      timer(2000).subscribe(() => {
        pageLoaderElement.classList.add('finished');
        timer(1500).subscribe(() => {
          if (this.document.getElementById('page-loader') !== null) {
            this.document.getElementById('page-loader').remove();
            if (this.matDialog.openDialogs.length === 0) {
              this.document.body.classList.remove('overflow-hidden');
            }
          }
        });
      });
    }
  }

  @Action(AddAppOverlayAction)
  addAppOverlay(state: StateContext<any>, action: AddAppOverlayAction) {
    const appOverlay: HTMLDivElement = this.getAppOverlayElement();
    if (appOverlay !== null) {
      const pElems = appOverlay.getElementsByTagName('h2');
      if (pElems.length === 1) {
        if (isString(action.text) && action.text.length > 0) {
          (pElems[0] as HTMLParagraphElement).innerText = action.text;
        }
        appOverlay.style.display = 'flex';
        this.document.body.classList.add('overflow-hidden');
      }
    }
  }

  private getAppOverlayElement() {
    return (this.document.getElementById('app-overlay') as any) as HTMLDivElement;
  }
}
