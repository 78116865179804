import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopLayoutLoaderComponent } from './top-layout-loader/top-layout-loader.component';
import { MatProgressBarModule } from '@angular/material';
import { NgxsModule } from '@ngxs/store';
import { TopLayoutLoaderState } from './state/top-layout-loader.state';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, NgxsModule.forFeature([TopLayoutLoaderState])],
  declarations: [TopLayoutLoaderComponent],
  exports: [TopLayoutLoaderComponent]
})
export class TopLayoutLoaderModule {}
