import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { MenuWrapperModel } from '../modules/app-layout/menu/model/menu-wrapper.model';
import { OrderState } from '../modules/order/order.state';
import { MenuDisplayComponent as SubscriptionMenuDisplayComponent } from '../modules/subscription/menu-display/menu-display/menu-display.component';
import { UserLogoutAction } from '../modules/user/state/action/user-logout.action';
import { userPictureUrl, UserState } from '../modules/user/state/user.state';
import { deepEqual } from '../modules/utils/function/deep-equal';
import { isPresentValue } from '../modules/utils/rxjs/operators/is-present-value';
import { isPresent } from '../modules/utils/type-guard/is-present';

export const bejovoSzamlaFeldolgozasaCustomAreaCheckShow$Replay = new BehaviorSubject<void>(undefined);
export const szolgaltatasElofizeteseShowContext$Replay = new BehaviorSubject<void>(undefined);

export const menuConfig: MenuWrapperModel = {
  top: [
    {
      title: 'MENU.ITEMS.IN_PROCESS_INVOICE.LABEL',
      icon: 'e961',
      authority: 'MENU_PROCESS_INVOICE',
      customAreaCheckShow$: (store: Store, http: HttpClient) =>
        bejovoSzamlaFeldolgozasaCustomAreaCheckShow$Replay.pipe(
          switchMap(() =>
            store.select<string[]>(UserState.authorities).pipe(
              distinctUntilChanged((oldValue, newValue) => deepEqual(oldValue, newValue)),
              filter(authorities => isPresent(authorities)),
              map(authorities => authorities.includes('MENU_SUBSCRIPTION')),
              switchMap(hasAuth =>
                hasAuth === false
                  ? of(false)
                  : store.select(OrderState.orders).pipe(
                      map(orders => {
                        if (Array.isArray(orders) && orders.length > 0 && orders[0].status === 'trial') {
                          return true;
                        }
                        return false;
                      })
                    )
              )
            )
          )
        ),
      customAreaCheckShow$Deps: [Store, HttpClient],
      customAreaCmp: () => SubscriptionMenuDisplayComponent,
      floatMenu: false,
      top: [
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.DASHBOARD',
          route: '/invoice/dashboard',
          icon: 'e9cb',
          authority: 'MENU_START_PAGE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_NAV',
          route: '/invoice/nav',
          icon: 'e9b9',
          authority: 'MENU_LIST_NAVINVOICES'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_RECORD',
          route: '/invoice/record',
          icon: 'e95c',
          authority: 'MENU_REGISTER_INVOICE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_CORRECTION',
          route: '/invoice/correction',
          icon: 'e964',
          authority: 'MENU_CORRECT_INVOICE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_APPROVE',
          route: '/invoice/approve',
          icon: 'e961',
          authority: 'MENU_APPROVE_INVOICE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_PAYMENT',
          route: '/invoice/payment',
          icon: 'e900',
          authority: 'MENU_PAY_INVOICE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_BOOKKEEPING',
          route: '/invoice/booking',
          icon: 'e95b',
          authority: 'MENU_BOOK_INVOICE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.INVOICE_SEARCH',
          route: '/invoice/search',
          icon: 'e957',
          authority: 'MENU_SEARCH_INVOICE'
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.SUPPLIER',
          route: '/invoice/supplier',
          icon: 'f2bc',
          authority: 'MENU_SUPPLIER'
        }
      ],
      bottom: [
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.SETTINGS',
          icon: 'f013',
          route: '/settings',
          authority: 'MENU_SETTINGS',
          showContext$: (store: Store) =>
            store.select(OrderState.firstOrderFirstItem).pipe(
              // consoleLogValue('menu settings'),
              isPresentValue(),
              map(orderItem => ['Small_Business_001', 'Flotta_001'].indexOf(orderItem.businessId) > -1)
            ),
          showContext$Deps: [Store]
        },
        {
          title: 'MENU.ITEMS.IN_PROCESS_INVOICE.ITEMS.SERVICE_SUBSCRIPTION',
          icon: 'f09d',
          route: '/subscription',
          authority: 'MENU_SUBSCRIBE',
          showContext$: (http: HttpClient) =>
            szolgaltatasElofizeteseShowContext$Replay.pipe(
              switchMap(() =>
                http
                  .get(`${environment.apiUrls.processUrl}/rest/task`, {
                    params: {
                      processDefinitionKey: 'completeCompanyData',
                      processVariables: 'starterProcess_eq_configureProduct'
                    }
                  })
                  .pipe(map(tasks => Array.isArray(tasks) && tasks.length > 0))
              ),
              shareReplay(1)
            ),
          showContext$Deps: [HttpClient]
        }
      ]
    },
    {
      title: 'MENU.ITEMS.NEW_APP.LABEL',
      icon: 'f067',
      authority: 'NEW_APPLICATION',
      floatMenu: false,
      top: [
        {
          title: 'MENU.ITEMS.NEW_APP.ITEMS.INVOICE_RECORD',
          route: '/invoice/record22',
          icon: 'e95c',
          authority: 'MENU_REGISTER_INVOICE'
        },
        {
          title: 'MENU.ITEMS.NEW_APP.ITEMS.INVOICE_CORRECTION',
          route: '/invoice/record33',
          icon: 'e964',
          authority: 'MENU_CORRECT_INVOICE'
        }
      ],
      bottom: [
        {
          title: 'MENU.ITEMS.NEW_APP.ITEMS.SERVICE_SUBSCRIPTION',
          icon: 'eaa3',
          route: '/invoice/record99',
          authority: 'MENU_SUBSCRIBE'
        }
      ]
    }
  ],
  bottom: [
    {
      title: 'MENU.ITEMS.MY_SUBSCRIPTION.LABEL',
      icon: 'f09d',
      authority: 'MENU_SUBSCRIPTION',
      route: '/my-subscription',
      floatMenu: false,
      top: [],
      bottom: [],
      hidden: [
        {
          title: 'MENU.ITEMS.MY_SUBSCRIPTION.ITEMS.SUBSCRIPTION_PROCESS',
          authority: 'MENU_SUBSCRIPTION',
          route: '/my-subscription/subscription'
        }
      ]
    },
    {
      title: 'MENU.ITEMS.USER_MANAGMENT.LABEL',
      icon: 'f0c0',
      route: '/users',
      authority: 'MENU_USERMANAGEMENT',
      floatMenu: false,
      top: [],
      bottom: []
    },
    {
      title: 'MENU.ITEMS.HELP.LABEL',
      icon: 'f059',
      authority: 'MENU_HELP',
      floatMenu: false,
      top: [],
      bottom: [],
      outsideLink: 'https://ven-it.atlassian.net/servicedesk/customer/portals',
      target: '_blank'
    },
    {
      title: 'MENU.ITEMS.MY_ACCOUNT.LABEL',
      icon: userPictureUrl,
      addIconUrlAccessToken: true,
      authority: 'MENU_MY_ACCOUNT',
      floatMenu: true,
      top: [
        {
          title: 'MENU.ITEMS.MY_ACCOUNT.ITEMS.MY_PROFILE',
          icon: 'e91e',
          route: '/user/me',
          authority: 'MENU_MY_ACCOUNT'
        },
        {
          title: 'MENU.ITEMS.MY_ACCOUNT.ITEMS.NAV_REGISTRATION',
          icon: 'eaa1',
          route: '/user/nav-registration',
          authority: 'MENU_NAVREGISTRATION'
        }
      ],
      bottom: [
        {
          title: 'MENU.ITEMS.MY_ACCOUNT.ITEMS.LOGOUT',
          icon: 'e9db',
          route: 'login',
          authority: 'MENU_MY_ACCOUNT',
          afterClicked: (store: Store) => store.dispatch(new UserLogoutAction()),
          afterClicked$Deps: [Store]
        }
      ]
    }
  ]
};
