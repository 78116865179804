import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatFormFieldModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ValidationMessagesComponent } from './validation-messages/validation-messages.component';

@NgModule({
  imports: [CommonModule, MatFormFieldModule, TranslateModule],
  declarations: [ValidationMessagesComponent],
  exports: [ValidationMessagesComponent]
})
export class ValidatingModule {}
