import * as tslib_1 from "tslib";
import { ComponentPortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { isFunction } from '../../../utils/type-guard/is-function';
import { isPresent } from '../../../utils/type-guard/is-present';
import { menuHandleAfterClick } from '../menu-handle-after-click.function';
import { ToggleSubMenuAction } from '../state/action/toggle-sub-menu.action';
import { MenuState } from '../state/menu.state';
var SubMenuComponent = /** @class */ (function () {
    function SubMenuComponent(injector, cdr, router, store) {
        this.injector = injector;
        this.cdr = cdr;
        this.router = router;
        this.store = store;
        this.selectMenu = new EventEmitter();
    }
    Object.defineProperty(SubMenuComponent.prototype, "chosenMenu", {
        get: function () {
            return this._chosenMenu;
        },
        set: function (value) {
            var _this = this;
            var _a;
            if (this._chosenMenu === undefined ||
                value === undefined ||
                this._chosenMenu.chosenMenu.authority !== value.chosenMenu.authority ||
                this._chosenMenu.chosenMenu.title !== value.chosenMenu.title) {
                if (this.customAreaCheckShowSubscription !== undefined) {
                    this.customAreaCheckShowSubscription.unsubscribe();
                }
                if (isPresent(value) && isFunction(value.chosenMenu.customAreaCmp)) {
                    if (isFunction(value.chosenMenu.customAreaCheckShow$)) {
                        var cbArgs_1 = [];
                        if (Array.isArray(value.chosenMenu.customAreaCheckShow$Deps)) {
                            value.chosenMenu.customAreaCheckShow$Deps.forEach(function (dep) { return cbArgs_1.push(_this.injector.get(dep)); });
                        }
                        this.customAreaCheckShowSubscription = (_a = value.chosenMenu).customAreaCheckShow$.apply(_a, tslib_1.__spread(cbArgs_1)).subscribe(function (show) { return (show ? _this.showCustomAreaComponent(value) : undefined); });
                    }
                    else {
                        this.showCustomAreaComponent(value);
                    }
                }
                else {
                    delete this.customAreaCmpPortal;
                }
            }
            this._chosenMenu = value;
            if (this._chosenMenu !== undefined &&
                this._chosenMenu.chosenMenu !== undefined &&
                this._chosenMenu.chosenMenu.bottom !== undefined &&
                this._chosenMenu.chosenMenu.bottom.length > 0) {
                this.visibleBottomDivider$ = combineLatest(this._chosenMenu.chosenMenu.bottom.map(function (item) { return (item._showContext$ ? item._showContext$ : new BehaviorSubject(true)); })).pipe(map(function (results) { return results.findIndex(function (result) { return result === true; }) > -1; }));
            }
            else {
                this.visibleBottomDivider$ = new BehaviorSubject(false);
            }
        },
        enumerable: true,
        configurable: true
    });
    SubMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.openedSubscription = this.store
            .select(MenuState.subMenuOpened)
            .pipe(untilDestroyed(this))
            .subscribe(function (subMenuOpened) { return (_this.opened = subMenuOpened); });
    };
    SubMenuComponent.prototype.onMenuToggle = function () {
        this.store.dispatch(new ToggleSubMenuAction());
    };
    SubMenuComponent.prototype.changeSubmenu = function (subMenu) {
        this.selectMenu.emit(subMenu);
        menuHandleAfterClick(subMenu, this.injector);
    };
    SubMenuComponent.prototype.ngOnDestroy = function () {
        if (this.customAreaCheckShowSubscription !== undefined) {
            this.customAreaCheckShowSubscription.unsubscribe();
        }
        if (this.openedSubscription !== undefined) {
            this.openedSubscription.unsubscribe();
        }
    };
    SubMenuComponent.prototype.trackBySubMenuModelFn = function (index, item) {
        return "" + item.route + item.title + item.authority;
    };
    // @HostBinding('class.sub-menu')
    // readonly subMenuClass = true;
    // constructor(private readonly router: Router, private readonly route: ActivatedRoute) {}
    //
    // navigate(path: string) {
    //   this.router.navigate([path]/*, { relativeTo: this.route }*/);
    // }
    // ngOnChanges(changes: SimpleChanges) {
    //   if (changes.chosenMenu) {
    //     debugger;
    //   }
    // }
    SubMenuComponent.prototype.onClickItem = function (item) {
        if (isPresent(this.chosenMenu) && isPresent(this.chosenMenu.chosenSubMenu) /*&& item.title !== this.chosenMenu.chosenSubMenu.title*/) {
            this.router.navigate([item.route]);
            this.changeSubmenu(item);
        }
    };
    SubMenuComponent.prototype.showCustomAreaComponent = function (value) {
        var _this = this;
        var _a;
        var cbArgs = [];
        if (Array.isArray(value.chosenMenu.customAreaCmpDeps)) {
            value.chosenMenu.customAreaCmpDeps.forEach(function (dep) { return cbArgs.push(_this.injector.get(dep)); });
        }
        var cmp = (_a = value.chosenMenu).customAreaCmp.apply(_a, tslib_1.__spread(cbArgs));
        if (cmp !== null) {
            this.customAreaCmpPortal = new ComponentPortal(cmp);
        }
        else {
            delete this.customAreaCmpPortal;
        }
        this.cdr.markForCheck();
    };
    return SubMenuComponent;
}());
export { SubMenuComponent };
