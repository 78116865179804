import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isObject } from '../../live-auto-complete3/function-type/function/live-autocomplete-option-model-icon.function';
import { SwUpdatesService } from '../../service-worker/sw-update.service';
import { ROUTE_USER_LOGIN } from '../../user/route.paths';
import { UserLogoutAction } from '../../user/state/action/user-logout.action';
import { UserState } from '../../user/state/user.state';
import { NoopFunctionType } from '../../utils/noop/noop-function.type';
import { isString } from '../../utils/type-guard/is-string';
import { ExpiredTokenError } from '../exception/expired-token.error';
import { HandledHttpError } from '../exception/handled-http.error';
import { NotFoundEndpointError } from '../exception/not-found-endpoint.error';
import { NotFoundInternetError } from '../exception/not-found-internet.error';
import { NotFoundServerError } from '../exception/not-found-server.error';
import { showTranslatedErrorDialog } from '../function/show-translated-error-dialog.function';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * ezzel tudjuk korlatozni hogy  a requestek ne induljanak el
   * @type {boolean}
   */
  private cancelPendingRequests = false;

  constructor(
    private translateService: TranslateService,
    private store: Store,
    private router: Router,
    private swUpdatesService: SwUpdatesService
  ) {}

  private _disableErrorCodes: number[] = [];

  get disableErrorCodes(): number[] {
    return this._disableErrorCodes;
  }

  addDisableErrorCode(code: number): void {
    this._disableErrorCodes.push(code);
  }

  removeDisableErrorCode(code: number): void {
    if (this._disableErrorCodes.indexOf(code) > -1) {
      this._disableErrorCodes.splice(this._disableErrorCodes.indexOf(code), 1);
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cancelPendingRequests) {
      return EMPTY;
    }

    const renewRun = this.store.selectSnapshot(UserState.renewRun);
    if (renewRun === false || req.url.startsWith('assets/i18n/') || req.url.endsWith('oauth/token')) {
      return this.getNextRequest(next, req);
    }
    req = req.clone({ headers: req.headers.set('ngsw-bypass', 'true') });
    return this.swUpdatesService.hasNewVersion$.pipe(
      filter(hasNewVersion => hasNewVersion === false),
      switchMap(() => this.getNextRequest(next, req))
    );
  }

  private getNextRequest(next: HttpHandler, req: HttpRequest<any>) {
    return next.handle(req).pipe(
      catchError<any, any>(error => {
        error = this.detectHandleErrors(error);
        if (this._disableErrorCodes.length > 0 && this._disableErrorCodes.indexOf(error.status) > -1) {
          return throwError(error);
        }

        error = this.handleHandledError(error);
        return throwError(error);
      })
    );
  }

  /**
   * kezeljuk az ismert hibakat a fejleckodok alapjan
   * @param error
   * @param _error
   */
  private handleHandledError(error) {
    let clickCallback: NoopFunctionType;
    let dialogTitleTranslateKey: string;
    let dialogMessageTranslateKey: string;
    let backdropClass: string;

    if (error instanceof ExpiredTokenError && this.router.url !== `/${ROUTE_USER_LOGIN}`) {
      // this.cancelPendingRequests = true;
      clickCallback = () => {
        this.store.dispatch(new UserLogoutAction(this.translateService.instant('HTTP_HANDLED_ERROR.EXPIRED_TOKEN.RELOAD_OVERLAY_TEXT')));
      };
      dialogTitleTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TITLE';
      dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TEXT';
      backdropClass = 'error-backdrop';
      error = new HandledHttpError(error);
    } else if (error instanceof NotFoundServerError) {
      dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_FOUND_SERVER_DIALOG.TEXT';
      backdropClass = 'error-backdrop';
      error = new HandledHttpError(error);
    } else if (error instanceof NotFoundInternetError) {
      dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_FOUND_INTERNET_DIALOG.TEXT';
      backdropClass = 'error-backdrop';
      error = new HandledHttpError(error);
    } else if (error instanceof NotFoundEndpointError) {
      dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_FOUND_ENDPOINT_DIALOG.TEXT';
      error = new HandledHttpError(error);
    }
    if (clickCallback !== undefined || dialogTitleTranslateKey !== undefined || dialogMessageTranslateKey !== undefined) {
      showTranslatedErrorDialog(clickCallback, dialogTitleTranslateKey, dialogMessageTranslateKey, undefined, backdropClass);
    }
    return error;
  }

  /**
   * detectaljuk az ismert hibakat http hibakod alapjan
   * @param error
   */
  private detectHandleErrors(error) {
    if (error.status === 0) {
      error = new NotFoundInternetError(error);
    } else if (error.status === 404) {
      error = new NotFoundEndpointError(error);
    } else if (error.status === 504) {
      error = new NotFoundServerError(error);
    } else if (error.status === 401) {
      error = new ExpiredTokenError(error);
    } else if (error.status === 500 && isObject(error.error) && isString(error.error.message) && error.error.message.startsWith('400 {')) {
      const messageJSON = JSON.parse(error.error.message.replace(/^400 /g, '').replace(/\r?\n|\r/g, ''));
      error = new HttpErrorResponse({ ...error, error: messageJSON });
    }
    return error;
  }
}
