import { MatDialog } from '@angular/material';
import { NoopFunctionType } from '../../utils/noop/noop-function.type';
import { openDialog } from '../../common/modal-question/function/open-dialog.function';
import { isFunction } from '../../utils/type-guard/is-function';
import { ErrorCenterService } from '../error-center.service';
import { ModalQuestionContentDialogModel } from '../../common/modal-question/model/modal-question-content-dialog.model';

/**
 * Error dialog
 *
 * @param dialog
 * @param dialogTitle
 * @param dialogMessage
 * @param dialogButton
 * @param clickCallback
 * @param backdropClass
 */
export function showErrorDialog(
  dialog: MatDialog,
  dialogTitle: string,
  dialogMessage: string,
  dialogButton: string,
  clickCallback?: NoopFunctionType,
  backdropClass?: string,
  isHTML = false
) {
  // Fix material dialog bootstrap -ban kiesik a zona-bol
  ErrorCenterService.INSTANCE.ngZone.run(() => {
    const dialogRef = openDialog(
      dialog,
      {
        title: dialogTitle,
        text: dialogMessage,
        okButtonText: dialogButton,
        isHTML
      } as ModalQuestionContentDialogModel,
      {
        closeOnNavigation: false,
        disableClose: true,
        width: '450px',
        backdropClass: backdropClass
      }
    );

    if (isFunction(clickCallback)) {
      dialogRef.beforeClose().subscribe(() => {
        clickCallback();
      });
    }
  });
}
