<div class="container">
  <div class="row p-3 p-lg-0">
    <div class="col-10  col-lg-10 col-xl-10">
      <div class="header w-100 d-flex flex-column flex-sm-row justify-content-md-between align-items-start align-items-sm-center">
        <img class="logo mb-4 mb-sm-0" src="/assets/images/taskingo-wordmark.svg" routerLink="/" />
        <div class="d-flex flex-column flex-sm-row" *ngIf="!(userLoggedIn$ | async)">
          <button mat-stroked-button color="primary" routerLink="/login" class="mr-sm-3 mb-4 mb-sm-0">Bejelentkezés</button>
          <button mat-raised-button color="primary" (click)="alert('not implemented')">Próbáld ki ingyen</button>
        </div>
      </div>

      <div class="error-page">
        <!--Képek-->
        <img class="error-code" src="/assets/images/404-text.svg" /> <img class="illustration" src="/assets/images/404-illustration.svg" />

        <!--Szövegek-->
        <div class="text-wrapper">
          <div class="not-found">{{ 'ERROR_PAGE_404.PAGE_NOT_FOUND' | translate }}</div>
          <div>{{ 'ERROR_PAGE_404.PAGE_NOT_EXISTS' | translate }}</div>
          <button color="primary" mat-button routerLink="/">
            <div class="back-to-main">
              <ng-container *ngIf="(userLoggedIn$ | async); else userNotLoggedInButtonTpl">
                {{ 'ERROR_PAGE_404.BACK_TO_DASHBOARD' | translate }}
              </ng-container>
              <ng-template #userNotLoggedInButtonTpl>{{ 'ERROR_PAGE_404.BACK_TO_MAIN_PAGE' | translate }}</ng-template>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--Lábléc-->
  <div class="footer plr-16">
    <div class="footer-links">
      <a
        class="footer-link float-xl-none"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.taskingo.com/adatkezelesi-szabalyzat/"
      >
        {{ 'FOOTER.PRIVACY' | translate }}
      </a>
      <a class="footer-link footer-middle-link" target="_blank" rel="noopener noreferrer" href="https://www.taskingo.com/altalanos-szerzodesi-feltetelek/">
        {{ 'FOOTER.TERMS_OF_SERVICE' | translate }}
      </a>
      <a class="footer-link" target="_blank" rel="noopener noreferrer" href="https://www.taskingo.com">
        {{ 'FOOTER.ABOUT_US' | translate }}
      </a>
    </div>
    <div class="copy-right">{{ 'FOOTER.COPY_RIGHT' | translate }}</div>
  </div>
</div>
