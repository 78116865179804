import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { isPresent } from '../../utils/type-guard/is-present';

@Injectable({
  providedIn: 'root'
})
export class RegisteredUserGuard implements CanActivateChild {
  constructor(private readonly store: Store, private readonly router: Router) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.selectOnce<boolean>(
      states =>
        !isPresent(states['process']) ||
        !isPresent(states['process'].task) ||
        (states['process'].task.formKey !== 'setProcessParamDeployRecieveInvoice' && states['process'].task.formKey !== 'inputCompanyForm')
    );
  }
}
