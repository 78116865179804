import { detect } from 'detect-browser';
import * as MobileDetect from 'mobile-detect';

let mobileDetect: MobileDetect;
export let isDesktop: boolean;
export let isTablet: boolean;
export let isMobile: boolean;
export let browserName: string;
export function setDeviceType(window: Window) {
  const browser = detect();
  mobileDetect = new MobileDetect(window.navigator.userAgent);
  const _isMobile = mobileDetect.mobile();
  const _isTablet = mobileDetect.tablet();
  isDesktop = !_isMobile && !_isTablet;
  isTablet = _isTablet !== null;
  // isTablet = isTablet;
  window.document.body.classList.add(isDesktop ? 'desktop' : isTablet ? 'tablet' : 'mobile');
  window.document.body.classList.add(`device-type-${_isMobile ? 'mobil' : _isTablet ? 'tablet' : 'desktop'}`);
  if (!isDesktop) {
    window.document.body.style.height = '100%';
    window.document.documentElement.style.height = '100%';
  }
  browserName = `${browser.os.toLowerCase().replace(/ /g, '')}-${browser.name.toLowerCase()}`;
  window.document.body.classList.add(browserName);

  isMobile = !isDesktop && !isTablet;
}
