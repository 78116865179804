import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, NEVER, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { openDialog } from '../common/modal-question/function/open-dialog.function';
import { WINDOW } from '../common/window.token';

/**
 * SwUpdatesService
 *
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 6 hours.
 * 3. Whenever an update is available, it activates the update.
 *
 * `updateActivated` {Observable<string>} - Emit the version hash whenever an update is activated.
 */
@Injectable()
export class SwUpdatesService implements OnDestroy {
  private onDestroy = new Subject<void>();
  updateActivated: Observable<string>;
  readonly hasNewVersion$ = new BehaviorSubject(false);
  disableNextAvaibleCheck = false;

  constructor(
    readonly swu: SwUpdate,
    private store: Store,
    private translateService: TranslateService,
    @Inject(WINDOW) private window: Window,
    private matDialog: MatDialog
  ) {
    console.warn('SW is enabled: ', swu.isEnabled);
    if (!swu.isEnabled) {
      this.updateActivated = NEVER.pipe(takeUntil(this.onDestroy));
      this.hasNewVersion$.subscribe(v => console.log('hasNewVersion$: ', v));
      this.hasNewVersion$.next(false);
      return;
    }
    this.checkUpdate();
  }

  private checkUpdate() {
    this.swu.available
      .pipe(
        tap(evt => this.log(`Update available: ${JSON.stringify(evt)}`)),
        takeUntil(this.onDestroy)
      )
      .subscribe(() => {
        if (this.disableNextAvaibleCheck === true) {
          this.disableNextAvaibleCheck = false;
          return;
        }
        this.swu.activateUpdate().then(() => {
          this.hasNewVersion$.next(true);
          openDialog(
            this.matDialog,
            {
              title: this.translateService.instant('APP_UPDATE.UPDATE_DIALOG.TITLE'),
              text: this.translateService.instant('APP_UPDATE.UPDATE_DIALOG.TEXT'),
              okButtonText: this.translateService.instant('APP_UPDATE.UPDATE_DIALOG.OK_BUTTON_TEXT')
            },
            {
              closeOnNavigation: false,
              disableClose: true,
              width: '450px'
            }
          )
            .afterClosed()
            .subscribe(() => this.window.location.reload());
        });
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
  }

  private log(message: string): void {
    const timestamp = new Date().toISOString();
    console.log(`[SwUpdates - ${timestamp}]: ${message}`);
  }
}
