import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
var NotFoundInternetError = /** @class */ (function (_super) {
    tslib_1.__extends(NotFoundInternetError, _super);
    function NotFoundInternetError(oldHttpError) {
        return _super.call(this, oldHttpError) || this;
    }
    return NotFoundInternetError;
}(HttpErrorResponse));
export { NotFoundInternetError };
