import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { isBoolean } from '../../../utils/type-guard/is-boolean';
import { ToggleMenuAction } from './action/toggle-menu.action';
import { ToggleSubMenuAction } from './action/toggle-sub-menu.action';
var defaultState = {
    menuOpened: false,
    subMenuOpened: true
};
var MenuState = /** @class */ (function () {
    function MenuState() {
    }
    MenuState.menuOpened = function (state) {
        // TODO tg storage login utan kozvetlen ha nincs state beolvassa de nem tarolja le
        // ha még nem töltött be a default state -> default értéket adjuk vissza
        if (!isBoolean(state['menuOpened'])) {
            return false;
        }
        return state.menuOpened !== undefined ? state.menuOpened : false;
    };
    MenuState.subMenuOpened = function (state) {
        // TODO tg storage login utan kozvetlen ha nincs state beolvassa de nem tarolja le
        // ha még nem töltött be a default state -> default értéket adjuk vissza
        if (!isBoolean(state['subMenuOpened'])) {
            return true;
        }
        return state.subMenuOpened !== undefined ? state.subMenuOpened : false;
    };
    MenuState.prototype.toggleMenu = function (_a) {
        var patchState = _a.patchState, getState = _a.getState;
        var state = getState();
        var opened = true;
        if (Object.keys(state).indexOf('menuOpened') > -1) {
            opened = !state.menuOpened;
        }
        return patchState({ menuOpened: opened });
    };
    MenuState.prototype.toggleSubMenu = function (_a) {
        var patchState = _a.patchState, getState = _a.getState;
        var state = getState();
        var opened = false;
        if (Object.keys(state).indexOf('subMenuOpened') > -1) {
            opened = !state.subMenuOpened;
        }
        return patchState({ subMenuOpened: opened });
    };
    MenuState.prototype.ngxsOnInit = function (ctx) {
        var state = ctx.getState();
        if (state !== undefined) {
            if (Object.keys(state).indexOf('menuOpened') === -1) {
                return ctx.setState(defaultState);
            }
        }
    };
    tslib_1.__decorate([
        Action(ToggleMenuAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MenuState.prototype, "toggleMenu", null);
    tslib_1.__decorate([
        Action(ToggleSubMenuAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MenuState.prototype, "toggleSubMenu", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MenuState, "menuOpened", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], MenuState, "subMenuOpened", null);
    MenuState = tslib_1.__decorate([
        State({
            name: 'menu',
            defaults: defaultState
        })
    ], MenuState);
    return MenuState;
}());
export { MenuState };
