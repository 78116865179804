// declare const require: any;
// import * as versionConfig from '../../version.json';
// tslint:disable-next-line
export const versionConfig = require('../../version.json');

export const versionFBTesthosting: {
  name: string;
  date: string;
  dayCounter: number;
  gitHash: string;
  config: {
    branchTo: string;
    versionPlaceholder: string;
  };
} = (versionConfig as { release: any[] }).release.find(release => release.name === 'fb');
