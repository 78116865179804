import * as tslib_1 from "tslib";
import { MatDialog } from '@angular/material';
import { Action, State } from '@ngxs/store';
import { timer } from 'rxjs';
import { isString } from '../../utils/type-guard/is-string';
import { AddAppOverlayAction } from './action/add-app-overlay.action';
import { RemoveAppLoadingAction } from './action/remove-app-loading.action';
var AppLayoutState = /** @class */ (function () {
    function AppLayoutState(document, matDialog) {
        var _this = this;
        this.document = document;
        this.matDialog = matDialog;
        this.matDialog.afterOpen.subscribe(function () { return _this.document.body.classList.add('overflow-hidden'); });
        this.matDialog.afterAllClosed.subscribe(function () {
            var appOverlay = _this.getAppOverlayElement();
            if (appOverlay !== null && appOverlay.style.display === 'none') {
                _this.document.body.classList.remove('overflow-hidden');
            }
        });
    }
    AppLayoutState.prototype.removeAppLoading = function () {
        var _this = this;
        var pageLoaderElement = this.document.getElementById('page-loader');
        if (pageLoaderElement !== null) {
            timer(2000).subscribe(function () {
                pageLoaderElement.classList.add('finished');
                timer(1500).subscribe(function () {
                    if (_this.document.getElementById('page-loader') !== null) {
                        _this.document.getElementById('page-loader').remove();
                        if (_this.matDialog.openDialogs.length === 0) {
                            _this.document.body.classList.remove('overflow-hidden');
                        }
                    }
                });
            });
        }
    };
    AppLayoutState.prototype.addAppOverlay = function (state, action) {
        var appOverlay = this.getAppOverlayElement();
        if (appOverlay !== null) {
            var pElems = appOverlay.getElementsByTagName('h2');
            if (pElems.length === 1) {
                if (isString(action.text) && action.text.length > 0) {
                    pElems[0].innerText = action.text;
                }
                appOverlay.style.display = 'flex';
                this.document.body.classList.add('overflow-hidden');
            }
        }
    };
    AppLayoutState.prototype.getAppOverlayElement = function () {
        return this.document.getElementById('app-overlay');
    };
    tslib_1.__decorate([
        Action(RemoveAppLoadingAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", []),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppLayoutState.prototype, "removeAppLoading", null);
    tslib_1.__decorate([
        Action(AddAppOverlayAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, AddAppOverlayAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], AppLayoutState.prototype, "addAppOverlay", null);
    AppLayoutState = tslib_1.__decorate([
        State({
            name: 'appLayout',
            defaults: undefined
        }),
        tslib_1.__metadata("design:paramtypes", [Document, MatDialog])
    ], AppLayoutState);
    return AppLayoutState;
}());
export { AppLayoutState };
