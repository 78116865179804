import * as tslib_1 from "tslib";
import { Select } from '@ngxs/store';
import { UserState } from '../user/state/user.state';
import { Observable } from 'rxjs';
var ErrorPage404Component = /** @class */ (function () {
    function ErrorPage404Component() {
    }
    ErrorPage404Component.prototype.alert = function (text) {
        alert(text);
    };
    tslib_1.__decorate([
        Select(UserState.loggedIn),
        tslib_1.__metadata("design:type", Observable)
    ], ErrorPage404Component.prototype, "userLoggedIn$", void 0);
    return ErrorPage404Component;
}());
export { ErrorPage404Component };
