import * as tslib_1 from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { Action, createSelector, Selector, State, UpdateState } from '@ngxs/store';
import { default as jwt_decode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { asapScheduler, throwError, timer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MessageAddAction } from '../../../app.state/action/message-add.action';
import { AddAppOverlayAction } from '../../app-layout/state/action/add-app-overlay.action';
import { showTranslatedErrorDialog } from '../../error-center/function/show-translated-error-dialog.function';
import { isEmptyString } from '../../utils/type-guard/is-empty-string';
import { isPresent } from '../../utils/type-guard/is-present';
import { UserService } from '../user.service';
import { LoadCompanyDataErrorAction } from './action/load-company-data-error.action';
import { LoadCompanyDataSuccessAction } from './action/load-company-data-success.action';
import { LoadCompanyDataAction } from './action/load-company-data.action';
import { RenewTokenErrorAction } from './action/renew-token-error.action';
import { RenewTokenSuccessAction } from './action/renew-token-success.action';
import { RenewTokenAction } from './action/renew-token.action';
import { UserLoginErrorAction } from './action/user-login-error.action';
import { UserLoginSuccessAction } from './action/user-login-success.action';
import { UserLoginAction } from './action/user-login.action';
import { UserLogoutAction } from './action/user-logout.action';
import { UserMeErrorAction } from './action/user-me-error.action';
import { UserMeSuccessAction } from './action/user-me-success.action';
import { UserMeAction } from './action/user-me.action';
export var userPictureUrl = environment.apiUrls.user + "/:userId/profilepicture";
export var notFoundTenantError = 'Not found tenant';
export var isLoggedInOnAnotherTabError = 'isLoggedInOnAnotherTabError';
var UserState = /** @class */ (function () {
    function UserState(userService, window, translateService, cookieService) {
        this.userService = userService;
        this.window = window;
        this.translateService = translateService;
        this.cookieService = cookieService;
        this.renewRun = false;
    }
    UserState_1 = UserState;
    UserState.tenantId = function (state) {
        return isPresent(state.me) ? state.me.tenants[0].id : isPresent(state.user) && state.user.tenants[0] ? state.user.tenants[0] : null;
    };
    UserState.loggedIn = function (state) {
        return isPresent(state.accessToken) && isPresent(state.refreshToken) && !UserState_1.timeoutToken;
    };
    // @Selector()
    // static token(state: UserStateModel) {
    //   return isPresent(state.token) ? state.token.access_token : null;
    // }
    UserState.user = function (state) {
        return isPresent(state.user) ? state.user : null;
    };
    UserState.authorities = function (state) {
        return isPresent(state.me) && isPresent(state.me.groupAuths)
            ? state.me.groupAuths.reduce(function (prev, current) { return prev.concat(current.authorities.map(function (auth) { return auth.id; })); }, [])
            : null;
    };
    UserState.hasAuthority = function (authority) {
        return createSelector([UserState_1], function (state) { return UserState_1.authorities(state).some(function (_authority) { return _authority === authority; }); });
    };
    UserState.accessToken = function (state) {
        return state.accessToken;
    };
    UserState.tenants = function (state) {
        return state.user && state.user.tenants ? state.user.tenants : [];
    };
    UserState.refreshToken = function (state) {
        return state.refreshToken;
    };
    UserState.userId = function (state) {
        return isPresent(state.user) ? state.user.user_name : null;
    };
    UserState.userEmail = function (state) {
        return isPresent(state.user) ? state.user.user_name : null;
    };
    UserState.renewRun = function (state) {
        return state.renewRun;
    };
    UserState.me = function (state) {
        return state.me;
    };
    UserState.companyData = function (state) {
        return state.companyData;
    };
    UserState.prototype.userLogin = function (_a, action) {
        var dispatch = _a.dispatch;
        // check storage found token
        if (this.cookieService.check('verify')) {
            if (!isEmptyString(this.cookieService.get('verify')) && !isEmptyString(this.cookieService.get('verify2'))) {
                return asapScheduler.schedule(function () { return dispatch(new UserLoginErrorAction(isLoggedInOnAnotherTabError)); });
            }
        }
        return this.userService.login(action.email, action.password).pipe(tap(function (response) {
            return asapScheduler.schedule(function () {
                return dispatch(response.tenants.length === 0
                    ? new UserLoginErrorAction(notFoundTenantError)
                    : [new UserLoginSuccessAction(response, action.updateState, action.rememberMe), new UserMeAction()]);
            });
        }), catchError(function (error) {
            dispatch([new UserLoginErrorAction(error)]);
            return throwError(error);
        }));
    };
    UserState.prototype.userLoginSuccess = function (_a, action) {
        var patchState = _a.patchState, getState = _a.getState, dispatch = _a.dispatch;
        if (action instanceof RenewTokenSuccessAction) {
            // console.group('replace token');
            // // console.log('old token: ', getState().token);
            // console.log('new token: ', action.response);
            // console.groupEnd();
            // console.group('replace user');
            // console.log('old user: ', getState().user);
            // console.log('new user: ', jwt_decode(action.response.access_token));
            // console.groupEnd();
        }
        else {
            patchState({ rememberMe: action.rememberMe });
        }
        var _patchState = {
            accessToken: action.response.access_token,
            refreshToken: action.response.refresh_token,
            user: jwt_decode(action.response.access_token)
        };
        if (action instanceof RenewTokenSuccessAction) {
            var state = getState();
            var rememberMe = void 0;
            if (this.cookieService.check('rememberMe')) {
                this.cookieService.set('rememberMe', '', 30, '/', this.window.location.hostname);
                rememberMe = true;
            }
            else {
                rememberMe = state.rememberMe;
            }
            this.cookieService.set('verify', _patchState.accessToken, rememberMe ? 30 : undefined, '/', this.window.location.hostname);
            this.cookieService.set('verify2', _patchState.refreshToken, rememberMe ? 30 : undefined, '/', this.window.location.hostname);
        }
        else {
            if (action.rememberMe) {
                this.cookieService.set('rememberMe', '', 30, '/', this.window.location.hostname);
            }
            this.cookieService.set('verify', _patchState.accessToken, action.rememberMe ? 30 : undefined, '/', this.window.location.hostname);
            this.cookieService.set('verify2', _patchState.refreshToken, action.rememberMe ? 30 : undefined, '/', this.window.location.hostname);
        }
        patchState(_patchState);
        this.startRefreshTokenTimer(getState(), dispatch);
        if (action.updateState) {
            asapScheduler.schedule(function () { return dispatch(new UpdateState()); });
        }
        this.renewRun = false;
        return patchState({ renewRun: false });
    };
    UserState.prototype.userLogout = function (_a, action) {
        var setState = _a.setState, dispatch = _a.dispatch;
        if (action.showOverlay) {
            dispatch(new AddAppOverlayAction(action.overlayText !== undefined && action.overlayText.length > 0 ? action.overlayText : 'Kilépés folyamatban, kérem várjon...'));
        }
        setState({});
        // this.cookieService.set('verify', '', undefined, '/', this.window.location.hostname);
        // this.cookieService.set('verify2', '', undefined, '/', this.window.location.hostname);
        this.cookieService.delete('verify', '/', this.window.location.hostname);
        this.cookieService.delete('verify2', '/', this.window.location.hostname);
        if (this.cookieService.check('rememberMe')) {
            this.cookieService.delete('rememberMe', '/', this.window.location.hostname);
        }
        if (action.redirect === true) {
            timer(1000).subscribe(function () {
                // TODO dispatch(new AddAppOverlayAction('Kilépés folyamatban, kérlek várj...'));
                // TODO dispatch(new IdleStopDetectAction());
                // if (this.window.location.hash !== '#/') {
                if (window.location.hash !== '#/') {
                    window.location.href = '/';
                }
                else {
                    window.location.reload(true);
                }
                // } else {
                //   this.window.location.reload(true);
                // }
            });
        }
    };
    UserState.prototype.userMe = function (ctx) {
        if (ctx.getState().renewRun || this.renewRun) {
            return timer(500).subscribe(function () { return asapScheduler.schedule(function () { return ctx.dispatch(new UserMeAction()); }); });
        }
        return this.userService.me().pipe(tap(function (response) { return asapScheduler.schedule(function () { return ctx.dispatch(new UserMeSuccessAction(response)); }); }), catchError(function (error) {
            asapScheduler.schedule(function () { return ctx.dispatch(new UserMeErrorAction(error)); });
            return throwError(error);
        }));
    };
    UserState.prototype.userMeSuccess = function (_a, action) {
        var patchState = _a.patchState, getState = _a.getState;
        return patchState({ me: action.response });
    };
    UserState.prototype.ngxsOnInit = function (_a) {
        var getState = _a.getState, dispatch = _a.dispatch, patchState = _a.patchState;
        var state = tslib_1.__assign({}, getState());
        if (this.cookieService.check('verify')) {
            state.accessToken = this.cookieService.get('verify');
            state.refreshToken = this.cookieService.get('verify2');
            patchState({ accessToken: state.accessToken, refreshToken: state.refreshToken, user: jwt_decode(state.accessToken) });
            this.startRefreshTokenTimer(state, dispatch);
        }
        return undefined;
    };
    UserState.prototype.showTokenExpireDialog = function (dispatch) {
        var _this = this;
        var dialogTitleTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TITLE';
        var dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TEXT';
        var backdropClass = 'error-backdrop';
        UserState_1.timeoutToken = true;
        showTranslatedErrorDialog(function () {
            dispatch(new UserLogoutAction(_this.translateService.instant('HTTP_HANDLED_ERROR.EXPIRED_TOKEN.RELOAD_OVERLAY_TEXT')));
        }, dialogTitleTranslateKey, dialogMessageTranslateKey, undefined, backdropClass);
    };
    UserState.prototype.startRefreshTokenTimer = function (state, dispatch) {
        var _this = this;
        var decodedAccessToken = jwt_decode(state.accessToken);
        if (this.renewTimer !== undefined && !this.renewTimer.closed) {
            this.renewTimer.unsubscribe();
        }
        // check expired
        if (new Date().getTime() / 1000 < decodedAccessToken.exp) {
            // startRenewTime: tiz masodpreccel a lejarat elotti idopontot beallitjuk
            var startRenewTime = (decodedAccessToken.exp - new Date().getTime() / 1000 - 5 * 60) * 1000;
            console.log('startRenewTime', startRenewTime / 1000, startRenewTime / 1000 / 60, new Date(new Date().getTime() + startRenewTime));
            this.renewTimer = timer(startRenewTime).subscribe(function () { return dispatch(new RenewTokenAction()); });
            UserState_1.timeoutToken = false;
            return true;
        }
        else {
            // expired
            var refreshToken = jwt_decode(state.refreshToken);
            var warningTime = refreshToken.exp - 60 * 30;
            var currentTime = new Date().getTime() / 1000;
            // check refresh token time
            if (currentTime < refreshToken.exp) {
                // renew token
                UserState_1.timeoutToken = false;
                dispatch(new RenewTokenAction());
                if (currentTime < warningTime) {
                    asapScheduler.schedule(function () {
                        return dispatch(new MessageAddAction('Nemsokár le fog járni a munkameneted', 'bejelentkezés', function () {
                            _this.cookieService.delete('verify');
                            _this.cookieService.delete('verify2');
                            _this.window.location.reload();
                        }));
                    });
                }
            }
            else {
                // expired refresh token
                UserState_1.timeoutToken = true;
                this.showTokenExpireDialog(dispatch);
            }
        }
    };
    UserState.prototype.renewToken = function (_a) {
        var _this = this;
        var patchState = _a.patchState, getState = _a.getState, dispatch = _a.dispatch;
        this.renewRun = true;
        patchState({ renewRun: true });
        return this.userService.refreshToken(getState().refreshToken).pipe(tap(function (response) { return asapScheduler.schedule(function () { return dispatch(new RenewTokenSuccessAction(response)); }); }), catchError(function (error) {
            patchState({ renewRun: false });
            _this.renewRun = false;
            asapScheduler.schedule(function () { return dispatch(new RenewTokenErrorAction(error)); });
            return throwError(error);
        }));
    };
    UserState.prototype.loadCompanyData = function (_a) {
        var dispatch = _a.dispatch, patchState = _a.patchState;
        return this.userService.getCompanyData().pipe(tap(function (companyData) { return patchState({ companyData: companyData }); }), tap(function (companyData) {
            asapScheduler.schedule(function () { return dispatch(new LoadCompanyDataSuccessAction(companyData)); });
        }), catchError(function (error) {
            asapScheduler.schedule(function () { return dispatch(new LoadCompanyDataErrorAction(error)); });
            return throwError(error);
        }));
    };
    var UserState_1;
    UserState.timeoutToken = false;
    tslib_1.__decorate([
        Action(UserLoginAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UserLoginAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "userLogin", null);
    tslib_1.__decorate([
        Action([UserLoginSuccessAction, RenewTokenSuccessAction]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UserLoginSuccessAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "userLoginSuccess", null);
    tslib_1.__decorate([
        Action(UserLogoutAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UserLogoutAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "userLogout", null);
    tslib_1.__decorate([
        Action(UserMeAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "userMe", null);
    tslib_1.__decorate([
        Action(UserMeSuccessAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, UserMeSuccessAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "userMeSuccess", null);
    tslib_1.__decorate([
        Action(RenewTokenAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "renewToken", null);
    tslib_1.__decorate([
        Action(LoadCompanyDataAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState.prototype, "loadCompanyData", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "tenantId", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "loggedIn", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", Object)
    ], UserState, "user", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "authorities", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "accessToken", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "tenants", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "refreshToken", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "userId", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "userEmail", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "renewRun", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "me", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], UserState, "companyData", null);
    UserState = UserState_1 = tslib_1.__decorate([
        State({
            name: 'user',
            defaults: {
                accessToken: null,
                refreshToken: null,
                user: null,
                me: null,
                renewRun: false,
                companyData: undefined,
                rememberMe: false,
                version: 1
            }
        }),
        tslib_1.__metadata("design:paramtypes", [UserService,
            Window,
            TranslateService,
            CookieService])
    ], UserState);
    return UserState;
}());
export { UserState };
