import * as tslib_1 from "tslib";
import { Observable, of, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as deepcopy from 'ts-deepcopy';
import { isFunctionReturnAny } from '../type-guard/is-function-return-any';
import { isPresent } from '../type-guard/is-present';
var MemoizeEntry = /** @class */ (function () {
    function MemoizeEntry() {
    }
    return MemoizeEntry;
}());
export function Memoize(expireTime) {
    return function (target, propertyKey, descriptor) {
        // ha metodus tipusu
        if (isPresent(descriptor.value) && isFunctionReturnAny(descriptor.value)) {
            // tslint:disable-next-line:no-console
            console.info('bind method', propertyKey);
            descriptor.value = memoize(descriptor.value, expireTime);
            return;
        }
        // ha getter tipusu
        if (isPresent(descriptor.get) !== undefined) {
            // tslint:disable-next-line:no-console
            console.info('bind getter', propertyKey);
            descriptor.get = memoize(descriptor.get, expireTime);
            return;
        }
        // ha nem megfelelo tipusu
        throw new Error('Only put a Memoize() decorator on a method or get accessor.');
    };
}
function memoize(originalMethod, expireTime) {
    var cache = new Map();
    var memoizeGarbageCollectorTimerSubscription;
    function startMemoizeGarbageCollector() {
        if (isPresent(memoizeGarbageCollectorTimerSubscription)) {
            if (!memoizeGarbageCollectorTimerSubscription.closed) {
                return;
            }
        }
        memoizeGarbageCollectorTimerSubscription = timer(5000, 30000).subscribe(function () {
            // tslint:disable-next-line:no-console
            console.info('start garbarage collector');
            var now = new Date().getTime();
            var deleteKeys = [];
            cache.forEach(function (cacheEntry, key) { return (cacheEntry.expireTime < now ? deleteKeys.push(key) : undefined); });
            deleteKeys.forEach(function (key) { return cache.delete(key); });
            // tslint:disable-next-line:max-line-length no-console
            console.info("finish garbarage collector, free entry number: " + deleteKeys.length + ", full cache size: " + cache.size);
            if (cache.size === 0) {
                stopMemoizeGarbageCollector();
            }
        });
    }
    function stopMemoizeGarbageCollector() {
        if (isPresent(memoizeGarbageCollectorTimerSubscription)) {
            if (!memoizeGarbageCollectorTimerSubscription.closed) {
                memoizeGarbageCollectorTimerSubscription.unsubscribe();
            }
        }
    }
    function generateUUIDFromArg(arg, index) {
        if (arg instanceof Object && arg.hasOwnProperty('uuid') && isPresent(arg.uuid)) {
            return arg.uuid;
        }
        else {
            try {
                return JSON.stringify(arg instanceof Set ? Array.from(arg.values()) : arg);
            }
            catch (e) {
                console.error("Could not generate unique identity from parameter => index: " + index + ", value: " + arg);
                return undefined;
            }
        }
    }
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // tslint:disable:no-invalid-this
        startMemoizeGarbageCollector();
        /**
         * elofeldolgozo hasznalja, ezzel meg lehet akadalyozni hogy ne cachebol dolgozzon, ha hiba van
         * a kulcsok osszeallitasanal
         */
        var enableCache = true;
        var now = new Date().getTime();
        // kulcs generalas
        var cacheKey = this.constructor.name + "#" + args
            .map(function (arg, index) {
            if (Array.isArray(arg)) {
                var cacheKeyArray = tslib_1.__spread(arg).map(function (arrayArg) { return generateUUIDFromArg(arrayArg, index); });
                if (cacheKeyArray.findIndex(function (_cacheKey) { return !isPresent(_cacheKey); }) > -1) {
                    return (enableCache = false);
                }
                return cacheKeyArray.join('!!');
            } /*else if (arg instanceof Set) {
              
            } */
            else {
                var _cacheKey = generateUUIDFromArg(arg, index);
                if (!isPresent(_cacheKey)) {
                    return (enableCache = false);
                }
                return _cacheKey;
            }
        })
            .join('!');
        if (!enableCache) {
            console.warn('Automatic disable cache, why not generated hashed keys...');
        }
        // tartolt ertek kereses (ha van de lejart akkor torlunk)
        if (enableCache && cache.has(cacheKey)) {
            var cachedValue = cache.get(cacheKey);
            if (cachedValue.expireTime >= now) {
                if (cachedValue.isStream) {
                    // tslint:disable-next-line:no-console
                    console.info('hit (stream) key: ', cacheKey);
                    return of(cachedValue.value);
                }
                // tslint:disable-next-line:no-console
                console.info('hit key: ', cacheKey);
                return cachedValue.value;
            }
            else {
                // tslint:disable-next-line:no-console
                console.info('delete key: ', cacheKey);
                cache.delete(cacheKey);
            }
        }
        var cacheExpireTime = now + expireTime;
        var value = originalMethod.apply(this, args);
        if (enableCache) {
            if (value instanceof Observable) {
                // ha stream
                return value.pipe(tap(function (streamValue) {
                    // tslint:disable-next-line:no-console
                    console.info('store stream value, key: ', cacheKey);
                    cache.set(cacheKey, {
                        expireTime: cacheExpireTime,
                        value: deepcopy.default(streamValue),
                        isStream: true
                    });
                }));
            }
            else if (value instanceof Object || Array.isArray(value)) {
                // complex object
                // tslint:disable-next-line:no-console
                console.info('store complex value, key: ', cacheKey);
                cache.set(cacheKey, { expireTime: cacheExpireTime, value: deepcopy.default(value), isStream: false });
            }
            else {
                // ha simple value
                // tslint:disable-next-line:no-console
                console.info('store simple value, key: ', cacheKey);
                cache.set(cacheKey, { expireTime: cacheExpireTime, value: deepcopy.default(value), isStream: false });
            }
        }
        return value;
    };
}
