import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export function matPaginatorIntlFactory(translateService: TranslateService): MatPaginatorIntl {
  const paginatorIntl = new MatPaginatorIntl();
  paginatorIntl.itemsPerPageLabel = '';
  paginatorIntl.firstPageLabel = translateService.instant('COMMON.PAGINATOR.FIRST_PAGE');
  paginatorIntl.lastPageLabel = translateService.instant('COMMON.PAGINATOR.LAST_PAGE');
  paginatorIntl.nextPageLabel = translateService.instant('COMMON.PAGINATOR.NEXT_PAGE');
  paginatorIntl.previousPageLabel = translateService.instant('COMMON.PAGINATOR.PREVIOUS_PAGE');
  paginatorIntl.getRangeLabel = getRangeLabel;
  return paginatorIntl;
}

function getRangeLabel(page: number, pageSize: number, length: number): string {
  if (length === 0 || pageSize === 0) {
    return `0 / ${length}`;
  }
  length = Math.max(length, 0);
  const startIndex = page * pageSize;
  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  return `${startIndex + 1} - ${endIndex} / ${length}`;
}
