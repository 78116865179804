import { registerLocaleData } from '@angular/common';
import { Injector } from '@angular/core';
import { DateAdapter } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as countries from 'i18n-iso-countries';
import * as moment_ from 'moment';
import { asapScheduler, Subscription } from 'rxjs';
import { LOCALES, LOCALES_VALUE_TYPE } from '../../locales';
import { isNil } from '../utils/type-guard/is-nil';
import { SelectLanguageAction } from './action/select-language.action';
import { SetLanguageOptionsSuccessAction } from './action/set-language-options-success.action';
import { SetLanguageOptionsAction } from './action/set-language-options.action';
import { I18nStateModel } from './model/i18n-state.model';

const moment = moment_;

const angularLocalesSettings = {
  en: () =>
    import('@angular/common/locales/en').then(locale =>
      import('@angular/common/locales/extra/en').then(extra => ({ locale: locale.default, extra: extra.default }))
    ),
  hu: () =>
    import('@angular/common/locales/hu').then(locale =>
      import('@angular/common/locales/extra/hu').then(extra => ({ locale: locale.default, extra: extra.default }))
    )
};

@State<I18nStateModel>({
  name: 'i18n',
  defaults: {
    selectedLang: null,
    version: 1
  }
})
export class I18nState {
  private translateServiceSubscription: Subscription;
  private registeredLocaleData: { [key: string]: boolean } = {};

  constructor(private translateService: TranslateService, private injector: Injector) {}

  @Selector()
  static selectedLang(state: I18nStateModel): LOCALES_VALUE_TYPE {
    return state.selectedLang;
  }

  @Action(SelectLanguageAction)
  selectLanguage({ patchState, dispatch }: StateContext<I18nStateModel>, { lang }: SelectLanguageAction) {
    if (isNil(lang) || LOCALES.indexOf(lang) === -1) {
      lang = 'HU';
    }
    patchState({ selectedLang: lang });
    asapScheduler.schedule(() => dispatch(new SetLanguageOptionsAction()));
  }

  @Action(SetLanguageOptionsAction)
  setLanguageOptions({ getState, dispatch }: StateContext<I18nStateModel>) {
    const selectedLang = getState().selectedLang.toLowerCase();
    this.translateService.use(selectedLang);
    if (this.translateServiceSubscription !== undefined && !this.translateServiceSubscription.closed) {
      this.translateServiceSubscription.unsubscribe();
    }

    if (isNil(this.registeredLocaleData[selectedLang])) {
      this.registeredLocaleData[selectedLang] = true;
      angularLocalesSettings[selectedLang]().then(localeSettings =>
        registerLocaleData(localeSettings.locale, selectedLang, localeSettings.extra)
      );
      countries.registerLocale(require(`i18n-iso-countries/langs/${selectedLang}.json`));
    }

    moment.locale(selectedLang);
    this.injector.get(DateAdapter).setLocale(selectedLang);
    dispatch(new SetLanguageOptionsSuccessAction());
  }
}
