import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { ROUTE_USER_LOGIN } from '../route.paths';
import { UserState } from '../state/user.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
import * as i3 from "../../common/window.token";
export var userGetUrl = { url: '' };
var AuthGuard = /** @class */ (function () {
    function AuthGuard(store, router, window) {
        this.store = store;
        this.router = router;
        this.window = window;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    AuthGuard.prototype.canLoad = function (route) {
        return this.check();
    };
    AuthGuard.prototype.check = function () {
        var _this = this;
        // debugger
        userGetUrl.url = this.window.location.hash.slice(1, this.window.location.hash.length);
        return this.store.selectOnce(UserState.loggedIn).pipe(map(function (isLoggedIn) {
            if (!isLoggedIn) {
                var userLoginRoute = "/" + ROUTE_USER_LOGIN;
                if (_this.router.url !== userLoginRoute) {
                    _this.router.navigate([userLoginRoute]);
                }
                return false;
            }
            return true;
        }));
    };
    AuthGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.check();
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.Store), i0.inject(i2.Router), i0.inject(i3.WINDOW)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
