<div class="menu-background-container h-100">
  <!-- Kell külön div a border-radius alatti rész színe miatt -->
  <div class="border-radius-wrapper">
    <!-- Bal oldali menü -->
    <div class="main-menu" [ngClass]="{ opened: opened }">
      <img class="logo" tgPreventClick (click)="onClickLogo()" src="/assets/images/logo-white.svg" />
      <div class="menu-containers">
        <!-- Felső menüpontok -->
        <div class="menu-items" *ngIf="(menuWrapper$ | async) as menuWrapper">
          <div class="top-menu-items">
            <div
              *ngFor="let menuItem of menuWrapper.top"
              class="menu-item-wrapper"
              [@fadeInEnter]
              [ngClass]="{ active: chosenMenu?.chosenMenu?.title === menuItem.title, closed: !opened }"
            >
              <!-- Szükséges belső wrapper, különben nem jelenik meg az aktívot jelző baloldali csík -->
              <div class="menu-item" (click)="onMenuChange(menuItem)" [matTooltip]="opened === false ? (menuItem.title|translate) : undefined">
                <div class="left-border"></div>
                <div class="tg-icon" [ngClass]="'icon-' + menuItem.icon"></div>
                <!--d-none nélkül lenne horizontális scroll-->
                <div class="menu-title" [@openClose]="opened ? 'open' : 'closed'">{{ menuItem.title |translate }}</div>
              </div>
            </div>
          </div>
          <!-- Alsó menüpontok -->
          <div class="bottom-menu-items">
            <div *ngFor="let menuItem of menuWrapper?.bottom">
              <div
                class="menu-item-wrapper"
                *ngIf="!menuItem.floatMenu; else floated"
                [ngClass]="{ active: chosenMenu?.chosenMenu?.title === menuItem.title, closed: !opened }"
              >
                <!-- Szükséges belső wrapper, különben nem jelenik meg az aktívot jelző baloldali csík -->
                <div class="menu-item" (click)="onMenuChange(menuItem)" [matTooltip]="opened === false ? (menuItem.title|translate) : undefined">
                  <div class="left-border"></div>
                  <div class="tg-icon" [ngClass]="'icon-' + menuItem.icon"></div>
                  <div class="menu-title" [@openClose]="opened ? 'open' : 'closed'">{{ menuItem.title|translate }}</div>
                </div>
              </div>
              <ng-template #floated>
                <!-- Felhasználó elem -->
                <div
                  class="tenant position-relative"
                  [ngClass]="{ active: chosenMenu?.chosenMenu?.title === null }"
                  [matMenuTriggerFor]="menu"
                  [matTooltip]="opened === false ? (menuItem.title|translate) : undefined"
                >
                  <mat-progress-spinner
                    *ngIf="userImageLoadMode"
                    color="accent"
                    mode="indeterminate"
                    class="position-absolute monogram-loader"
                  ></mat-progress-spinner>
                  <img
                    *ngIf="!userImageMonogramMode; else monogramTpl"
                    [src]="(menuItem.icon | addUserIdToIcon) + '&random=' + getImageRandom()"
                    class="user-profile-picture"
                    (error)="onErrorUserImage()"
                    (load)="onLoadUserImage()"
                  />
                  <ng-template #monogramTpl>
                    <div class="d-inline-block">
                      <div
                        class="tg-icon-wrapper d-flex align-items-center justify-content-center user-monogram-icon-wrapper"
                        *ngIf="(userMe$ | async) as userMe"
                      >
                        <div class="monogram">{{ userMe.lastname | monogram: userMe.firstname }}</div>
                      </div>
                    </div>
                  </ng-template>
                  <span class="text" [@openCloseInline]="opened ? 'open' : 'closed'">{{ menuItem.title|translate }}</span>
                </div>
                <mat-menu
                  #menu="matMenu"
                  [overlapTrigger]="false"
                  [class]="opened ? 'tg-menu-back-drop-class-opened' : 'tg-menu-back-drop-class-closed'"
                >
                  <ng-template matMenuContent>
                    <button
                      *ngFor="let floatMenu of menuItem.top"
                      (click)="onFloatMenuNavigate(floatMenu)"
                      mat-menu-item
                      class="d-flex flex-row"
                    >
                      <div class="tg-icon" [ngClass]="'icon-' + floatMenu.icon"></div>
                      <div class="float-title">{{ floatMenu.title|translate }}</div>
                    </button>

                    <button
                      *ngFor="let floatMenu of menuItem.bottom"
                      (click)="onFloatMenuNavigate(floatMenu)"
                      mat-menu-item
                      class="d-flex flex-row"
                    >
                      <div class="tg-icon" [ngClass]="'icon-' + floatMenu.icon"></div>
                      <div class="float-title">{{ floatMenu.title|translate }}</div>
                    </button>
                  </ng-template>
                </mat-menu>
              </ng-template>
            </div>
            <div class="text-center" *ngIf="opened">
              <div
                class="text-center d-inline-block"
                style="font-size:12px; color: rgba(255, 255, 255, 0.6);"
                [@openClose]="opened ? 'open' : 'closed'"
              >
                Ver: {{ version }}
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-items" [ngClass]="{ 'closed-width': !opened }">
          <!-- Ki-be nyitó-záró elem -->
          <div class="menu-toggle">
            <button mat-icon-button (click)="onMenuToggle()">
              <div class="tg-icon toggle-icon icon-e90e"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Jobb oldali menü -->
  <tg-sub-menu
    *ngIf="chosenMenu?.chosenMenu?.top.length > 0 || chosenMenu?.chosenMenu?.bottom.length > 0"
    [chosenMenu]="chosenMenu"
    (selectMenu)="onSelectSubMenu($event)"
  ></tg-sub-menu>
</div>
