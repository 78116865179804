import { InjectionToken, Injector } from '@angular/core';
import { RouterStateModel } from '@ngxs/router-plugin';
import { StorageEngine } from '@ngxs/storage-plugin';
import { Store } from '@ngxs/store';
import { default as jwt_decode } from 'jwt-decode';
import { timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TokenUserModel } from '../user/model/token-user.model';
import { UserModel } from '../user/model/user.model';
import { UserState } from '../user/state/user.state';
import { isPresent } from './type-guard/is-present';
import { isString } from './type-guard/is-string';

export const TGStorageRouterExcludePathsToken = new InjectionToken('TG_STORAGE_ROUTER_EXCLUDE_PATHS_TOKEN');

export class TGStorageEngine implements StorageEngine {
  private userId: string;
  private store: Store;
  private routerExcludedPaths: string[] = [];

  set user(user: TokenUserModel) {
    this.userId = user.user_name;
  }

  constructor(private injector: Injector) {
    timer(0).subscribe(() => {
      this.routerExcludedPaths = this.injector.get(TGStorageRouterExcludePathsToken) as string[];
      if (!isPresent(this.routerExcludedPaths)) {
        this.routerExcludedPaths = [];
      }
      this.getUserId();
    });
  }

  private getUserId() {
    this.store = this.injector.get(Store);
    const user = this.store.selectSnapshot(UserState.user);
    if (isPresent(user)) {
      this.userId = user.user_name;
    } else {
      this.userId = undefined;
    }
  }

  getItem(key: string): string {
    if (['user', 'i18n'].indexOf(key) === -1) {
      key = this.keyPrefix(key);
    }
    if (key !== undefined) {
      const value = localStorage.getItem(`${environment.appName}__${key}`);
      if (key === 'user' && isString(value)) {
        try {
          const user = jwt_decode(value) as UserModel;
          if (user.user_name !== undefined) {
            this.userId = user.user_name;
          }
        } catch (e) {}
      }
      return value;
    }
    // tslint:disable-next-line
    return null;
  }

  setItem(key: string, value: string | { notFoundAuthFutureValue: boolean }): void {
    let routerSave = false;
    if (['user', 'i18n'].indexOf(key) === -1) {
      if (key === 'router') {
        routerSave = true;
      }
      key = this.keyPrefix(key);
    }
    if (key !== undefined && value !== JSON.stringify({ notFoundAuthFutureValue: 1 })) {
      // TODO ATNEZNI ES KIVENNI A NOT FOUND AUTH FUTURE VALUE-t
      if (typeof value === 'object' && value.notFoundAuthFutureValue === true) {
        delete value.notFoundAuthFutureValue;
      } else if (isString(value)) {
        const json = JSON.parse(value);
        if (json.notFoundAuthFutureValue !== undefined && isPresent(localStorage.getItem(`${environment.appName}__${key}`))) {
          return;
        }
        delete json.notFoundAuthFutureValue;
        value = JSON.stringify(json);
      }
      if (isString(value)) {
        if (routerSave && this.routerExcludedPaths.length > 0) {
          const routerState: RouterStateModel = JSON.parse(value);
          if (
            isPresent(routerState.state) &&
            isPresent(routerState.state.url) &&
            this.routerExcludedPaths.indexOf(routerState.state.url.split('?')[0]) > -1
          ) {
            return;
          }
        }
        localStorage.setItem(`${environment.appName}__${key}`, value);
      }
    }
  }

  keyPrefix(key: string, tryGetUserId = true): string {
    if (this.userId === undefined) {
      if (tryGetUserId) {
        this.getUserId();
        return this.keyPrefix(key, false);
      }
      return undefined;
    }
    return `${this.userId}_${key}`;
  }

  get length(): number {
    return localStorage.length;
  }

  clear(): void {
    localStorage.clear();
  }

  key(index: number): string {
    return localStorage.key(index);
  }

  removeItem(key: string): void {
    if (key !== 'user') {
      key = this.keyPrefix(key);
    }
    if (key !== undefined) {
      localStorage.removeItem(`${environment.appName}__${key}`);
    }
  }
}
