import { MatAutocompleteDefaultOptions, MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { timer } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { isDesktop } from '../main-config';
import { appInitializerFactory } from './app-initializer.factory';
import { validationMessagesConfig } from './config/validation.config';
import { FixedAngularRouterTracking } from './fixed-angular-router-tracking';
import { HideTopLayoutLoaderAction } from './modules/common/top-layout-loader/state/action/hide-top-layout-loader.action';
import { ShowTopLayoutLoaderAction } from './modules/common/top-layout-loader/state/action/show-top-layout-loader.action';
import { SelectLanguageAction } from './modules/i18n/action/select-language.action';
import { I18nState } from './modules/i18n/i18n.state';
import { StartLoadingAction } from './modules/invoice/record/record/approver/state/actions/start-loading.action';
import { mockOnBoardingStartUrl, mockProcessInstanceUrl, processSubmitFormUrl, taskDataByProcessInstanceIdUrl } from './modules/process/process.service';
import { SwUpdatesService } from './modules/service-worker/sw-update.service';
import { TaxService } from './modules/tax/tax.service';
import { createTranslateLoader } from './modules/translate/translate';
import { UserLoginSuccessAction } from './modules/user/state/action/user-login-success.action';
import { UserState } from './modules/user/state/user.state';
import { oauthTokenUrl } from './modules/user/user.service';
import { matPaginatorIntlFactory } from './modules/common/mat-paginator.localization';
var ɵ0 = createTranslateLoader, ɵ1 = function (router) {
    return new FixedAngularRouterTracking(router);
}, ɵ2 = [
    { value: environment.maintenanceModeFbUrl },
    { value: taskDataByProcessInstanceIdUrl.replace(':processInstanceId', '.*'), regexp: true },
    { value: oauthTokenUrl },
    { value: mockOnBoardingStartUrl },
    { value: environment.apiUrls.processUrl + "/api/message/mailconfirm/process-instance/.*", regexp: true },
    { value: mockProcessInstanceUrl.replace(':processInstanceId', '.*'), regexp: true },
    // { value: taskDataByProcessInstanceIdUrl.replace(':processInstanceId', '.*'), regexp: true },
    { value: environment.apiUrls.zipCode + "/*", regexp: true },
    // TODO url const-ra atirni! vagy excludeNext-vel hasznalni! (2 url az elfelejtett jelszo miatt van)
    {
        value: environment.apiUrls.processUrl + "/api/message/forgotPasswordConfirmed/process-instance/.*",
        regexp: true
    },
    // ez az url sosem kaphat auth tokent!
    { value: processSubmitFormUrl.replace(':taskId', '.*'), regexp: true }
], ɵ3 = validationMessagesConfig, ɵ4 = { duration: 8000 }, ɵ5 = {
    autoActiveFirstOption: true
}, ɵ6 = ["/user/registration/my-data", "/user/registration/my-company-data"], ɵ7 = appInitializerFactory, ɵ8 = window, ɵ9 = { appearance: 'standard' }, ɵ10 = {
    appearance: 'standard'
}, ɵ11 = matPaginatorIntlFactory;
var AppModule = /** @class */ (function () {
    function AppModule(angulartics2GoogleAnalytics, translate, router, store, actions$, swUpdatesService, matIconRegistry, domSanitizer, window, taxService, translateService) {
        var _this = this;
        this.window = window;
        this.disableNextRouteRemove = false;
        if (angulartics2GoogleAnalytics !== undefined && environment.google.analytics.enable) {
            angulartics2GoogleAnalytics.startTracking();
        }
        this.preloadTaxes(store, taxService, actions$);
        actions$
            .pipe(ofActionDispatched(ShowTopLayoutLoaderAction))
            .subscribe(function (action) { return (_this.disableNextRouteRemove = action.disableNextRouteRemove); });
        console.log("%c Taskingo client version: " + environment.VERSION, 'background: #222; color: #bada55');
        this.setLanguageOptions(translate, store, translateService);
        this.detectFirstRoute(router, store);
        if (!isDesktop) {
            router.events.pipe(filter(function (e) { return e instanceof NavigationStart; })).subscribe(function () { return store.dispatch(new StartLoadingAction()); });
            router.events
                .pipe(filter(function (e) { return e instanceof NavigationEnd && _this.disableNextRouteRemove === false; }))
                .subscribe(function () { return timer(1000).subscribe(function () { return store.dispatch(new HideTopLayoutLoaderAction()); }); });
        }
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
    }
    AppModule.prototype.setLanguageOptions = function (translate, store, translateService) {
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('hu');
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        var selectedLang = store.selectSnapshot(I18nState.selectedLang);
        if (selectedLang === null) {
            // ha nincs nyelv akkor a bongeszoben beallitott nyelvet vesszuk figyelembe
            selectedLang = translateService
                .getBrowserCultureLang()
                .split('-')[0]
                .toUpperCase();
        }
        store.dispatch(new SelectLanguageAction(selectedLang));
    };
    AppModule.prototype.preloadTaxes = function (store, taxService, actions$) {
        var preloadStream = taxService.getAll();
        if (store.selectSnapshot(UserState.loggedIn)) {
            preloadStream.subscribe(function () { });
        }
        else {
            actions$
                .pipe(ofActionSuccessful(UserLoginSuccessAction), take(1), switchMap(function () { return preloadStream; }))
                .subscribe(function () { });
        }
    };
    AppModule.prototype.detectFirstRoute = function (router, store) {
        router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), delay(300), take(1))
            .subscribe(function () { return store.dispatch(new HideTopLayoutLoaderAction()); });
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
