import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
var HandledHttpError = /** @class */ (function (_super) {
    tslib_1.__extends(HandledHttpError, _super);
    function HandledHttpError(oldHttpError) {
        return _super.call(this, oldHttpError) || this;
    }
    return HandledHttpError;
}(HttpErrorResponse));
export { HandledHttpError };
