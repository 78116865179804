<tg-top-layout-loader></tg-top-layout-loader>
<h2 class="message" *ngFor="let message of (messages$ | async)">
  {{ message.message }}
  <a
    *ngIf="message.linkText"
    href="#"
    (click)="$event.stopPropagation(); $event.preventDefault(); message.linkClick ? message.linkClick() : undefined"
    >{{ message.linkText }}</a
  >
</h2>
<div class="container-height"><router-outlet></router-outlet></div>
