import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MonogramPipe } from './monogram.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [MonogramPipe],
  exports: [MonogramPipe]
})
export class MonogramPipeModule {}
