import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { UserState } from '../state/user.state';
import * as i0 from "@angular/core";
import * as i1 from "@ngxs/store";
import * as i2 from "@angular/router";
var NotAuthGuard = /** @class */ (function () {
    function NotAuthGuard(store, router) {
        this.store = store;
        this.router = router;
    }
    NotAuthGuard.prototype.canActivate = function (next, state) {
        return this.check();
    };
    NotAuthGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.check();
    };
    NotAuthGuard.prototype.check = function () {
        if (!this.store.selectSnapshot(UserState.loggedIn)) {
            return true;
        }
        this.router.navigate(['/']);
        return false;
    };
    NotAuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function NotAuthGuard_Factory() { return new NotAuthGuard(i0.inject(i1.Store), i0.inject(i2.Router)); }, token: NotAuthGuard, providedIn: "root" });
    return NotAuthGuard;
}());
export { NotAuthGuard };
