import * as tslib_1 from "tslib";
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { isDesktop, isTablet } from '../../../../../main-config';
import { TopLayoutLoaderState } from '../state/top-layout-loader.state';
var TopLayoutLoaderComponent = /** @class */ (function () {
    function TopLayoutLoaderComponent() {
        this.isDesktop = isDesktop;
        this.isTablet = isTablet;
    }
    tslib_1.__decorate([
        Select(TopLayoutLoaderState.show),
        tslib_1.__metadata("design:type", Observable)
    ], TopLayoutLoaderComponent.prototype, "show$", void 0);
    return TopLayoutLoaderComponent;
}());
export { TopLayoutLoaderComponent };
