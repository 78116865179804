import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Inject, Injector, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatAutocompleteDefaultOptions,
  MatButtonModule,
  MatFormFieldModule,
  MatIconRegistry,
  MatInputModule,
  MatSnackBarModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatCardModule } from '@angular/material/card';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { Actions, NgxsModule, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { Angulartics2Module, RouterlessTracking } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { RecaptchaModule } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { InputFileModule } from 'ngx-input-file';
import { NgxMaskModule } from 'ngx-mask';
import { timer } from 'rxjs';
import { delay, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { isDesktop } from '../main-config';
import { AppInitializerFactory, appInitializerFactory } from './app-initializer.factory';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { validationMessagesConfig } from './config/validation.config';
import { FixedAngularRouterTracking } from './fixed-angular-router-tracking';
import { LOCALES_VALUE_TYPE } from './locales';
import { AppLayoutModule } from './modules/app-layout/app-layout.module';
import { MenuState } from './modules/app-layout/menu/state/menu.state';
import { AppLayoutState } from './modules/app-layout/state/app-layout.state';
import { ModalQuestionModule } from './modules/common/modal-question/modal-question.module';
import { HideTopLayoutLoaderAction } from './modules/common/top-layout-loader/state/action/hide-top-layout-loader.action';
import { ShowTopLayoutLoaderAction } from './modules/common/top-layout-loader/state/action/show-top-layout-loader.action';
import { TopLayoutLoaderModule } from './modules/common/top-layout-loader/top-layout-loader.module';
import { WINDOW } from './modules/common/window.token';
import { ErrorCenterService } from './modules/error-center/error-center.service';
import { HttpErrorInterceptor } from './modules/error-center/interceptor/http-error.interceptor';
import { ErrorPage404Component } from './modules/error-page404/error-page404.component';
import { SelectLanguageAction } from './modules/i18n/action/select-language.action';
import { I18nState } from './modules/i18n/i18n.state';
import { StartLoadingAction } from './modules/invoice/record/record/approver/state/actions/start-loading.action';
import { OrderState } from './modules/order/order.state';
import {
  mockOnBoardingStartUrl,
  mockProcessInstanceUrl,
  processSubmitFormUrl,
  taskDataByProcessInstanceIdUrl
} from './modules/process/process.service';
import { ProcessState } from './modules/process/state/process.state';
import { SwUpdatesService } from './modules/service-worker/sw-update.service';
import { SwUpdatesModule } from './modules/service-worker/sw-updates.module';
import { TaxService } from './modules/tax/tax.service';
import { createTranslateLoader } from './modules/translate/translate';
import { EXCLUDE_TOKEN_REQUIRE } from './modules/user/interceptor/exlude-token-require.token';
import { TokenInterceptor } from './modules/user/interceptor/token.interceptor';
import { UserLoginSuccessAction } from './modules/user/state/action/user-login-success.action';
import { UserState } from './modules/user/state/user.state';
import { oauthTokenUrl } from './modules/user/user.service';
import { TGStorageEngine, TGStorageRouterExcludePathsToken } from './modules/utils/tg-storage.engine';
import { VALIDATION_MESSAGES_CONFIG } from './modules/validating/token/validation.provider.token';
import { ValidatingModule } from './modules/validating/validating.module';
import { matPaginatorIntlFactory } from './modules/common/mat-paginator.localization';
import { MatPaginatorIntl } from '@angular/material/paginator';

@NgModule({
  declarations: [AppComponent, ErrorPage404Component],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, ErrorCenterService]
      }
    }),

    NgxsModule.forRoot([I18nState, UserState, OrderState, AppLayoutState, ProcessState, MenuState], {
      developmentMode: !environment.production
    }),
    // NgxsReduxDevtoolsPluginModule.forRoot(),
    // NgxsRouterPluginModule.forRoot(),
    NgxsFormPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['inputUserDataForm', 'inviteUsersPage', 'i18n', 'menu']
    }),
    ModalQuestionModule,

    // MockStartModule,

    RecaptchaModule.forRoot(),
    // login, logout
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    ReactiveFormsModule,

    // DashboardModule,
    AppLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SwUpdatesModule,
    ValidatingModule,
    TopLayoutLoaderModule,
    MatMomentDateModule,
    InputFileModule.forRoot({
      fileAccept: '*',
      fileLimit: 1,
      sizeLimit: 5
    }),
    NgxMaskModule.forRoot(),
    Angulartics2Module.forRoot({
      developerMode: !environment.google.analytics.enable,
      pageTracking: {
        clearQueryParams: true
      }
    })
  ],
  providers: [
    {
      provide: RouterlessTracking,
      useFactory: (router: Router) => {
        return new FixedAngularRouterTracking(router);
      },
      deps: [Router]
    },
    ErrorCenterService,
    CookieService,
    {
      provide: EXCLUDE_TOKEN_REQUIRE,
      useValue: [
        { value: environment.maintenanceModeFbUrl },
        { value: taskDataByProcessInstanceIdUrl.replace(':processInstanceId', '.*'), regexp: true },
        { value: oauthTokenUrl },

        { value: mockOnBoardingStartUrl },
        { value: `${environment.apiUrls.processUrl}/api/message/mailconfirm/process-instance/.*`, regexp: true },
        { value: mockProcessInstanceUrl.replace(':processInstanceId', '.*'), regexp: true },
        // { value: taskDataByProcessInstanceIdUrl.replace(':processInstanceId', '.*'), regexp: true },
        { value: `${environment.apiUrls.zipCode}/*`, regexp: true },
        // TODO url const-ra atirni! vagy excludeNext-vel hasznalni! (2 url az elfelejtett jelszo miatt van)
        {
          value: `${environment.apiUrls.processUrl}/api/message/forgotPasswordConfirmed/process-instance/.*`,
          regexp: true
        },
        // ez az url sosem kaphat auth tokent!
        { value: processSubmitFormUrl.replace(':taskId', '.*'), regexp: true }
      ]
    },
    { provide: HTTP_INTERCEPTORS, useExisting: TokenInterceptor, multi: true },
    { provide: VALIDATION_MESSAGES_CONFIG, useValue: validationMessagesConfig },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 8000 } },
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: {
        autoActiveFirstOption: true
      } as MatAutocompleteDefaultOptions
    },
    {
      provide: STORAGE_ENGINE,
      useClass: TGStorageEngine,
      deps: [Injector]
    },
    {
      provide: TGStorageRouterExcludePathsToken,
      useValue: [`/user/registration/my-data`, `/user/registration/my-company-data`]
    },
    // { provide: LOCALE_ID, useValue: 'hu' },
    // {
    //   provide: MAT_DATE_LOCALE,
    //   useValue: 'hu' // window.navigator.language || (window.navigator as any).userLanguage
    // },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppInitializerFactory],
      multi: true
    },
    {
      provide: WINDOW,
      useValue: window
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      }
    },
    {
      provide: MatPaginatorIntl,
      useFactory: matPaginatorIntlFactory,
      deps: [TranslateService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  private disableNextRouteRemove = false;

  constructor(
    angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    translate: TranslateService,
    router: Router,
    store: Store,
    actions$: Actions,
    swUpdatesService: SwUpdatesService,
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer,
    @Inject(WINDOW) private window: Window,
    taxService: TaxService,
    translateService: TranslateService
  ) {
    if (angulartics2GoogleAnalytics !== undefined && environment.google.analytics.enable) {
      angulartics2GoogleAnalytics.startTracking();
    }
    this.preloadTaxes(store, taxService, actions$);

    actions$
      .pipe(ofActionDispatched(ShowTopLayoutLoaderAction))
      .subscribe(action => (this.disableNextRouteRemove = action.disableNextRouteRemove));

    console.log(`%c Taskingo client version: ${environment.VERSION}`, 'background: #222; color: #bada55');
    this.setLanguageOptions(translate, store, translateService);

    this.detectFirstRoute(router, store);

    if (!isDesktop) {
      router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(() => store.dispatch(new StartLoadingAction()));

      router.events
        .pipe(filter(e => e instanceof NavigationEnd && this.disableNextRouteRemove === false))
        .subscribe(() => timer(1000).subscribe(() => store.dispatch(new HideTopLayoutLoaderAction())));
    }

    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }

  private setLanguageOptions(translate: TranslateService, store: Store, translateService: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('hu');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let selectedLang: LOCALES_VALUE_TYPE | string = store.selectSnapshot(I18nState.selectedLang);
    if (selectedLang === null) {
      // ha nincs nyelv akkor a bongeszoben beallitott nyelvet vesszuk figyelembe
      selectedLang = translateService
        .getBrowserCultureLang()
        .split('-')[0]
        .toUpperCase();
    }
    store.dispatch(new SelectLanguageAction(selectedLang as any));
  }

  private preloadTaxes(store: Store, taxService: TaxService, actions$: Actions) {
    const preloadStream = taxService.getAll();
    if (store.selectSnapshot(UserState.loggedIn)) {
      preloadStream.subscribe(() => {});
    } else {
      actions$
        .pipe(
          ofActionSuccessful(UserLoginSuccessAction),
          take(1),
          switchMap(() => preloadStream)
        )
        .subscribe(() => {});
    }
  }

  private detectFirstRoute(router: Router, store: Store) {
    router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        delay(300),
        take(1)
      )
      .subscribe(() => store.dispatch(new HideTopLayoutLoaderAction()));
  }
}
