import * as tslib_1 from "tslib";
import { ModalQuestionContentComponent } from '../modal-question-content/modal-question-content.component';
export function openDialog(dialog, data, config) {
    if (dialog.openDialogs.length === 0) {
        var defaultConfig = {
            closeOnNavigation: false,
            disableClose: true,
            width: '250px',
            data: data
        };
        return dialog.open(ModalQuestionContentComponent, config ? tslib_1.__assign({}, defaultConfig, config) : defaultConfig);
    }
    else {
        return dialog.openDialogs[0];
    }
}
export function openOKDialog(redirectRoute, router, dialog, data, config, route) {
    var dialogRef = openDialog(dialog, data, config);
    var navigateParams = {};
    if (route !== undefined) {
        navigateParams.relativeTo = route;
    }
    dialogRef.afterClosed().subscribe(function () { return router.navigate([redirectRoute], navigateParams); });
    return dialogRef;
}
