import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
var NotFoundServerError = /** @class */ (function (_super) {
    tslib_1.__extends(NotFoundServerError, _super);
    function NotFoundServerError(oldHttpError) {
        return _super.call(this, oldHttpError) || this;
    }
    return NotFoundServerError;
}(HttpErrorResponse));
export { NotFoundServerError };
