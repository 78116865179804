import { HttpClient } from '@angular/common/http';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import * as moment from 'moment';
import { asapScheduler, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { OrderTrialModel } from '../subscription/model/order-trial.model';
import { LoadOrdersErrorAction } from './action/load-orders-error.action';
import { LoadOrdersSuccessAction } from './action/load-orders-success.action';
import { LoadOrdersAction } from './action/load-orders.action';
import { OrderStateModel } from './model/order-state.model';

@State<OrderStateModel>({ name: 'order' })
export class OrderState {
  constructor(private http: HttpClient) {}

  @Selector()
  static orders(state: OrderStateModel) {
    return state.orders;
  }

  @Selector()
  static firstOrder(state: OrderStateModel) {
    return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0] : null;
  }

  @Selector()
  static firstOrderStatus(state: OrderStateModel) {
    return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0].status : null;
  }

  @Selector()
  static firstOrderPeriodTo(state: OrderStateModel) {
    return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0].periodTo : null;
  }

  @Selector()
  static firstOrderFirstItem(state: OrderStateModel) {
    return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0].items[0] : null;
  }

  @Selector()
  static firstOrderFirstItemBusinessId(state: OrderStateModel) {
    const order = OrderState.firstOrderFirstItem(state);
    return order !== null ? order.businessId : null;
  }

  @Action(LoadOrdersAction)
  loadOrders({ dispatch }: StateContext<OrderStateModel>) {
    const currentDate = moment();
    return this.http
      .get<OrderTrialModel[]>(`${environment.apiUrls.order}/api/order`, {
        params: {
          orderStatusAny: 'trial,active',
          periodFromBefore: currentDate.add('1', 'day').format('YYYY-MM-DD'),
          periodToAfter: currentDate.subtract('1', 'day').format('YYYY-MM-DD')
        }
      })
      .pipe(
        tap(response => asapScheduler.schedule(() => dispatch(new LoadOrdersSuccessAction(response)))),
        catchError(error => {
          asapScheduler.schedule(() => dispatch(new LoadOrdersErrorAction(error)));
          return of(error);
        })
      );
  }

  @Action(LoadOrdersSuccessAction)
  loadOrdersSuccess({ patchState }: StateContext<OrderStateModel>, { orders }: LoadOrdersSuccessAction) {
    patchState({ orders });
    // if (Array.isArray(orders) && orders.length > 0) {
    //   this.http.get(`${environment.apiUrls.order}/api/order/${orders[0].orderId}`).subscribe(r => console.log(r));
    // }
  }
}
