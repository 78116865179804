import { Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, UpdateState } from '@ngxs/store';
import { default as jwt_decode } from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { asapScheduler, Observable, Subscription, throwError, timer } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MessageAddAction } from '../../../app.state/action/message-add.action';
import { AddAppOverlayAction } from '../../app-layout/state/action/add-app-overlay.action';
import { WINDOW } from '../../common/window.token';
import { showTranslatedErrorDialog } from '../../error-center/function/show-translated-error-dialog.function';
import { isEmptyString } from '../../utils/type-guard/is-empty-string';
import { isPresent } from '../../utils/type-guard/is-present';
import { TokenUserModel } from '../model/token-user.model';
import { UserService } from '../user.service';
import { LoadCompanyDataErrorAction } from './action/load-company-data-error.action';
import { LoadCompanyDataSuccessAction } from './action/load-company-data-success.action';
import { LoadCompanyDataAction } from './action/load-company-data.action';
import { RenewTokenErrorAction } from './action/renew-token-error.action';
import { RenewTokenSuccessAction } from './action/renew-token-success.action';
import { RenewTokenAction } from './action/renew-token.action';
import { UserLoginErrorAction } from './action/user-login-error.action';
import { UserLoginSuccessAction } from './action/user-login-success.action';
import { UserLoginAction } from './action/user-login.action';
import { UserLogoutAction } from './action/user-logout.action';
import { UserMeErrorAction } from './action/user-me-error.action';
import { UserMeSuccessAction } from './action/user-me-success.action';
import { UserMeAction } from './action/user-me.action';
import { UserStateModel } from './model/user-state.model';

export const userPictureUrl = `${environment.apiUrls.user}/:userId/profilepicture`;
export const notFoundTenantError = 'Not found tenant';
export const isLoggedInOnAnotherTabError = 'isLoggedInOnAnotherTabError';

@State<UserStateModel>({
  name: 'user',
  defaults: {
    accessToken: null,
    refreshToken: null,
    user: null,
    me: null,
    renewRun: false,
    companyData: undefined,
    rememberMe: false,
    version: 1
  }
})
export class UserState implements NgxsOnInit {
  constructor(
    private readonly userService: UserService,
    @Inject(WINDOW) private window: Window,
    private translateService: TranslateService,
    private cookieService: CookieService
  ) {}

  static timeoutToken = false;
  private renewTimer: Subscription;
  private renewRun = false;

  @Selector()
  static tenantId(state: UserStateModel) {
    return isPresent(state.me) ? state.me.tenants[0].id : isPresent(state.user) && state.user.tenants[0] ? state.user.tenants[0] : null;
  }

  @Selector()
  static loggedIn(state: UserStateModel) {
    return isPresent(state.accessToken) && isPresent(state.refreshToken) && !UserState.timeoutToken;
  }

  // @Selector()
  // static token(state: UserStateModel) {
  //   return isPresent(state.token) ? state.token.access_token : null;
  // }

  @Selector()
  static user(state: UserStateModel): TokenUserModel {
    return isPresent(state.user) ? state.user : null;
  }

  @Selector()
  static authorities(state: UserStateModel) {
    return isPresent(state.me) && isPresent(state.me.groupAuths)
      ? state.me.groupAuths.reduce((prev, current) => prev.concat(current.authorities.map(auth => auth.id)), [])
      : null;
  }

  static hasAuthority(authority: string) {
    return createSelector(
      [UserState],
      (state: UserStateModel) => UserState.authorities(state).some(_authority => _authority === authority)
    );
  }

  @Selector()
  static accessToken(state: UserStateModel) {
    return state.accessToken;
  }

  @Selector()
  static tenants(state: UserStateModel) {
    return state.user && state.user.tenants ? state.user.tenants : [];
  }

  @Selector()
  static refreshToken(state: UserStateModel) {
    return state.refreshToken;
  }

  @Selector()
  static userId(state: UserStateModel) {
    return isPresent(state.user) ? state.user.user_name : null;
  }

  @Selector()
  static userEmail(state: UserStateModel) {
    return isPresent(state.user) ? state.user.user_name : null;
  }

  @Selector()
  static renewRun(state: UserStateModel) {
    return state.renewRun;
  }

  @Selector()
  static me(state: UserStateModel) {
    return state.me;
  }

  @Selector()
  static companyData(state: UserStateModel) {
    return state.companyData;
  }

  @Action(UserLoginAction)
  userLogin({ dispatch }: StateContext<UserStateModel>, action: UserLoginAction) {
    // check storage found token
    if (this.cookieService.check('verify')) {
      if (!isEmptyString(this.cookieService.get('verify')) && !isEmptyString(this.cookieService.get('verify2'))) {
        return asapScheduler.schedule(() => dispatch(new UserLoginErrorAction(isLoggedInOnAnotherTabError)));
      }
    }

    return this.userService.login(action.email, action.password).pipe(
      tap(response =>
        asapScheduler.schedule(() =>
          dispatch(
            response.tenants.length === 0
              ? new UserLoginErrorAction(notFoundTenantError)
              : [new UserLoginSuccessAction(response, action.updateState, action.rememberMe), new UserMeAction()]
          )
        )
      ),
      catchError(error => {
        dispatch([new UserLoginErrorAction(error)]);
        return throwError(error);
      })
    );
  }

  @Action([UserLoginSuccessAction, RenewTokenSuccessAction])
  userLoginSuccess({ patchState, getState, dispatch }: StateContext<UserStateModel>, action: UserLoginSuccessAction) {
    if (action instanceof RenewTokenSuccessAction) {
      // console.group('replace token');
      // // console.log('old token: ', getState().token);
      // console.log('new token: ', action.response);
      // console.groupEnd();
      // console.group('replace user');
      // console.log('old user: ', getState().user);
      // console.log('new user: ', jwt_decode(action.response.access_token));
      // console.groupEnd();
    } else {
      patchState({ rememberMe: action.rememberMe });
    }

    const _patchState = {
      accessToken: action.response.access_token,
      refreshToken: action.response.refresh_token,
      user: jwt_decode(action.response.access_token)
    };

    if (action instanceof RenewTokenSuccessAction) {
      const state = getState();
      let rememberMe: boolean;
      if (this.cookieService.check('rememberMe')) {
        this.cookieService.set('rememberMe', '', 30, '/', this.window.location.hostname);
        rememberMe = true;
      } else {
        rememberMe = state.rememberMe;
      }
      this.cookieService.set('verify', _patchState.accessToken, rememberMe ? 30 : undefined, '/', this.window.location.hostname);
      this.cookieService.set('verify2', _patchState.refreshToken, rememberMe ? 30 : undefined, '/', this.window.location.hostname);
    } else {
      if (action.rememberMe) {
        this.cookieService.set('rememberMe', '', 30, '/', this.window.location.hostname);
      }
      this.cookieService.set('verify', _patchState.accessToken, action.rememberMe ? 30 : undefined, '/', this.window.location.hostname);
      this.cookieService.set('verify2', _patchState.refreshToken, action.rememberMe ? 30 : undefined, '/', this.window.location.hostname);
    }
    patchState(_patchState);
    this.startRefreshTokenTimer(getState(), dispatch);
    if (action.updateState) {
      asapScheduler.schedule(() => dispatch(new UpdateState()));
    }
    this.renewRun = false;
    return patchState({ renewRun: false });
  }

  @Action(UserLogoutAction)
  userLogout({ setState, dispatch }: StateContext<UserStateModel>, action: UserLogoutAction) {
    if (action.showOverlay) {
      dispatch(
        new AddAppOverlayAction(
          action.overlayText !== undefined && action.overlayText.length > 0 ? action.overlayText : 'Kilépés folyamatban, kérem várjon...'
        )
      );
    }
    setState({} as UserStateModel);
    // this.cookieService.set('verify', '', undefined, '/', this.window.location.hostname);
    // this.cookieService.set('verify2', '', undefined, '/', this.window.location.hostname);
    this.cookieService.delete('verify', '/', this.window.location.hostname);
    this.cookieService.delete('verify2', '/', this.window.location.hostname);
    if (this.cookieService.check('rememberMe')) {
      this.cookieService.delete('rememberMe', '/', this.window.location.hostname);
    }
    if (action.redirect === true) {
      timer(1000).subscribe(() => {
        // TODO dispatch(new AddAppOverlayAction('Kilépés folyamatban, kérlek várj...'));
        // TODO dispatch(new IdleStopDetectAction());
        // if (this.window.location.hash !== '#/') {
        if (window.location.hash !== '#/') {
          window.location.href = '/';
        } else {
          window.location.reload(true);
        }
        // } else {
        //   this.window.location.reload(true);
        // }
      });
    }
  }

  @Action(UserMeAction)
  userMe(ctx: StateContext<UserStateModel>) {
    if (ctx.getState().renewRun || this.renewRun) {
      return timer(500).subscribe(() => asapScheduler.schedule(() => ctx.dispatch(new UserMeAction())));
    }
    return this.userService.me().pipe(
      tap(response => asapScheduler.schedule(() => ctx.dispatch(new UserMeSuccessAction(response)))),
      catchError(error => {
        asapScheduler.schedule(() => ctx.dispatch(new UserMeErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(UserMeSuccessAction)
  userMeSuccess({ patchState, getState }: StateContext<UserStateModel>, action: UserMeSuccessAction) {
    return patchState({ me: action.response });
  }

  ngxsOnInit({ getState, dispatch, patchState }: StateContext<UserStateModel>): void | any {
    const state = { ...getState() };
    if (this.cookieService.check('verify')) {
      state.accessToken = this.cookieService.get('verify');
      state.refreshToken = this.cookieService.get('verify2');
      patchState({ accessToken: state.accessToken, refreshToken: state.refreshToken, user: jwt_decode(state.accessToken) });
      this.startRefreshTokenTimer(state, dispatch);
    }
    return undefined;
  }

  showTokenExpireDialog(dispatch: any): void {
    const dialogTitleTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TITLE';
    const dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TEXT';
    const backdropClass = 'error-backdrop';
    UserState.timeoutToken = true;
    showTranslatedErrorDialog(
      () => {
        dispatch(new UserLogoutAction(this.translateService.instant('HTTP_HANDLED_ERROR.EXPIRED_TOKEN.RELOAD_OVERLAY_TEXT')));
      },
      dialogTitleTranslateKey,
      dialogMessageTranslateKey,
      undefined,
      backdropClass
    );
  }

  private startRefreshTokenTimer(state, dispatch: (actions: any | any[]) => Observable<void>) {
    const decodedAccessToken = jwt_decode(state.accessToken);
    if (this.renewTimer !== undefined && !this.renewTimer.closed) {
      this.renewTimer.unsubscribe();
    }
    // check expired
    if (new Date().getTime() / 1000 < decodedAccessToken.exp) {
      // startRenewTime: tiz masodpreccel a lejarat elotti idopontot beallitjuk
      const startRenewTime = (decodedAccessToken.exp - new Date().getTime() / 1000 - 5 * 60) * 1000;
      console.log('startRenewTime', startRenewTime / 1000, startRenewTime / 1000 / 60, new Date(new Date().getTime() + startRenewTime));
      this.renewTimer = timer(startRenewTime).subscribe(() => dispatch(new RenewTokenAction()));
      UserState.timeoutToken = false;
      return true;
    } else {
      // expired
      const refreshToken = jwt_decode(state.refreshToken);
      const warningTime = refreshToken.exp - 60 * 30;
      const currentTime = new Date().getTime() / 1000;
      // check refresh token time
      if (currentTime < refreshToken.exp) {
        // renew token
        UserState.timeoutToken = false;
        dispatch(new RenewTokenAction());
        if (currentTime < warningTime) {
          asapScheduler.schedule(() =>
            dispatch(
              new MessageAddAction('Nemsokár le fog járni a munkameneted', 'bejelentkezés', () => {
                this.cookieService.delete('verify');
                this.cookieService.delete('verify2');
                this.window.location.reload();
              })
            )
          );
        }
      } else {
        // expired refresh token
        UserState.timeoutToken = true;
        this.showTokenExpireDialog(dispatch);
      }
    }
  }

  @Action(RenewTokenAction)
  renewToken({ patchState, getState, dispatch }: StateContext<UserStateModel>) {
    this.renewRun = true;
    patchState({ renewRun: true });
    return this.userService.refreshToken(getState().refreshToken).pipe(
      tap(response => asapScheduler.schedule(() => dispatch(new RenewTokenSuccessAction(response)))),
      catchError(error => {
        patchState({ renewRun: false });
        this.renewRun = false;
        asapScheduler.schedule(() => dispatch(new RenewTokenErrorAction(error)));
        return throwError(error);
      })
    );
  }

  @Action(LoadCompanyDataAction)
  loadCompanyData({ dispatch, patchState }: StateContext<UserStateModel>) {
    return this.userService.getCompanyData().pipe(
      tap(companyData => patchState({ companyData })),
      tap(companyData => {
        asapScheduler.schedule(() => dispatch(new LoadCompanyDataSuccessAction(companyData)));
      }),
      catchError(error => {
        asapScheduler.schedule(() => dispatch(new LoadCompanyDataErrorAction(error)));
        return throwError(error);
      })
    );
  }
}
