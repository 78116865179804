import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Action, Selector, State } from '@ngxs/store';
import * as moment from 'moment';
import { asapScheduler, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LoadOrdersErrorAction } from './action/load-orders-error.action';
import { LoadOrdersSuccessAction } from './action/load-orders-success.action';
import { LoadOrdersAction } from './action/load-orders.action';
var OrderState = /** @class */ (function () {
    function OrderState(http) {
        this.http = http;
    }
    OrderState_1 = OrderState;
    OrderState.orders = function (state) {
        return state.orders;
    };
    OrderState.firstOrder = function (state) {
        return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0] : null;
    };
    OrderState.firstOrderStatus = function (state) {
        return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0].status : null;
    };
    OrderState.firstOrderPeriodTo = function (state) {
        return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0].periodTo : null;
    };
    OrderState.firstOrderFirstItem = function (state) {
        return Array.isArray(state.orders) && state.orders.length > 0 ? state.orders[0].items[0] : null;
    };
    OrderState.firstOrderFirstItemBusinessId = function (state) {
        var order = OrderState_1.firstOrderFirstItem(state);
        return order !== null ? order.businessId : null;
    };
    OrderState.prototype.loadOrders = function (_a) {
        var dispatch = _a.dispatch;
        var currentDate = moment();
        return this.http
            .get(environment.apiUrls.order + "/api/order", {
            params: {
                orderStatusAny: 'trial,active',
                periodFromBefore: currentDate.add('1', 'day').format('YYYY-MM-DD'),
                periodToAfter: currentDate.subtract('1', 'day').format('YYYY-MM-DD')
            }
        })
            .pipe(tap(function (response) { return asapScheduler.schedule(function () { return dispatch(new LoadOrdersSuccessAction(response)); }); }), catchError(function (error) {
            asapScheduler.schedule(function () { return dispatch(new LoadOrdersErrorAction(error)); });
            return of(error);
        }));
    };
    OrderState.prototype.loadOrdersSuccess = function (_a, _b) {
        var patchState = _a.patchState;
        var orders = _b.orders;
        patchState({ orders: orders });
        // if (Array.isArray(orders) && orders.length > 0) {
        //   this.http.get(`${environment.apiUrls.order}/api/order/${orders[0].orderId}`).subscribe(r => console.log(r));
        // }
    };
    var OrderState_1;
    tslib_1.__decorate([
        Action(LoadOrdersAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState.prototype, "loadOrders", null);
    tslib_1.__decorate([
        Action(LoadOrdersSuccessAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, LoadOrdersSuccessAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState.prototype, "loadOrdersSuccess", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState, "orders", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState, "firstOrder", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState, "firstOrderStatus", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState, "firstOrderPeriodTo", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState, "firstOrderFirstItem", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], OrderState, "firstOrderFirstItemBusinessId", null);
    OrderState = OrderState_1 = tslib_1.__decorate([
        State({ name: 'order' }),
        tslib_1.__metadata("design:paramtypes", [HttpClient])
    ], OrderState);
    return OrderState;
}());
export { OrderState };
