import { versionFBTesthosting as version } from '../app/version-reader';

export const environment = {
  appName: 'TaskingoFB',
  production: true,
  hmr: false,
  apiUrls: {
    processUrl: 'https://apps.ven-it.com/taskingo/process',
    oauth: 'https://apps.ven-it.com/taskingo/user/oauth',
    zipCode: 'https://apps.ven-it.com/taskingo/company/api/zip/v2',
    userApi: 'https://apps.ven-it.com/taskingo/user/api',
    user: 'https://apps.ven-it.com/taskingo/user/api/user',
    userMe: 'https://apps.ven-it.com/taskingo/user/api/user/me',
    company: 'https://apps.ven-it.com/taskingo/company',
    payment: 'https://apps.ven-it.com/taskingo/payment',
    order: 'https://apps.ven-it.com/taskingo/order',
    file: 'https://apps.ven-it.com/taskingo/file',
    invoice: 'https://apps.ven-it.com/taskingo/invoice'
  },
  clientSecret: 'ZnJvbnRlbmQ6ZnJvbnRlbmQxMjM=',
  captchaCode: '6Lc8T7sUAAAAANN_7iRrG-ZP8XnBIz-93KxWGLPv',
  useCaptcha: false,
  unauthProcessToken:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJhbGwiXSwiZXhwIjoxNTM3MjI3MzQ3LCJqdGkiOiIzMjJkMzg1OS1jYTYwLTQ3ZjktYjlhNC02NWY2ZDkxZDA4ZmMiLCJjbGllbnRfaWQiOiJmcm9udGVuZCJ9.VLkoiQ9XERDiSHTqx_tgroqgTTGO1qlSSctc8_LcVNbuERxjm2Ub5svO5N8_j0onU2uenwHUvq95O_-eqV50eXLtAhYXkRfn5syyK4TxWR3RQevIOpsi7dyVJbL3A54eXskPhMhnHCQLZ44UMWoqmPdeeaSWu4k0kj50L2Suxpkh6BsHSpV_Q6c1iIZ2RAbbabUL80vk8ONHhTslqOv3CiNntnf1uAsG5Qql_JyJnqPeqSO3gBo_Aw22u2mku0x69UxjabjlnTaVdDMNBcWxUQPf-4Y5k2cgkH7GPY2ee9XNEc33f9H3P0aHekf1AIY6tIv7osT1g-dfgYGfzTWa0w',
  VERSION: `${version.date}-${version.config.versionPlaceholder}-${version.dayCounter}-${version.gitHash}`,
  simplePay: {
    acceptanceOfDataManagementLink: 'https://www.taskingo.com/adatkezelesi-szabalyzat/'
  },
  maintenanceModeFbUrl: 'https://taskingo-test-b41b3.firebaseio.com/maintenance-mode.json',
  google: {
    analytics: {
      enable: false,
      code: 'UA-149949179-1'
    }
  }
};
