import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { ShowTopLayoutLoaderAction } from './action/show-top-layout-loader.action';
import { HideTopLayoutLoaderAction } from './action/hide-top-layout-loader.action';
var TopLayoutLoaderState = /** @class */ (function () {
    function TopLayoutLoaderState() {
    }
    TopLayoutLoaderState.show = function (state) {
        return state.visible;
    };
    TopLayoutLoaderState.prototype.showTopLayoutLoader = function (_a) {
        var patchState = _a.patchState;
        return patchState({ visible: true });
    };
    TopLayoutLoaderState.prototype.hideTopLayoutLoader = function (_a) {
        var patchState = _a.patchState;
        return patchState({ visible: false });
    };
    tslib_1.__decorate([
        Action(ShowTopLayoutLoaderAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TopLayoutLoaderState.prototype, "showTopLayoutLoader", null);
    tslib_1.__decorate([
        Action(HideTopLayoutLoaderAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TopLayoutLoaderState.prototype, "hideTopLayoutLoader", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], TopLayoutLoaderState, "show", null);
    TopLayoutLoaderState = tslib_1.__decorate([
        State({
            name: 'topLayoutLoader',
            defaults: {
                visible: false
            }
        })
    ], TopLayoutLoaderState);
    return TopLayoutLoaderState;
}());
export { TopLayoutLoaderState };
