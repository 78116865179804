import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export var processCompleteUrl = environment.apiUrls.processUrl + "/rest/task/:taskId/complete";
// ezen url-nel sosincsen auth token!
export var processSubmitFormUrl = environment.apiUrls.processUrl + "/rest/task/:taskId/submit-form";
export var taskDataByProcessInstanceIdUrl = environment.apiUrls.processUrl + "/rest/task?processInstanceId=:processInstanceId";
export var taskDataByTenantIdUrl = environment.apiUrls.processUrl + "/rest/task?tenantIdIn=:tenantId";
export var taskDataByProcessDefinitionKey = environment.apiUrls.processUrl + "/rest/task?processDefinitionKey=:processDefinitionKey";
export var refreshProcessInstanceData = environment.apiUrls.processUrl + "/rest/process-instance/:processInstanceId";
export var processMessagesUrl = environment.apiUrls.processUrl + "/rest/message";
export var mockOnBoardingStartUrl = environment.apiUrls.processUrl + "/rest/process-definition/key/onboardingContactPerson/start";
// export const mockOnBoardingConfirmUrl = `${environment.apiUrls.processUrl}/api/message/mailconfirm/process-instance/:processInstanceId`;
export var mockProcessInstanceUrl = environment.apiUrls.processUrl + "/rest/process-instance?superProcessInstance=:processInstanceId";
var ProcessService = /** @class */ (function () {
    function ProcessService(http /*, private readonly tokenInterceptor: TokenInterceptor*/) {
        this.http = http;
    }
    // refreshUserTask(assignee: string): Observable<TaskModel> {
    //   return this.http
    //     .get<TaskModel>(refreshUserTaskUrl.replace(':assignee', assignee))
    //     .pipe(map(tasks => (Array.isArray(tasks) && tasks.length > 0 ? tasks[0] : null)));
    // }
    ProcessService.prototype.refreshProcessInstance = function (processInstanceId) {
        return this.http.get(refreshProcessInstanceData.replace(':processInstanceId', processInstanceId));
    };
    ProcessService.prototype.taskData = function (tenantId) {
        return this.http
            .get(taskDataByTenantIdUrl.replace(':tenantId', tenantId))
            .pipe(map(function (tasks) { return (Array.isArray(tasks) && tasks.length > 0 ? tasks[0] : undefined); }));
    };
    ProcessService.prototype.taskDataByProcessInstanceId = function (processInstanceId) {
        return this.http
            .get(taskDataByProcessInstanceIdUrl.replace(':processInstanceId', processInstanceId), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .pipe(map(function (tasks) { return tasks[0]; }));
    };
    ProcessService.prototype.taskDataByProcessDefinitionKey = function (processDefinitionKey) {
        return this.http
            .get(taskDataByProcessDefinitionKey.replace(':processDefinitionKey', processDefinitionKey))
            .pipe(map(function (tasks) { return tasks[0]; }));
    };
    ProcessService.prototype.completeInputUserData = function (taskId, form) {
        // this.tokenInterceptor.excludeNext = [{ value: processSubmitFormUrl.replace(':taskId', '.*'), regexp: true }];
        return this.http.post("" + processSubmitFormUrl.replace(':taskId', taskId), {
            variables: {
                userInData: {
                    value: "{\"lastname\":\"" + form.lastName + "\",\"firstname\":\"" + form.firstName + "\"}",
                    type: 'json'
                },
                password: {
                    value: form.password,
                    type: 'String',
                    valueInfo: {
                        transient: true
                    }
                },
                company: {
                    value: "{ \"companyname\": \"" + form.companyname + "\", \"taxnumber\":\"" + form.taxnumber + "\",\"zip\":\"" + form.zip + "\",\"housenumber\":\"" + form.housenumber + "\",\"city\":\"" + form.city + "\",\"street\":\"" + form.street + "\"}",
                    type: 'json'
                }
            },
            withVariablesInReturn: 'true'
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };
    // completeCompanyData(taskId: string, form: InputCompanyDataFormModel) {
    //   return this.http.post(
    //     `${processCompleteUrl.replace(':taskId', taskId)}`,
    //     {
    //       variables: {
    //         company: {
    //           value: `${JSON.stringify({ ...form, country: 'HU' })}`,
    //           // value: `{"companyname":"${form.companyname}","taxnumber":"${form.taxnumber}","zip":"${
    //           //   form.zip
    //           // }","country":"HU","housenumber":"${form.housenumber}","city":"${form.city}","street":"${form.street}"}`,
    //           type: 'json'
    //         }
    //       }
    //     },
    //     {
    //       headers: {
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   );
    //   // .pipe(tap(() => this.http.get(`${environment.apiUrls.processUrl}/rest/task/${taskId}/form-variables`).subscribe(w => console.warn(w))));
    // }
    ProcessService.prototype.completeMaxAmount = function (taskId, maxAmount) {
        return this.http.post("" + processCompleteUrl.replace(':taskId', taskId), {
            variables: {
                maxAmount: { value: maxAmount, type: 'string' }
            }
        });
    };
    ProcessService.prototype.message = function (json) {
        return this.http.post(processMessagesUrl, json);
    };
    ProcessService.prototype.registration = function (randomNumber, randomEmail, type) {
        return this.http.post(mockOnBoardingStartUrl, {
            variables: {
                email: { value: randomEmail, type: 'String' },
                product: { value: type, type: 'String' }
            },
            withVariablesInReturn: 'false',
            businessKey: randomNumber
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // .pipe(
        // delay(1000),
        // switchMap(processInstance => {
        //   return this.http.get(mockProcessInstanceUrl.replace(':processInstanceId', processInstance.id),{
        //     headers: {
        //       'Authorization':
        //         'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6WyJhbGwiXSwiZXhwIjoxNTM2NjMyMjY3LCJqdGkiOiJhMjY5NTQwOC0xN2JlLTRhZWEtYTM2Ny1hMTJhODU4NjZiNjgiLCJjbGllbnRfaWQiOiJmcm9udGVuZCJ9.Vhn7dBlDpoWlE-e-lwkBBK1-ZVcsJLBuDb1bHwublA_ib62HG6ZWmuvfCt2KsFF2NfILgP7ZZcuQXDTWTSM6wApmh2757_fGsXfyjWW9XlLU3sWgTCGeYNT42HQbMv5yLx_xi4H656pQvZUJcMqElFS-AgbqFKQFMVx8zuL6NSvjAS9ZlfP4PDGAx9nOm8aRkY-GgZ4r39TqCw979vNR2fcKfxK8nEAN0C0aX2Rt4JaVysogZSSPa4TWdphSj-ursNvluZEgYT9Sf1uBEdXXtcHUpX_nyM8nNYWznzDR50wwn63I0PD1haZRWCAfPvX_Q0pMbIhVA_OHjXrhVd34og'
        //     }
        //   }).pipe(
        //     map(
        //       (
        //         v: {
        //           links: string[];
        //           id: string;
        //           definitionId: string;
        //           businessKey: string;
        //           caseInstanceId: string;
        //           ended: boolean;
        //           suspended: boolean;
        //           tenantId: string;
        //         }[]
        //       ) => {
        //         processInstance.id = v[0].id;
        //         return processInstance;
        //       }
        //     ),
        //     delay(1000)
        //   );
        // })
        // );
    };
    ProcessService.ngInjectableDef = i0.defineInjectable({ factory: function ProcessService_Factory() { return new ProcessService(i0.inject(i1.HttpClient)); }, token: ProcessService, providedIn: "root" });
    return ProcessService;
}());
export { ProcessService };
