import * as tslib_1 from "tslib";
import { isFunction } from '../../utils/type-guard/is-function';
export function menuHandleAfterClick(subMenu, injector) {
    if (isFunction(subMenu.afterClicked)) {
        var cbArgs_1 = [];
        if (Array.isArray(subMenu.afterClicked$Deps)) {
            subMenu.afterClicked$Deps.forEach(function (dep) { return cbArgs_1.push(injector.get(dep)); });
        }
        subMenu.afterClicked.apply(subMenu, tslib_1.__spread(cbArgs_1));
    }
}
