import { Observable, of, Subscription, timer } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as deepcopy from 'ts-deepcopy';
import { isFunctionReturnAny } from '../type-guard/is-function-return-any';
import { isPresent } from '../type-guard/is-present';

class MemoizeEntry {
  expireTime: number;
  value: any;
  isStream: boolean;
}

export function Memoize(expireTime: number): (target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => any {
  return (target: object, propertyKey: string, descriptor: TypedPropertyDescriptor<any>) => {
    // ha metodus tipusu
    if (isPresent(descriptor.value) && isFunctionReturnAny(descriptor.value)) {
      // tslint:disable-next-line:no-console
      console.info('bind method', propertyKey);
      descriptor.value = memoize(descriptor.value, expireTime);
      return;
    }
    // ha getter tipusu
    if (isPresent(descriptor.get) !== undefined) {
      // tslint:disable-next-line:no-console
      console.info('bind getter', propertyKey);
      descriptor.get = memoize(descriptor.get, expireTime);
      return;
    }
    // ha nem megfelelo tipusu
    throw new Error('Only put a Memoize() decorator on a method or get accessor.');
  };
}

function memoize(originalMethod: () => any, expireTime: number): (...args: any[]) => Observable<any> | any {
  const cache = new Map<string, MemoizeEntry>();

  let memoizeGarbageCollectorTimerSubscription: Subscription;

  function startMemoizeGarbageCollector(): void {
    if (isPresent(memoizeGarbageCollectorTimerSubscription)) {
      if (!memoizeGarbageCollectorTimerSubscription.closed) {
        return;
      }
    }
    memoizeGarbageCollectorTimerSubscription = timer(5000, 30000).subscribe(() => {
      // tslint:disable-next-line:no-console
      console.info('start garbarage collector');
      const now = new Date().getTime();
      const deleteKeys: string[] = [];
      cache.forEach((cacheEntry, key) => (cacheEntry.expireTime < now ? deleteKeys.push(key) : undefined));

      deleteKeys.forEach(key => cache.delete(key));
      // tslint:disable-next-line:max-line-length no-console
      console.info(`finish garbarage collector, free entry number: ${deleteKeys.length}, full cache size: ${cache.size}`);
      if (cache.size === 0) {
        stopMemoizeGarbageCollector();
      }
    });
  }

  function stopMemoizeGarbageCollector(): void {
    if (isPresent(memoizeGarbageCollectorTimerSubscription)) {
      if (!memoizeGarbageCollectorTimerSubscription.closed) {
        memoizeGarbageCollectorTimerSubscription.unsubscribe();
      }
    }
  }

  function generateUUIDFromArg(arg, index): string {
    if (arg instanceof Object && arg.hasOwnProperty('uuid') && isPresent(arg.uuid)) {
      return arg.uuid;
    } else {
      try {
        return JSON.stringify(arg instanceof Set ? Array.from(arg.values()) : arg);
      } catch (e) {
        console.error(`Could not generate unique identity from parameter => index: ${index}, value: ${arg}`);
        return undefined;
      }
    }
  }

  return function(...args: any[]): Observable<any> | any {
    // tslint:disable:no-invalid-this
    startMemoizeGarbageCollector();
    /**
     * elofeldolgozo hasznalja, ezzel meg lehet akadalyozni hogy ne cachebol dolgozzon, ha hiba van
     * a kulcsok osszeallitasanal
     */
    let enableCache = true;
    const now = new Date().getTime();
    // kulcs generalas
    const cacheKey = `${this.constructor.name}#${args
      .map((arg, index) => {
        if (Array.isArray(arg)) {
          const cacheKeyArray = [...arg].map(arrayArg => generateUUIDFromArg(arrayArg, index));
          if (cacheKeyArray.findIndex(_cacheKey => !isPresent(_cacheKey)) > -1) {
            return (enableCache = false);
          }
          return cacheKeyArray.join('!!');
        } /*else if (arg instanceof Set) {
          
        } */ else {
          const _cacheKey = generateUUIDFromArg(arg, index);
          if (!isPresent(_cacheKey)) {
            return (enableCache = false);
          }
          return _cacheKey;
        }
      })
      .join('!')}`;

    if (!enableCache) {
      console.warn('Automatic disable cache, why not generated hashed keys...');
    }
    // tartolt ertek kereses (ha van de lejart akkor torlunk)
    if (enableCache && cache.has(cacheKey)) {
      const cachedValue = cache.get(cacheKey);
      if (cachedValue.expireTime >= now) {
        if (cachedValue.isStream) {
          // tslint:disable-next-line:no-console
          console.info('hit (stream) key: ', cacheKey);
          return of(cachedValue.value);
        }
        // tslint:disable-next-line:no-console
        console.info('hit key: ', cacheKey);
        return cachedValue.value;
      } else {
        // tslint:disable-next-line:no-console
        console.info('delete key: ', cacheKey);
        cache.delete(cacheKey);
      }
    }

    const cacheExpireTime = now + expireTime;
    const value: any = originalMethod.apply(this, args);
    if (enableCache) {
      if (value instanceof Observable) {
        // ha stream
        return value.pipe(
          tap(streamValue => {
            // tslint:disable-next-line:no-console
            console.info('store stream value, key: ', cacheKey);
            cache.set(cacheKey, {
              expireTime: cacheExpireTime,
              value: deepcopy.default(streamValue),
              isStream: true
            });
          })
        );
      } else if (value instanceof Object || Array.isArray(value)) {
        // complex object
        // tslint:disable-next-line:no-console
        console.info('store complex value, key: ', cacheKey);
        cache.set(cacheKey, { expireTime: cacheExpireTime, value: deepcopy.default(value), isStream: false });
      } else {
        // ha simple value
        // tslint:disable-next-line:no-console
        console.info('store simple value, key: ', cacheKey);
        cache.set(cacheKey, { expireTime: cacheExpireTime, value: deepcopy.default(value), isStream: false });
      }
    }
    return value;
  };
}
