import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest, race } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { getRandomInt } from './modules/app-layout/menu/menu.component';
import { RemoveAppLoadingAction } from './modules/app-layout/state/action/remove-app-loading.action';
import { openDialog } from './modules/common/modal-question/function/open-dialog.function';
import { ShowTopLayoutLoaderAction } from './modules/common/top-layout-loader/state/action/show-top-layout-loader.action';
import { WINDOW } from './modules/common/window.token';
import { ProcessLoadProcessInstanceErrorAction } from './modules/process/state/action/process-load-process-instance-error.action';
import { ProcessLoadProcessInstanceSuccessAction } from './modules/process/state/action/process-load-process-instance-success.action';
import { ProcessLoadProcessInstanceAction } from './modules/process/state/action/process-load-process-instance.action';
import { ProcessRefreshUserTaskErrorAction } from './modules/process/state/action/process-refresh-user-task-error.action';
import { ProcessRefreshUserTaskSuccessAction } from './modules/process/state/action/process-refresh-user-task-success.action';
import { ProcessRefreshUserTaskAction } from './modules/process/state/action/process-refresh-user-task.action';
import { UserLogoutAction } from './modules/user/state/action/user-logout.action';
import { UserMeErrorAction } from './modules/user/state/action/user-me-error.action';
import { UserMeSuccessAction } from './modules/user/state/action/user-me-success.action';
import { UserMeAction } from './modules/user/state/action/user-me.action';
import { UserState } from './modules/user/state/user.state';
import { isPresent } from './modules/utils/type-guard/is-present';
import * as i0 from "@angular/core";
export function appInitializerFactory(_appInitializerFactory) {
    return function () { return _appInitializerFactory.init(); };
}
/**
 * TODO RENAME!!!
 * @param actionsResponse
 * @param router
 * @param removeAppLoader
 * @param resolve
 */
export function taskState(formKey, router) {
    switch (formKey) {
        // case 'inputCompanyForm':
        //   router.navigate(['/user-manager/my-company-data']).then(() => {
        //     removeAppLoader();
        //     resolve();
        //   });
        //   break;
        case 'setProcessParamDeployRecieveInvoice':
            return router.navigate(['/user/registration-manager/set-amount']);
        case 'inviteUsers':
            return router.navigate(['/user/registration-manager/invite']);
        default:
            return null;
    }
}
var AppInitializerFactory = /** @class */ (function () {
    function AppInitializerFactory(injector) {
        this.injector = injector;
    }
    AppInitializerFactory.prototype.init = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var store = _this.injector.get(Store);
            var router = _this.injector.get(Router);
            var actions = _this.injector.get(Actions);
            var httpClient = _this.injector.get(HttpClient);
            var isLoggedIn = store.selectSnapshot(UserState.loggedIn);
            var tenants = store.selectSnapshot(UserState.tenants);
            var matDialog = _this.injector.get(MatDialog);
            var translateService = _this.injector.get(TranslateService);
            var window = _this.injector.get(WINDOW);
            if (environment.maintenanceModeFbUrl !== null) {
                httpClient.get(environment.maintenanceModeFbUrl).subscribe(function (enable) {
                    if (enable === true) {
                        window.location.assign("/maintenance-mode.html?rand=" + getRandomInt(0, 9999999999));
                        return;
                    }
                    _this.bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService);
                }, function () { return _this.bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService); });
            }
            else {
                _this.bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService);
            }
        });
    };
    AppInitializerFactory.prototype.bootstrap = function (store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService) {
        var removeAppLoader = function () {
            store.dispatch(new RemoveAppLoadingAction());
        };
        store.dispatch(new ShowTopLayoutLoaderAction());
        var getErrorsSubscription = actions
            .pipe(ofActionDispatched(ProcessRefreshUserTaskErrorAction), take(1))
            .subscribe(function () {
            removeAppLoader();
            resolve();
        });
        if (isLoggedIn && tenants.length === 0) {
            removeAppLoader();
            openDialog(matDialog, {
                title: 'Jogosultsági hiba',
                text: 'A felhasználó nem tartozik előfizetőhöz, bejelentkezés nem lehetséges.',
                okButtonText: 'OK'
            }, {
                closeOnNavigation: false,
                disableClose: true
            })
                .beforeClosed()
                .subscribe(function () {
                store.dispatch(new UserLogoutAction());
                getErrorsSubscription.unsubscribe();
            });
            return;
        }
        if (!isLoggedIn) {
            removeAppLoader();
            getErrorsSubscription.unsubscribe();
            resolve();
        }
        else {
            var bodyClassList = this.injector.get(DOCUMENT).body.classList;
            bodyClassList.remove('not-auth');
            bodyClassList.add('auth');
            var successBootstrapProcessSubscription_1 = combineLatest([
                actions.pipe(ofActionSuccessful(UserMeSuccessAction), take(1)),
                actions.pipe(ofActionSuccessful(ProcessRefreshUserTaskSuccessAction), take(1))
            ]).subscribe(function (actionsResponse) {
                getErrorsSubscription.unsubscribe();
                var finishAction = function () {
                    var _taskState = taskState(actionsResponse[1]['task'] && actionsResponse[1]['task']['formKey'] ? actionsResponse[1]['task']['formKey'] : '', router);
                    if (_taskState instanceof Promise) {
                        return _taskState.then(function () {
                            successBootstrapProcessSubscription_1.unsubscribe();
                            removeAppLoader();
                            return resolve();
                        });
                    }
                    else {
                        successBootstrapProcessSubscription_1.unsubscribe();
                        removeAppLoader();
                        return resolve();
                    }
                };
                if (!isPresent(actionsResponse[1].task)) {
                    return finishAction();
                }
                race([
                    actions.pipe(ofActionSuccessful(ProcessLoadProcessInstanceSuccessAction)),
                    actions.pipe(ofActionSuccessful(ProcessLoadProcessInstanceErrorAction))
                ]).subscribe(function () {
                    return finishAction();
                });
                store.dispatch(new ProcessLoadProcessInstanceAction());
            });
            var userMeErrorSubscription_1;
            var userMeSuccessSubscription_1 = actions
                .pipe(ofActionSuccessful(UserMeSuccessAction), take(1))
                .subscribe(function () {
                var authorities = store.selectSnapshot(UserState.authorities);
                if (Array.isArray(authorities) && authorities.length > 0) {
                    store.dispatch(new ProcessRefreshUserTaskAction());
                }
                else {
                    var dialogRef = openDialog(matDialog, {
                        title: translateService.instant('USER.HAVE_NO_RIGHTS_DIALOG.TITLE'),
                        text: translateService.instant('USER.HAVE_NO_RIGHTS_DIALOG.CONTENT'),
                        okButtonText: translateService.instant('INVOICE.ACCESS_RESOLVER.OK_BUTTON')
                    }, {
                        closeOnNavigation: false,
                        disableClose: true
                    });
                    return dialogRef.beforeClosed().subscribe(function () { return store.dispatch(new UserLogoutAction()); });
                }
                if (userMeErrorSubscription_1 !== undefined) {
                    userMeSuccessSubscription_1.unsubscribe();
                }
            });
            userMeErrorSubscription_1 = actions
                .pipe(ofActionSuccessful(UserMeErrorAction), take(1))
                .subscribe(function () {
                successBootstrapProcessSubscription_1.unsubscribe();
                getErrorsSubscription.unsubscribe();
                userMeSuccessSubscription_1.unsubscribe();
                removeAppLoader();
                resolve();
            });
            store.dispatch(new UserMeAction());
        }
    };
    AppInitializerFactory.ngInjectableDef = i0.defineInjectable({ factory: function AppInitializerFactory_Factory() { return new AppInitializerFactory(i0.inject(i0.INJECTOR)); }, token: AppInitializerFactory, providedIn: "root" });
    return AppInitializerFactory;
}());
export { AppInitializerFactory };
