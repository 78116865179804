/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../menu/menu.component.ngfactory";
import * as i3 from "../menu/menu.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/cdk/layout";
import * as i6 from "../menu/menu.service";
import * as i7 from "@ngxs/store";
import * as i8 from "../../common/window.token";
import * as i9 from "../../mobile-app-layout/mobile-app-layout/mobile-app-layout.component.ngfactory";
import * as i10 from "../../mobile-app-layout/mobile-app-layout/mobile-app-layout.component";
import * as i11 from "@angular/common";
import * as i12 from "./app-layout.component";
import * as i13 from "../../../app-title.service";
var styles_AppLayoutComponent = [i0.styles];
var RenderType_AppLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLayoutComponent, data: { "animation": [{ type: 7, name: "fadeInEnter", definitions: [{ type: 1, expr: ":enter", animation: { type: 10, animation: { type: 8, animation: { type: 4, styles: { type: 5, steps: [{ type: 6, styles: { opacity: 0, transform: "translate3d({{ a }}, 0, 0)", offset: 0 }, offset: null }, { type: 6, styles: { opacity: 1, transform: "translate3d({{ b }}, 0, 0)", offset: 1 }, offset: null }] }, timings: "{{ timing }}s {{ delay }}s" }, options: { params: { timing: 1, delay: 0, a: 0, b: 0 } } }, options: null }, options: null }], options: {} }, { type: 7, name: "routeAnimations", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter", animation: { type: 6, styles: { position: "fixed", maxWidth: "calc(100vw - 30px)", width: "100%" }, offset: null }, options: { optional: true } }, { type: 11, selector: ":leave", animation: { type: 6, styles: { position: "fixed", maxWidth: "calc(100vw - 30px)", width: "100%" }, offset: null }, options: { optional: true } }, { type: 3, steps: [{ type: 11, selector: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "0.5s" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s" }], options: { optional: true } }], options: null }], options: null }], options: {} }] } });
export { RenderType_AppLayoutComponent as RenderType_AppLayoutComponent };
function View_AppLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "sidenav-container"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "tg-menu", [], null, null, null, i2.View_MenuComponent_0, i2.RenderType_MenuComponent)), i1.ɵdid(3, 245760, null, 0, i3.MenuComponent, [i4.Router, i5.BreakpointObserver, i1.ChangeDetectorRef, i6.MenuService, i7.Store, i1.Injector, i7.Actions, i8.WINDOW], null, null), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["scrollElement", 1]], null, 5, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "content"]], [[24, "@.disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n\n  "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "footer", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { _ck(_v, 3, 0); _ck(_v, 9, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disableAnimations; _ck(_v, 7, 0, currVal_0); }); }
function View_AppLayoutComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "tg-mobile-app-layout", [], null, null, null, i9.View_MobileAppLayoutComponent_0, i9.RenderType_MobileAppLayoutComponent)), i1.ɵdid(2, 49152, null, 0, i10.MobileAppLayoutComponent, [i1.Injector, i4.Router], { appTitle$: [0, "appTitle$"], menu$: [1, "menu$"], disableAnimations: [2, "disableAnimations"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appTitle$; var currVal_1 = _co.menu$; var currVal_2 = _co.disableAnimations; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scrollElement: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppLayoutComponent_1)), i1.ɵdid(2, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["mobileTpl", 2]], null, 0, null, View_AppLayoutComponent_2)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDesktop; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AppLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tg-app-layout", [], null, null, null, View_AppLayoutComponent_0, RenderType_AppLayoutComponent)), i1.ɵdid(1, 4374528, null, 0, i12.AppLayoutComponent, [i1.Injector, i4.Router, i8.WINDOW, i13.AppTitleService, i6.MenuService], null, null)], null, null); }
var AppLayoutComponentNgFactory = i1.ɵccf("tg-app-layout", i12.AppLayoutComponent, View_AppLayoutComponent_Host_0, {}, {}, []);
export { AppLayoutComponentNgFactory as AppLayoutComponentNgFactory };
