import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuDisplayComponent } from './menu-display/menu-display.component';
import { MatProgressSpinnerModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, MatProgressSpinnerModule, TranslateModule],
  declarations: [MenuDisplayComponent],
  entryComponents: [MenuDisplayComponent],
  exports: [MenuDisplayComponent]
})
export class MenuDisplayModule {}
