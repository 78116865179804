import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
var AppTitleService = /** @class */ (function () {
    function AppTitleService() {
        this._title$ = new ReplaySubject(1);
    }
    Object.defineProperty(AppTitleService.prototype, "title$", {
        get: function () {
            return this._title$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppTitleService.prototype, "title", {
        set: function (value) {
            this._title$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    AppTitleService.ngInjectableDef = i0.defineInjectable({ factory: function AppTitleService_Factory() { return new AppTitleService(); }, token: AppTitleService, providedIn: "root" });
    return AppTitleService;
}());
export { AppTitleService };
