<ng-container *ngIf="(loading$ | async); else tpl">
  <div class="custom-spinner"><mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner></div>
</ng-container>
<ng-template #tpl>
  <div class="d-flex justify-content-start w-100">
    <div *ngIf="backDaysCounter >= 0; else probationOver" class="text">
      {{ 'SUBSCRIPTION.MENU_DISPLAY.LABEL.PROBATION' | translate: { backDaysCounter: backDaysCounter } }}
    </div>
    <ng-template #probationOver>
      <div class="text">{{ 'SUBSCRIPTION.MENU_DISPLAY.LABEL.OVER' | translate }}</div>
    </ng-template>
  </div>
</ng-template>
