import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { menuConfig } from '../../../config/menu.config';
import { UserState } from '../../user/state/user.state';
import { deepmerge } from '../../utils/common/deep-merge';
import { deepEqual } from '../../utils/function/deep-equal';
import { isFunction } from '../../utils/type-guard/is-function';
import { isPresent } from '../../utils/type-guard/is-present';
import { BaseMenuConfigInterface } from './model/base-menu.model';
import { MenuConfigModel } from './model/menu-config.model';
import { MenuWrapperModel } from './model/menu-wrapper.model';
import { SubMenuConfigModel } from './model/sub-menu-config.model';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private _menu$ = new BehaviorSubject<MenuWrapperModel>(deepmerge({}, menuConfig));
  private menu$: Observable<MenuWrapperModel>;

  constructor(/*private router: Router,*/ private injector: Injector, private store: Store) {
    this.menu$ = this._menu$.pipe(
      switchMap((menu: MenuWrapperModel) => {
        const operator = this.getMenuAuthorityFilterOperator(menu);
        return this.store.select(UserState.loggedIn).pipe(
          filter(loggedIn => loggedIn),
          switchMap(() =>
            this.store.select(UserState.user).pipe(
              filter(state => isPresent(state)),
              distinctUntilChanged((oldValue, newValue) => deepEqual(oldValue, newValue)),
              switchMap(() => this.store.select(UserState.authorities)),
              operator
            )
          )
        );
      }),
      this.getMenuShowContextFilterOperator(),
      shareReplay(1)
    );
  }

  getMenuObservable(): Observable<MenuWrapperModel> {
    return this.menu$;
  }

  getMenuCurrentValue(): MenuWrapperModel {
    return this._menu$.getValue();
  }

  /**
   * Authority-k alapján szűri
   * , hogy mit láthat a user a paraméterben megadott menürészlet alapján
   * @param menus
   */
  private _authorizedMenuFilter(menus: BaseMenuConfigInterface[], authorities: string[]): BaseMenuConfigInterface[] {
    return menus.filter((menu: BaseMenuConfigInterface) => authorities.includes(menu.authority));
  }

  /**
   * Authority-k alapján szűri, hogy mit láthat a user a menüben
   * végig megy a Fő menü alsó és felső részén, illetve annak minden gyermek submenüjén
   * @param menuWrapper
   */
  private filterMenuWrapperByAuthority(menuWrapper: MenuWrapperModel, authorities: string[]) {
    menuWrapper.top = this._authorizedMenuFilter(menuWrapper.top, authorities) as MenuConfigModel[];
    menuWrapper.bottom = this._authorizedMenuFilter(menuWrapper.bottom, authorities) as MenuConfigModel[];
    const newMenuWrapper: MenuWrapperModel = { ...menuWrapper };
    [...newMenuWrapper.top, ...newMenuWrapper.bottom].forEach((menu: MenuConfigModel) => {
      menu.top = this._authorizedMenuFilter(menu.top, authorities) as SubMenuConfigModel[];
      menu.bottom = this._authorizedMenuFilter(menu.bottom, authorities) as SubMenuConfigModel[];
    });
    return newMenuWrapper;
  }

  private getMenuAuthorityFilterOperator(menuWrapper: MenuWrapperModel) {
    return map((authorities: string[]) => this.filterMenuWrapperByAuthority(menuWrapper, authorities));
  }

  showContextFilterItems(menuItem: MenuConfigModel | SubMenuConfigModel): boolean {
    if (isFunction(menuItem.showContext)) {
      const cbArgs = [];
      if (Array.isArray(menuItem.showContextDeps)) {
        menuItem.showContextDeps.forEach(dep => cbArgs.push(this.injector.get(dep)));
      }
      return menuItem.showContext(...cbArgs);
    }
    return true;
  }

  // afterClickedFilterItems(menuItem: (SubMenuConfigModel)): boolean {
  //   if (isFunction(menuItem.afterClicked)) {
  //     const cbArgs = [];
  //     if (Array.isArray(menuItem.afterClicked$Deps)) {
  //       menuItem.afterClicked$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
  //     }
  //     return menuItem.afterClicked(...cbArgs);
  //   }
  //   return true;
  // }

  private getMenuShowContextFilterOperator() {
    return map((menuWrapper: MenuWrapperModel) => {
      // check top level show items by context method
      menuWrapper.top = menuWrapper.top
        .filter((menuItem: MenuConfigModel) => this.showContextFilterItems(menuItem))
        .map(menuItem => {
          if (isFunction(menuItem.showContext$)) {
            const cbArgs = [];
            if (Array.isArray(menuItem.showContext$Deps)) {
              menuItem.showContext$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
            }
            menuItem._showContext$ = menuItem.showContext$(...cbArgs);
          }
          return menuItem;
        });
      menuWrapper.bottom = menuWrapper.bottom
        .filter((menuItem: MenuConfigModel) => this.showContextFilterItems(menuItem))
        .map(menuItem => {
          if (isFunction(menuItem.showContext$)) {
            const cbArgs = [];
            if (Array.isArray(menuItem.showContext$Deps)) {
              menuItem.showContext$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
            }
            menuItem._showContext$ = menuItem.showContext$(...cbArgs);
          }
          return menuItem;
        });

      // check sub level show items by context method
      menuWrapper.top = menuWrapper.top.map((menuItem: MenuConfigModel) => {
        menuItem.top = menuItem.top
          .filter(subMenuItem => this.showContextFilterItems(subMenuItem))
          .map(subMenuItem => {
            if (isFunction(subMenuItem.showContext$)) {
              const cbArgs = [];
              if (Array.isArray(subMenuItem.showContext$Deps)) {
                subMenuItem.showContext$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
              }
              subMenuItem._showContext$ = subMenuItem.showContext$(...cbArgs);
            }
            return subMenuItem;
          }); // , this.afterClickedFilterItems(subMenuItem)));
        menuItem.bottom = menuItem.bottom
          .filter((subMenuItem: SubMenuConfigModel) => this.showContextFilterItems(subMenuItem))
          .map(subMenuItem => {
            if (isFunction(subMenuItem.showContext$)) {
              const cbArgs = [];
              if (Array.isArray(subMenuItem.showContext$Deps)) {
                subMenuItem.showContext$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
              }
              subMenuItem._showContext$ = subMenuItem.showContext$(...cbArgs);
            }
            return subMenuItem;
          }); // , this.afterClickedFilterItems(subMenuItem)));
        return menuItem;
      });

      menuWrapper.bottom = menuWrapper.bottom.map(menuItem => {
        menuItem.top = menuItem.top
          .filter((subMenuItem: SubMenuConfigModel) => this.showContextFilterItems(subMenuItem))
          .map(subMenuItem => {
            if (isFunction(subMenuItem.showContext$)) {
              const cbArgs = [];
              if (Array.isArray(subMenuItem.showContext$Deps)) {
                subMenuItem.showContext$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
              }
              subMenuItem._showContext$ = subMenuItem.showContext$(...cbArgs);
            }
            return subMenuItem;
          }); //, this.afterClickedFilterItems(subMenuItem)));
        menuItem.bottom = menuItem.bottom
          .filter((subMenuItem: SubMenuConfigModel) => this.showContextFilterItems(subMenuItem))
          .map(subMenuItem => {
            if (isFunction(subMenuItem.showContext$)) {
              const cbArgs = [];
              if (Array.isArray(subMenuItem.showContext$Deps)) {
                subMenuItem.showContext$Deps.forEach(dep => cbArgs.push(this.injector.get(dep)));
              }
              subMenuItem._showContext$ = subMenuItem.showContext$(...cbArgs);
            }
            return subMenuItem;
          }); //, this.afterClickedFilterItems(subMenuItem)));
        return menuItem;
      });

      return menuWrapper;
    });
  }

  /**
   * Megadott url alapján kikeresi a config-ból, hogy melyik menün + submenün vagyunk aktuálisan
   * @param currentUrl
   * @param menu
   */
  findSelectedMenuItemByCurrentUrl(currentUrl: string, menu?: MenuWrapperModel) {
    let menu$: Observable<MenuWrapperModel>;
    if (menu !== undefined) {
      menu$ = of(menu);
    } else {
      menu$ = this.menu$;
    }
    return menu$.pipe(
      map(menuWrapper => {
        let selectedMenus = null;
        const allMenus = [...menuWrapper.top, ...menuWrapper.bottom];
        allMenus.forEach((_menu: MenuConfigModel) => {
          if (isPresent(_menu.route) && _menu.route === currentUrl) {
            selectedMenus = { chosenMenu: _menu, chosenSubMenu: undefined };
          }
          if (_menu.hidden === undefined) {
            _menu.hidden = [];
          }
          // ha van olyan eleme, aminek a route-ja = az url
          const chosenMenu = [..._menu.top, ..._menu.bottom, ..._menu.hidden].find((subMenu: SubMenuConfigModel) =>
            currentUrl.includes(subMenu.route)
          );
          if (chosenMenu) {
            selectedMenus = { chosenMenu: _menu, chosenSubMenu: chosenMenu };
          }
        });
        if (!isPresent(selectedMenus)) {
          if (allMenus.length > 0) {
            selectedMenus = {
              chosenMenu: allMenus[0],
              chosenSubMenu: allMenus[0].top[0] || allMenus[0].top[0]
            };
          } else {
            selectedMenus = {
              chosenMenu: undefined,
              chosenSubMenu: undefined
            };
          }
        }

        return { selectedMenus, navigateUrl: this.getTopAccesMenuItemUrl(selectedMenus, menuWrapper) };
      })
    );
  }

  /**
   * ha nem volt ilyen url-el elem a listában, az első elemre navigáljuk
   * azért nem 404-re, mert app.routing default az invoice/dashboard-ra navigálna
   * de nem mindegyik fajta felhasználónak lesz a dashboard elérhető! pl:bookkeeper(könyvelő)
   * @param selectedMenus
   * @param menuWrapper
   */
  getTopAccesMenuItemUrl(selectedMenus: any, menuWrapper) {
    if (!isPresent(selectedMenus) && menuWrapper.top.length > 0) {
      if (menuWrapper.top[0].top.length > 0) {
        return menuWrapper.top[0].top[0].route;
      } else if (menuWrapper.top[0].bottom.length > 0) {
        return menuWrapper.top[0].bottom[0].route;
      }
    }
    return undefined;
  }
}
