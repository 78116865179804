import { Injector, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
var ErrorCenterService = /** @class */ (function () {
    function ErrorCenterService(http, matDialog, injector) {
        this.http = http;
        this.matDialog = matDialog;
        this.injector = injector;
        this._errorCodes$ = new ReplaySubject(1);
        ErrorCenterService._INSTANCE = this;
    }
    ErrorCenterService.prototype.loadTranslate = function (path) {
        var _this = this;
        this.http.get(path).subscribe(function (errorCodes) { return _this._errorCodes$.next(errorCodes); });
    };
    Object.defineProperty(ErrorCenterService.prototype, "errorCodes$", {
        get: function () {
            return this._errorCodes$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorCenterService, "INSTANCE", {
        get: function () {
            return this._INSTANCE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorCenterService.prototype, "translateService", {
        get: function () {
            if (this._translateService === undefined) {
                this._translateService = this.injector.get(TranslateService);
            }
            return this._translateService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ErrorCenterService.prototype, "ngZone", {
        get: function () {
            if (this._ngZone === undefined) {
                this._ngZone = this.injector.get(NgZone);
            }
            return this._ngZone;
        },
        enumerable: true,
        configurable: true
    });
    return ErrorCenterService;
}());
export { ErrorCenterService };
