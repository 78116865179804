import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ErrorCenterService } from '../error-center/error-center.service';

export class TranslateJsonLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private errorCenterService: ErrorCenterService,
    public prefix: string = '/assets/i18n/',
    public suffix: string = '.json'
  ) {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    this.errorCenterService.loadTranslate(`${this.prefix}${lang}-http-error-codes${this.suffix}`);
    return this.http.get(`${this.prefix}${lang}${this.suffix}`);
  }
}
