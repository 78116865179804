/**
 * extend from https://github.com/angulartics/angulartics2/blob/master/src/lib/core/angular-router.ts
 */
import { NavigationEnd, Router } from '@angular/router';
import { RouterlessTracking, TrackNavigationEnd } from 'angulartics2';
import { Observable } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

export class FixedAngularRouterTracking implements RouterlessTracking {
  constructor(private router: Router) {}

  trackLocation(settings): Observable<TrackNavigationEnd> {
    return this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      filter(() => !settings.developerMode),
      map((e: NavigationEnd) => {
        return { url: e.urlAfterRedirects };
      }),
      delay(0)
    );
  }

  prepareExternalUrl(url: string): string {
    if (url.charAt(0) !== '/') {
      return `/${url}`;
    }
    return url;
  }
}
