import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatProgressSpinnerModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AddUseIdToIconPipeModule } from '../../common/add-use-id-to-icon-pipe/add-use-id-to-icon-pipe.module';
import { MonogramPipeModule } from '../../common/monogram-pipe/monogram-pipe.module';
import { PreventClickModule } from '../../common/prevent-click/prevent-click.module';
import { MenuDisplayModule as SubscriptionMenuDisplayModule } from '../../subscription/menu-display/menu-display.module';
import { MenuIconDirective } from './menu-icon.directive';
import { MenuComponent } from './menu.component';
import { SubMenuModule } from './sub-menu/sub-menu.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,

    SubMenuModule,
    SubscriptionMenuDisplayModule,
    MonogramPipeModule,
    MatProgressSpinnerModule,
    AddUseIdToIconPipeModule,
    PreventClickModule
  ],
  declarations: [MenuComponent, MenuIconDirective],
  exports: [MenuComponent]
})
export class MenuModule {}
