import * as tslib_1 from "tslib";
import { HttpUrlEncodingCodec } from '@angular/common/http';
var ParamsEncoder = /** @class */ (function (_super) {
    tslib_1.__extends(ParamsEncoder, _super);
    function ParamsEncoder() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ParamsEncoder.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    ParamsEncoder.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    return ParamsEncoder;
}(HttpUrlEncodingCodec));
export { ParamsEncoder };
