import * as tslib_1 from "tslib";
import { registerLocaleData } from '@angular/common';
import { Injector } from '@angular/core';
import { DateAdapter } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State } from '@ngxs/store';
import * as countries from 'i18n-iso-countries';
import * as moment_ from 'moment';
import { asapScheduler } from 'rxjs';
import { LOCALES } from '../../locales';
import { isNil } from '../utils/type-guard/is-nil';
import { SelectLanguageAction } from './action/select-language.action';
import { SetLanguageOptionsSuccessAction } from './action/set-language-options-success.action';
import { SetLanguageOptionsAction } from './action/set-language-options.action';
var moment = moment_;
var ɵ0 = function () {
    return import('@angular/common/locales/en').then(function (locale) {
        return import('@angular/common/locales/extra/en').then(function (extra) { return ({ locale: locale.default, extra: extra.default }); });
    });
}, ɵ1 = function () {
    return import('@angular/common/locales/hu').then(function (locale) {
        return import('@angular/common/locales/extra/hu').then(function (extra) { return ({ locale: locale.default, extra: extra.default }); });
    });
};
var angularLocalesSettings = {
    en: ɵ0,
    hu: ɵ1
};
var I18nState = /** @class */ (function () {
    function I18nState(translateService, injector) {
        this.translateService = translateService;
        this.injector = injector;
        this.registeredLocaleData = {};
    }
    I18nState.selectedLang = function (state) {
        return state.selectedLang;
    };
    I18nState.prototype.selectLanguage = function (_a, _b) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        var lang = _b.lang;
        if (isNil(lang) || LOCALES.indexOf(lang) === -1) {
            lang = 'HU';
        }
        patchState({ selectedLang: lang });
        asapScheduler.schedule(function () { return dispatch(new SetLanguageOptionsAction()); });
    };
    I18nState.prototype.setLanguageOptions = function (_a) {
        var getState = _a.getState, dispatch = _a.dispatch;
        var selectedLang = getState().selectedLang.toLowerCase();
        this.translateService.use(selectedLang);
        if (this.translateServiceSubscription !== undefined && !this.translateServiceSubscription.closed) {
            this.translateServiceSubscription.unsubscribe();
        }
        if (isNil(this.registeredLocaleData[selectedLang])) {
            this.registeredLocaleData[selectedLang] = true;
            angularLocalesSettings[selectedLang]().then(function (localeSettings) {
                return registerLocaleData(localeSettings.locale, selectedLang, localeSettings.extra);
            });
            countries.registerLocale(require("i18n-iso-countries/langs/" + selectedLang + ".json"));
        }
        moment.locale(selectedLang);
        this.injector.get(DateAdapter).setLocale(selectedLang);
        dispatch(new SetLanguageOptionsSuccessAction());
    };
    tslib_1.__decorate([
        Action(SelectLanguageAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, SelectLanguageAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], I18nState.prototype, "selectLanguage", null);
    tslib_1.__decorate([
        Action(SetLanguageOptionsAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], I18nState.prototype, "setLanguageOptions", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], I18nState, "selectedLang", null);
    I18nState = tslib_1.__decorate([
        State({
            name: 'i18n',
            defaults: {
                selectedLang: null,
                version: 1
            }
        }),
        tslib_1.__metadata("design:paramtypes", [TranslateService, Injector])
    ], I18nState);
    return I18nState;
}());
export { I18nState };
export { ɵ0, ɵ1 };
