import * as tslib_1 from "tslib";
import { Action, Selector, State, Store } from '@ngxs/store';
import { asapScheduler, throwError } from 'rxjs';
// import { produce } from 'immer';
import { catchError, tap } from 'rxjs/operators';
import { UserState } from '../../user/state/user.state';
import { isPresent } from '../../utils/type-guard/is-present';
import { ProcessService } from '../process.service';
import { ProcessLoadProcessInstanceErrorAction } from './action/process-load-process-instance-error.action';
import { ProcessLoadProcessInstanceSuccessAction } from './action/process-load-process-instance-success.action';
import { ProcessLoadProcessInstanceAction } from './action/process-load-process-instance.action';
import { ProcessLoadTaskByProcessDefinitionKeyErrorAction } from './action/process-load-task-by-process-definition-key-error.action';
import { ProcessLoadTaskByProcessDefinitionKeySuccessAction } from './action/process-load-task-by-process-definition-key-success.action';
import { ProcessLoadTaskByProcessDefinitionKeyAction } from './action/process-load-task-by-process-definition-key.action';
import { ProcessLoadTaskErrorAction } from './action/process-load-task-error.action';
import { ProcessLoadTaskSuccessAction } from './action/process-load-task-success.action';
import { ProcessLoadTaskAction } from './action/process-load-task.action';
import { ProcessRefreshUserTaskErrorAction } from './action/process-refresh-user-task-error.action';
import { ProcessRefreshUserTaskSuccessAction } from './action/process-refresh-user-task-success.action';
import { ProcessRefreshUserTaskAction } from './action/process-refresh-user-task.action';
import { ProcessSetInstanceidAction } from './action/process-set-instanceid.action';
var ProcessState = /** @class */ (function () {
    function ProcessState(processService, store) {
        this.processService = processService;
        this.store = store;
    }
    ProcessState.processInstanceBusinessKey = function (state) {
        return isPresent(state.processInstance) ? state.processInstance.businessKey : null;
    };
    ProcessState.taskId = function (state) {
        return isPresent(state.task) ? state.task.id : null;
    };
    ProcessState.prototype.processSetInstanceid = function (_a, action) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        if (action instanceof ProcessSetInstanceidAction) {
            return patchState({ processInstanceId: action.processInstanceId });
        }
        else {
            return patchState({ processInstanceId: isPresent(action.task) ? action.task.processInstanceId : null });
        }
    };
    ProcessState.prototype.processLoadTask = function (_a, action) {
        var dispatch = _a.dispatch, getState = _a.getState;
        var method;
        if (action.loadWithProcessInstanceId === true) {
            method = this.processService.taskDataByProcessInstanceId(getState().processInstanceId);
        }
        else {
            method = this.processService.taskData(this.store.selectSnapshot(UserState.tenantId));
        }
        return method.pipe(tap(function (task) { return asapScheduler.schedule(function () { return dispatch(new ProcessLoadTaskSuccessAction(task, action.loadProcessInstance)); }); }), catchError(function (error) {
            asapScheduler.schedule(function () { return dispatch(new ProcessLoadTaskErrorAction(error)); });
            return throwError(error);
        }));
    };
    ProcessState.prototype.processLoadTaskSuccess = function (_a, action) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        patchState({ task: action.task, processInstanceId: action.task.processInstanceId });
        if (action.loadProcessInstance) {
            return dispatch(new ProcessLoadProcessInstanceAction());
        }
    };
    // TODO ez kell ? ? ?? ? ? ?
    ProcessState.prototype.processLoadProcessInstance = function (_a) {
        var getState = _a.getState, dispatch = _a.dispatch;
        var state = getState();
        return this.processService.refreshProcessInstance(state.task.processInstanceId).pipe(tap(function (processInstance) { return asapScheduler.schedule(function () { return dispatch(new ProcessLoadProcessInstanceSuccessAction(processInstance)); }); }), catchError(function (error) {
            asapScheduler.schedule(function () { return dispatch(new ProcessLoadProcessInstanceErrorAction(error)); });
            return throwError(error);
        }));
    };
    ProcessState.prototype.processLoadProcessInstanceSuccess = function (_a, action) {
        var patchState = _a.patchState;
        return patchState({ processInstance: action.processInstance, processInstanceId: action.processInstance.id });
    };
    ProcessState.prototype.processRefreshUserTask = function (_a) {
        var dispatch = _a.dispatch;
        return this.processService.taskData(this.store.selectSnapshot(UserState.tenantId)).pipe(tap(function (task) { return asapScheduler.schedule(function () { return dispatch(new ProcessRefreshUserTaskSuccessAction(task)); }); }), catchError(function (error) {
            asapScheduler.schedule(function () { return dispatch(new ProcessRefreshUserTaskErrorAction(error)); });
            return throwError(error);
        }));
    };
    ProcessState.prototype.processRefreshUserTaskSuccess = function (_a, action) {
        var patchState = _a.patchState, dispatch = _a.dispatch;
        if (isPresent(action.task)) {
            return patchState({
                task: action.task,
                processInstanceId: action.task !== null ? action.task.processInstanceId : null
            });
        }
        else {
            return patchState({
                task: undefined,
                processInstanceId: undefined
            });
        }
        // if (action.task !== null) {
        //   return dispatch(new ProcessLoadProcessInstanceAction());
        // }
    };
    // @Action(ProcessLoadTaskErrorAction)
    // processLoadTaskError() {
    //   // TODO hibakezeles
    // }
    ProcessState.prototype.processLoadTaskByProcessDefinitionKey = function (_a, action) {
        var dispatch = _a.dispatch, patchState = _a.patchState;
        return this.processService.taskDataByProcessDefinitionKey(action.processDefinitionKey).pipe(tap(function (task) {
            if (isPresent(task)) {
                return patchState({
                    task: task,
                    processInstanceId: task !== null ? task.processInstanceId : null
                });
            }
            else {
                return patchState({
                    task: undefined,
                    processInstanceId: undefined
                });
            }
        }), tap(function (task) { return asapScheduler.schedule(function () { return dispatch(new ProcessLoadTaskByProcessDefinitionKeySuccessAction(task)); }); }), catchError(function (error) {
            asapScheduler.schedule(function () { return dispatch(new ProcessLoadTaskByProcessDefinitionKeyErrorAction(error)); });
            return throwError(error);
        }));
    };
    tslib_1.__decorate([
        Action([ProcessSetInstanceidAction, ProcessRefreshUserTaskSuccessAction]),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processSetInstanceid", null);
    tslib_1.__decorate([
        Action(ProcessLoadTaskAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProcessLoadTaskAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processLoadTask", null);
    tslib_1.__decorate([
        Action(ProcessLoadTaskSuccessAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProcessLoadTaskSuccessAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processLoadTaskSuccess", null);
    tslib_1.__decorate([
        Action(ProcessLoadProcessInstanceAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processLoadProcessInstance", null);
    tslib_1.__decorate([
        Action(ProcessLoadProcessInstanceSuccessAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProcessLoadProcessInstanceSuccessAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processLoadProcessInstanceSuccess", null);
    tslib_1.__decorate([
        Action(ProcessRefreshUserTaskAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processRefreshUserTask", null);
    tslib_1.__decorate([
        Action(ProcessRefreshUserTaskSuccessAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProcessRefreshUserTaskSuccessAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processRefreshUserTaskSuccess", null);
    tslib_1.__decorate([
        Action(ProcessLoadTaskByProcessDefinitionKeyAction),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object, ProcessLoadTaskByProcessDefinitionKeyAction]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState.prototype, "processLoadTaskByProcessDefinitionKey", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", void 0)
    ], ProcessState, "processInstanceBusinessKey", null);
    tslib_1.__decorate([
        Selector(),
        tslib_1.__metadata("design:type", Function),
        tslib_1.__metadata("design:paramtypes", [Object]),
        tslib_1.__metadata("design:returntype", String)
    ], ProcessState, "taskId", null);
    ProcessState = tslib_1.__decorate([
        State({
            name: 'process',
            defaults: {
                task: null,
                processInstance: null,
                processInstanceId: null,
                version: 1
            }
        }),
        tslib_1.__metadata("design:paramtypes", [ProcessService, Store])
    ], ProcessState);
    return ProcessState;
}());
export { ProcessState };
