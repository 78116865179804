import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Actions, ofActionDispatched, ofActionSuccessful, Store } from '@ngxs/store';
import { combineLatest, race, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { getRandomInt } from './modules/app-layout/menu/menu.component';
import { RemoveAppLoadingAction } from './modules/app-layout/state/action/remove-app-loading.action';
import { openDialog } from './modules/common/modal-question/function/open-dialog.function';
import { ShowTopLayoutLoaderAction } from './modules/common/top-layout-loader/state/action/show-top-layout-loader.action';
import { WINDOW } from './modules/common/window.token';
import { ProcessLoadProcessInstanceErrorAction } from './modules/process/state/action/process-load-process-instance-error.action';
import { ProcessLoadProcessInstanceSuccessAction } from './modules/process/state/action/process-load-process-instance-success.action';
import { ProcessLoadProcessInstanceAction } from './modules/process/state/action/process-load-process-instance.action';
import { ProcessRefreshUserTaskErrorAction } from './modules/process/state/action/process-refresh-user-task-error.action';
import { ProcessRefreshUserTaskSuccessAction } from './modules/process/state/action/process-refresh-user-task-success.action';
import { ProcessRefreshUserTaskAction } from './modules/process/state/action/process-refresh-user-task.action';
import { UserLogoutAction } from './modules/user/state/action/user-logout.action';
import { UserMeErrorAction } from './modules/user/state/action/user-me-error.action';
import { UserMeSuccessAction } from './modules/user/state/action/user-me-success.action';
import { UserMeAction } from './modules/user/state/action/user-me.action';
import { UserState } from './modules/user/state/user.state';
import { isPresent } from './modules/utils/type-guard/is-present';

export function appInitializerFactory(_appInitializerFactory: AppInitializerFactory): Function {
  return () => _appInitializerFactory.init();
}

/**
 * TODO RENAME!!!
 * @param actionsResponse
 * @param router
 * @param removeAppLoader
 * @param resolve
 */
export function taskState(formKey: string, router) {
  switch (formKey) {
    // case 'inputCompanyForm':
    //   router.navigate(['/user-manager/my-company-data']).then(() => {
    //     removeAppLoader();
    //     resolve();
    //   });
    //   break;
    case 'setProcessParamDeployRecieveInvoice':
      return router.navigate(['/user/registration-manager/set-amount']);
    case 'inviteUsers':
      return router.navigate(['/user/registration-manager/invite']);
    default:
      return null;
  }
}

@Injectable({ providedIn: 'root' })
export class AppInitializerFactory {
  constructor(private readonly injector: Injector) {}

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      const store = this.injector.get(Store);
      const router = this.injector.get(Router);
      const actions = this.injector.get(Actions);
      const httpClient = this.injector.get(HttpClient);
      const isLoggedIn = store.selectSnapshot(UserState.loggedIn);
      const tenants = store.selectSnapshot(UserState.tenants);
      const matDialog = this.injector.get(MatDialog);
      const translateService = this.injector.get(TranslateService);
      const window: Window = this.injector.get(WINDOW) as Window;

      if (environment.maintenanceModeFbUrl !== null) {
        httpClient.get<boolean>(environment.maintenanceModeFbUrl).subscribe(
          enable => {
            if (enable === true) {
              window.location.assign(`/maintenance-mode.html?rand=${getRandomInt(0, 9999999999)}`);
              return;
            }
            this.bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService);
          },
          () => this.bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService)
        );
      } else {
        this.bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService);
      }
    });
  }

  private bootstrap(store, actions, resolve, isLoggedIn, tenants, matDialog, router, translateService) {
    const removeAppLoader = () => {
      store.dispatch(new RemoveAppLoadingAction());
    };

    store.dispatch(new ShowTopLayoutLoaderAction());
    const getErrorsSubscription = actions
      .pipe(
        ofActionDispatched(ProcessRefreshUserTaskErrorAction),
        take(1)
      )
      .subscribe(() => {
        removeAppLoader();
        resolve();
      });

    if (isLoggedIn && tenants.length === 0) {
      removeAppLoader();

      openDialog(
        matDialog,
        {
          title: 'Jogosultsági hiba',
          text: 'A felhasználó nem tartozik előfizetőhöz, bejelentkezés nem lehetséges.',
          okButtonText: 'OK'
        },
        {
          closeOnNavigation: false,
          disableClose: true
        }
      )
        .beforeClosed()
        .subscribe(() => {
          store.dispatch(new UserLogoutAction());
          getErrorsSubscription.unsubscribe();
        });
      return;
    }

    if (!isLoggedIn) {
      removeAppLoader();
      getErrorsSubscription.unsubscribe();
      resolve();
    } else {
      const bodyClassList = this.injector.get(DOCUMENT).body.classList;
      bodyClassList.remove('not-auth');
      bodyClassList.add('auth');

      const successBootstrapProcessSubscription = combineLatest([
        actions.pipe(
          ofActionSuccessful(UserMeSuccessAction),
          take(1)
        ),
        actions.pipe(
          ofActionSuccessful(ProcessRefreshUserTaskSuccessAction),
          take(1)
        )
      ]).subscribe((actionsResponse: [UserMeSuccessAction, ProcessRefreshUserTaskSuccessAction]) => {
        getErrorsSubscription.unsubscribe();
        const finishAction = () => {
          const _taskState = taskState(
            actionsResponse[1]['task'] && actionsResponse[1]['task']['formKey'] ? actionsResponse[1]['task']['formKey'] : '',
            router
          );
          if (_taskState instanceof Promise) {
            return _taskState.then(() => {
              successBootstrapProcessSubscription.unsubscribe();
              removeAppLoader();
              return resolve();
            });
          } else {
            successBootstrapProcessSubscription.unsubscribe();
            removeAppLoader();
            return resolve();
          }
        };
        if (!isPresent(actionsResponse[1].task)) {
          return finishAction();
        }
        race([
          actions.pipe(ofActionSuccessful(ProcessLoadProcessInstanceSuccessAction)),
          actions.pipe(ofActionSuccessful(ProcessLoadProcessInstanceErrorAction))
        ]).subscribe(() => {
          return finishAction();
        });
        store.dispatch(new ProcessLoadProcessInstanceAction());
      });
      let userMeErrorSubscription: Subscription;
      const userMeSuccessSubscription = actions
        .pipe(
          ofActionSuccessful(UserMeSuccessAction),
          take(1)
        )
        .subscribe(() => {
          const authorities = store.selectSnapshot(UserState.authorities);
          if (Array.isArray(authorities) && authorities.length > 0) {
            store.dispatch(new ProcessRefreshUserTaskAction());
          } else {
            const dialogRef = openDialog(
              matDialog,
              {
                title: translateService.instant('USER.HAVE_NO_RIGHTS_DIALOG.TITLE'),
                text: translateService.instant('USER.HAVE_NO_RIGHTS_DIALOG.CONTENT'),
                okButtonText: translateService.instant('INVOICE.ACCESS_RESOLVER.OK_BUTTON')
              },
              {
                closeOnNavigation: false,
                disableClose: true
              }
            );
            return dialogRef.beforeClosed().subscribe(() => store.dispatch(new UserLogoutAction()));
          }
          if (userMeErrorSubscription !== undefined) {
            userMeSuccessSubscription.unsubscribe();
          }
        });
      userMeErrorSubscription = actions
        .pipe(
          ofActionSuccessful(UserMeErrorAction),
          take(1)
        )
        .subscribe(() => {
          successBootstrapProcessSubscription.unsubscribe();
          getErrorsSubscription.unsubscribe();
          userMeSuccessSubscription.unsubscribe();
          removeAppLoader();
          resolve();
        });
      store.dispatch(new UserMeAction());
    }
  }
}
