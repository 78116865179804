import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { isDesktop, isTablet } from '../../../../../main-config';
import { TopLayoutLoaderState } from '../state/top-layout-loader.state';

@Component({
  selector: 'tg-top-layout-loader',
  template: `
    <mat-progress-bar *ngIf="(show$ | async)" mode="indeterminate" [color]="isDesktop ? 'primary' : 'accent'"></mat-progress-bar>
  `,
  styles: [
    `
      .mat-progress-bar {
        position: fixed;
        z-index: 999999999;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopLayoutLoaderComponent {
  isDesktop = isDesktop;
  isTablet = isTablet;
  @Select(TopLayoutLoaderState.show)
  readonly show$: Observable<boolean>;
}
