import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[tgPreventClick]'
})
export class PreventClickDirective {
  @HostListener('click', ['$event'])
  onClick($event: Event): boolean {
    $event.stopPropagation();
    $event.preventDefault();

    return false;
  }
}
